import React from 'react';
import './index.scss'
import {getUserInfo} from "../../../lib/help/public";

import Company from "./Firm"
import Agency from "./Agency"
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";

export default class AuthorIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsInfo: {},
            userInfo: {
                user: {},
                info: {}
            },
        }
    }
    getUserInfo() {
        let tmpInfo = sessionStorage.getItem('userInfo');
        if (!tmpInfo) return;
        tmpInfo = JSON.parse(tmpInfo);
        if (!tmpInfo.info) {
            if (tmpInfo.user.UserType === '企业账户') {
                this.props.history.replace('/firm-replenish')
            }
            if (tmpInfo.user.UserType === '机构主账户') {
                this.props.history.replace('/agency-replenish')
            }
        } else {
            this.setState({
                userInfo: tmpInfo
            })
        }

    }

    componentDidMount() {
        getUserInfo()
        this.getUserInfo();
    }

    componentWillUnmount() {
    }

    render() {
        return (
          <div className={'users-breadcrumb profile-index-container'}>
              <div className={'breadcrumb detail-breadcrumb'}>
                  <Breadcrumb separator=">">
                      <Breadcrumb.Item>
                          <Link className={'link'} to={'/profile'}>个人中心</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                          <a className={'link'}>基本信息</a>
                      </Breadcrumb.Item>
                  </Breadcrumb>
              </div>
            <div className="detail-breadcrumb-margin">
                {
                    this.state.userInfo.user.UserType === '企业账户' &&
                        <Company/>
                }
                {
                    (this.state.userInfo.user.UserType === '机构主账户' || this.state.userInfo.user.UserType === '机构子账户') &&
                    <Agency/>
                }

            </div>
          </div>
        )
    }
}
