import React from 'react';
import {Button, Col, Form, Input, Row} from 'antd';
import './index.scss'
import http from './../../lib/service/http'
import Upload from '../../components/Upload'
import {rules} from '../../lib/help/rules'
import {getUserInfo} from '../../lib/help/public'

const {not_null, phone, upload_file_not_required} = rules;


export default class Login extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onFinish(values) {
        values.uuid = this.state.uuid;
       console.log(values.logo);
        if (values.logo===undefined) {
            values.logo = ''
        } else {
            values.logo = values.logo.url
        }
        http.post('/financial/information', values).then(res => {
            getUserInfo(()=> {
                this.props.history.push({pathname: '/supple-tip'})
            })
        }, () => {
        })
    };

    onFileChange = (fileKey, file) => {
        this.formRef.current.setFieldsValue({[fileKey]: file});
        this.formRef.current.validateFields([fileKey]);
    }



    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="firm-register-container agency-replenish-container">
                <div className="firm-register-content content">
                    <div className="form-content register-from-content agency-replenish-from">
                        <Row gutter={[0, 0]} className='number-info-box'>
                            <Col className="gutter-row left-from" span={16}>
                                <Form
                                    name="basic"
                                    ref={this.formRef}
                                    onFinish={(values) => this.onFinish(values)}
                                    onFinishFailed={() => this.onFinishFailed()}>
                                    <Form.Item
                                        label="机构名称"
                                        name="name"
                                        rules={not_null}
                                        className={'not-extra input-name'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构名称'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="机构地址"
                                        name="address"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构地址'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="机构网址"
                                        name="website"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入网址'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="联系人姓名"
                                        name="contact_name"
                                        rules={not_null}
                                        className={'not-extra input-phone'}
                                    >
                                        <Input size="middle" placeholder={'请输入姓名'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="联系人职位"
                                        name="contact_position"
                                        rules={not_null}
                                        className={'not-extra input-phone'}
                                    >
                                        <Input size="middle" placeholder={'请输入职位'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="联系人电话"
                                        name="contact_telephone"
                                        rules={phone}
                                        className={'not-extra input-phone'}
                                    >
                                        <Input size="middle" placeholder={'请输入电话'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="机构Logo上传"
                                        name="logo"
                                        rules={upload_file_not_required}
                                        className={'not-extra upload-item'}
                                        extra={'只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符'}
                                        validateFirst={true}
                                    >
                                        <Upload
                                            onChange={this.onFileChange}
                                            fileKey={'logo'}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="机构主营业务"
                                        name={'business_scope'}
                                        rules={not_null}
                                        className={'not-extra desc-input'}
                                    >
                                        <Input.TextArea/>
                                    </Form.Item>

                                    <Form.Item className="submit-btn-item">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div className="desc-col">
                                    <p className="desc-title">注意事项</p>
                                    <div className="desc-box">
                                        <p className="item-text">
                                            1.注册企业账户只能办理企业事项，注册机构账号只能办理机构事项；若二者均是，需分别注册。
                                        </p>
                                        <p className="item-text">
                                            2.注册账号时，需要通过手机接收短信验证，请正确填写您的手机号码。
                                        </p>
                                        <p className="item-text">
                                            3.较多业务系统将使用填写的注册信息，请如实填写。
                                        </p>
                                        <p className="item-text">
                                            4.请牢记注册的登录账号和密码，切勿向他人泄漏。
                                        </p>
                                        <p className="item-text">
                                            5.如有问题，请联系我们，电话:<span className={'number-text'}>023-85233427</span>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
