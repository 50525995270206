import React from 'react';
import { message, Modal } from 'antd';
import {withRouter} from "react-router-dom";
import {Link, NavLink} from "react-router-dom";
import './index.scss'
import http from "../../lib/service/http";
import Cookie from "js-cookie";
import logo from '../../resource/images/public/logo-black.svg'
import logoWhite from '../../resource/images/public/logo-white.svg'
import userAvatar from '../../resource/images/public/user-avatar.svg'
import {ExclamationCircleOutlined} from '@ant-design/icons';


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            isScroll: false,
            OtherHeader: false,
            currentRoute: this.props.location,
            isModalVisible: false,
            currentUrl: ''
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const bodyObserver = document.querySelector('body');
        this.customObserver = new MutationObserver(function(){
            const headerNode = document.querySelector('.ant-layout-header');
            const bodyStyle = document.querySelector('body');
            const profileNode = document.querySelector('.profile-container');
            if(bodyStyle.style.width.includes('calc') && bodyStyle.style.width.indexOf('0px') === -1 && !profileNode){
                headerNode.style.width = bodyStyle.style.width;
            }else{
                headerNode.style.width = '100%'
            }

        });
        const customObserverConfig = {
            attributes:true
        }
        this.customObserver.observe(bodyObserver,customObserverConfig);
        const {location} = this.props.history;
        this.setState({
            currentUrl: location.pathname,
        });
        //  let route = this.props.location.pathname;
        this.props.history.listen(route => {
            this.setState({
                currentRoute: route,
                currentUrl: route.pathname,
            })
        })
    }


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.customObserver.disconnect();

    }

    getHeader = (route) => {
        return route.pathname === '/enterprise-financial' ||
            route.pathname === '/product-supermarket' ||
            route.pathname === '/financing-requirements' ||
            route.pathname === '/news-list' ||
            route.pathname === '/profile' ||
            route.pathname === '/account-info' ||
            route.pathname === '/account-security' ||
            route.pathname.indexOf('news-info') > -1 ||
            route.pathname.indexOf('enterprise-detail') > -1 ||
            route.pathname.indexOf('enterprise-financial-detail') > -1 ||
            route.pathname.indexOf('financial-detail') > -1 ||
            route.pathname.indexOf('bond-detail') > -1 ||
            route.pathname.indexOf('corporate-detail') > -1 ||
            route.pathname.indexOf('user-info') > -1 ||
            route.pathname.indexOf('/my-account') > -1 ||
            route.pathname.indexOf('/transaction') > -1 ||
            route.pathname.indexOf('confirm-list') > -1 ||
            route.pathname.indexOf('/my-message') > -1 ||
            route.pathname.indexOf('/requirements') >-1 ||
            route.pathname.indexOf('/add-requirements') >-1 ||
            route.pathname.indexOf('/register-agreement') > -1 ||
            route.pathname === '/fulfil-tip/confirm'
    };

    handleScroll = () => {

        let tmpScrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (tmpScrollTop > 30 && !this.state.isScroll) {
            this.setState({
                isScroll: true,
            })
            return;
        }
        if (tmpScrollTop < 30 && this.state.isScroll) {
            this.setState({
                isScroll: false,
            })
        }
    };

    logout = () => {
        http.post('/user/logout').then(() => {
            sessionStorage.clear();
            Cookie.remove('token');
            message.success('登出成功');
            this.handleCancel();
            this.props.history.replace('/');
        })
    }

    userLink(userInfo) {
        if (!userInfo.info && userInfo.user && userInfo.user.UserType === '机构主账户') {
            return '/agency-replenish'
        }
        if (!userInfo.info && userInfo.user && userInfo.user.UserType === '企业账户') {
            return '/firm-replenish'
        }
        return '/profile'
    }

    otherHeader = () => {
        if (this.getHeader(this.state.currentRoute)) {
            return true
        }
        return this.state.isScroll
    };
    handleOk = () => {
        this.logout();
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }

    render() {
        let userInfo = sessionStorage.getItem('userInfo') || '{}';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
        }
        const {isModalVisible} = this.state;
        return (
            <div className={this.otherHeader() ? "index-header-container white-header" : "index-header-container"}>
                <div className="header-content content">
                    <div className="img-box">
                        <Link className={'link'} to={'/'}>
                            {
                                this.otherHeader() ?
                                    <img src={logo} alt="" className="header-logo"/> :
                                    <img src={logoWhite} alt="" className="header-logo"/>

                            }
                        </Link>
                    </div>
                    <div className="nav-box">
                        <div className="item-box">
                            <NavLink exact className={'link'} activeClassName="activeNav" to={'/'}>
                                首页
                                <p className="line">
                                </p>
                            </NavLink>
                        </div>
                        <div className="item-box">
                            <NavLink  className={(this.state.currentUrl.indexOf('bond-detail') > -1 ||this.state.currentUrl.indexOf('corporate-detail') > -1) ? 'activeNav link' : 'link'} activeClassName="activeNav" to={'/product-supermarket'}>产品超市
                                <p className="line">
                                </p>
                            </NavLink>
                        </div>
                        <div className="item-box">
                            <NavLink  className={(this.state.currentUrl.indexOf('enterprise-detail') > -1 ||this.state.currentUrl.indexOf('financial-detail') > -1) ? 'activeNav link' : 'link'} activeClassName="activeNav" to={'/enterprise-financial'}>
                                企业与机构
                                <p className="line">
                                </p>
                            </NavLink>
                        </div>
                        <div className="item-box">
                            <NavLink  className={(this.state.currentUrl.indexOf('financing-requirements-detail') > -1 ||this.state.currentUrl.indexOf('/financing-requirements-detail') > -1) ? 'activeNav link' : 'link'} activeClassName="activeNav" to={'/financing-requirements'}>
                                融资需求
                                <p className="line">
                                </p>
                            </NavLink>
                        </div>
                        <div className="item-box">
                            <NavLink exact className={(this.state.currentUrl.indexOf('news-info') > -1) ? 'activeNav link' : 'link'} activeClassName="activeNav" to={"/news-list"}>
                                政策资讯
                                <p className="line">
                                </p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="avatar-box">
                        {
                            !userInfo.user ?
                                <div className="un-login-box">
                                    <Link className={'user-name has-line link-btn'} to={'/login'}>登录</Link>
                                    <Link className={'user-name link-btn'} to={'/register-type'}>注册</Link>
                                </div> :
                                <div className="login-box">
                                    {/*<Link className={'personal-center link-btn'} to={this.userLink(userInfo)}>个人中心</Link>*/}
                                    <Link to={this.userLink(userInfo)} className="user-name-box">
                                        <img src={userAvatar} className={'avatar-img'} alt={''}/>
                                        <p className="user-name has-line link-btn">{userInfo.user.UserName}</p>
                                    </Link>
                                    <p className="user-name link-btn" onClick={this.showModal}>退出登录</p>
                                </div>
                        }
                    </div>
                </div>
                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'确定'}
                    centered
                    onOk={this.handleOk}
                    className="set-padding"
                    onCancel={this.handleCancel}>
                    <p className='account-modal-title'><ExclamationCircleOutlined
                        className="icon-waring"/>确定退出登录吗?</p>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Header)
