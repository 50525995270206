export const regexps_username =  /^[0-9a-zA-Z_]{6,20}$/;
export const regexps_account = /^[a-zA-Z0-9]{6,20}$/;
export const regexps_phone = /^1[0-9]{10}$/;
export const regexps_img_code = /^[0-9a-zA-Z]{4}$/;
export const regexps_phone_code = /^[0-9]{6}$/;
export const regexps_email = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
export const regexps_id_card =  /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
export const regexps_invitation_code =  /^[\s\S]{6}$/;
export const regexps_file_name = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
export const regexps_password = /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,16}$/;
export const regexps_credit_code = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
export const regexps_capitalized_amount =  /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
export const regexps_num = /^\d+$|^\d*\.\d+$/g;
export const regexps_date = /^([0-9]|10)$/;
export const regexps_rate = /^(\d{0,2})$|^([1][0][0])$/;
export const regexps_product_name = /^.{0,20}$/;
export const regexps_remark = /^.{0,50}$/;
export const regexps_product_num = /^0\.([0-9]|\d[0-9])$|^[0-9]\d{0,8}\.\d{0,4}$|^[1-9]\d{0,8}$/;
export const regexps_product_rate = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/;
export const regexps_product_other = /^[\s\S]{0,3000}$/;
export const regexps_memo =  /^.{0,200}$/;
export const regexps_product_date = /^[1-9]\d*$/;
export const regexps_product_email_phone = /^(1[0-9]{10}$)|(^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/;
export const regexps_product_zero = /^([1-9]\d*(\.\d*)?)|(0\.\d*[1-9][0-9])|(0\.\d*[1-9])$/;
