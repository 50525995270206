import React from 'react';
import {Table, Space, Modal, Form, Select, Button, Pagination} from "antd";
import Add from './Modal/AddUser'
import closeImg from "../../../../resource/images/profile/cancle.svg";
import http from "../../../../lib/service/http";
import {localTime} from "../../../../lib/help/helper";
import infoSuccess from "../../../../resource/images/profile/info-success.jpg";
import {rules} from "../../../../lib/help/rules";
import NoData from "../../../NoData";
const {is_select} = rules;

const {Option} = Select;
export default class UserPage extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleRole: false,
      list: [],
      total: 0,
      currentPage: 1,
      disable: 0,
      recordEdit: [],
      roleType: [],
    }
  }
  columns=[
    {
      title: '用户名',
      dataIndex: 'UserName',
      key: 'UserName',
    },
    {
      title: '角色名',
      dataIndex: 'RoleName',
      key: 'RoleName',
    },
    {
      title: '创建时间',
      key: 'CreatedAt',
      render: (row) => {
      return (localTime(row.CreatedAt))
    }
},
{
  title: '备注',
  dataIndex: 'Memo',
  key: 'Memo',
  className: 'remark',
},
{
  title: '操作',
    key: 'action',
  render: (text, record) => {
    const {Disabled} = record;
    switch (Disabled) {
      case 1:
        return (<Space size="middle" className={'operate-btn'}>
          <a  onClick={this.disable.bind(this, record)}   className={record.UserType === 'FINANCIAL' ? 'disabled-btn' : ''}>解冻用户</a>
          <a  onClick={this.delete.bind(this, record)} className={record.UserType === 'FINANCIAL' ? 'disabled-btn' : ''}>删除用户</a>
          <a onClick={this.edit.bind(this, record)} className={record.UserType === 'FINANCIAL' ? 'disabled-btn' : ''}>修改角色</a>
        </Space>);
      case 0:
        return (<Space size="middle" className={'operate-btn'}>
          <a  onClick={this.disable.bind(this, record)} className={record.UserType === 'FINANCIAL' ? 'disabled-btn' : ''}>冻结用户</a>
          <a  onClick={this.delete.bind(this, record)} className={record.UserType === 'FINANCIAL' ? 'disabled-btn' : ''}>删除用户</a>
          <a onClick={this.edit.bind(this, record)} className={record.UserType === 'FINANCIAL' ? 'disabled-btn' : ''}>修改角色</a>
        </Space>)
    }
    }
},
];
  addUser=() => {
    this.setState({
      visible: true,
    });
  };
  disable =(record) => {
    if (record.UserType !== 'FINANCIAL') {
    const that = this;
    Modal.confirm({
      className: 'info-disable',
      width: '416px',
      title: (
        <div className={'disable-title'}>确定{record.Disabled ===1? '解冻': '冻结'}该用户吗?</div>
      ),
      content:(
        <div className={'disable-text'}>
          {record.Disabled ===1? '解冻后将恢复该用户的正常功能': '冻结后该用户将无法正常使用'}
        </div>
      ),
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
      onOk() {
        if (record.Disabled ===1) {
          http.post('/financial/sub-account/recover', {user_id:record.ID}).then(res => {
            if (Object.keys(res).length===0) {
              that.getList({page: that.state.currentPage})
            }
          }, () => {
          })
        } else {
          http.post('/financial/sub-account/disable', {user_id:record.ID}).then(res => {
            if (Object.keys(res).length===0) {
              that.getList({page: that.state.currentPage})
            }
          }, () => {
          })
        }
      },
    });
    }
  };
  delete = (record) => {
    if (record.UserType !== 'FINANCIAL') {
      const that = this;
      Modal.confirm({
        className: 'info-disable',
        width: '416px',
        title: (
          <div className={'disable-title'}>确定删除该用户吗?</div>
        ),
        content: (
          <div className={'disable-text'}>删除后将无法撤回</div>
        ),
        okText: '确定',
        cancelText: '取消',
        onCancel() {
        },
        onOk() {
          http.post('/financial/sub-account/delete', {user_id: record.ID}).then(res => {
            if (Object.keys(res).length === 0) {
              that.getList({page: that.state.currentPage})
            }
          }, () => {
          })
        },
      });
    }
  };
  edit = (record) => {
    if (record.UserType !== 'FINANCIAL') {
      this.setState({
        visibleRole: true,
        recordEdit: record
      });
    }
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  closeModal = () => {
    this.setState({
      visible: false
    });
    this.getList({page: this.state.currentPage})
  };
  cancelRole = () => {
    this.setState({
      visibleRole: false
    });
  };
  editRole = () => {
    this.setState({
      visibleRole: false
    });
  };
  getRole = () => {
    http.get('/financial/role-list').then(res => {
      this.setState({
        roleType:res.data.items || []
      })
    })
  };
  getList = (params) => {
    http.get('/financial/sub-account/list',{page:params.page}).then(res => {
      if (res) {
        this.setState({
          list: res.data.items,
          total: res.data.total,
          disabled: res.data.Disabled
        })
      }
    })
  };
  componentDidMount () {
    this.getList({page:1});
    this.getRole()
  }
  componentDidUpdate(prevProps,prevState){
    if(this.props.activeKey === '1' &&(prevProps.activeKey !== this.props.activeKey)) {
      this.getList({page:1});
      this.getRole();
      this.setState({
        currentPage: 1
      })
    }
  }
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params = {
      page:page,
    };
    this.getList({page:page})
  };
  onFinish =(values) =>{
    const params  = {
      role_name: values.role_name,
      user_id:this.state.recordEdit.ID
    };
    const that=this;
    http.post('/financial/sub-account/edit',params).then(res => {
      if (Object.keys(res).length===0) {
        Modal.info({
          closable: true,
          closeIcon: (<img src={closeImg} alt=""/>),
          className: 'info-success',
          width: '320px',
          content: (
            <div>
              <img src={infoSuccess} alt=""/>
              <p className={'add-success'}>修改成功</p>
            </div>
          ),
          okText: '我知道了',
          onCancel() {
            that.getList({page: that.state.currentPage})
            that.setState({
              visibleRole:false
            })
          },
          onOk() {
            that.getList({page: that.state.currentPage})
            that.setState({
              visibleRole:false
            })
          },
        });
      }
    }, () => {
    });
    this.setState({
     // visibleRole: false
    })
  };
  onFinishFailed(errorInfo) {
  };
  render() {
    const { visible,visibleRole,list,total} = this.state;
    return (
      <div className="firm-register-container user-manage">
        <div className="firm-register-content user-content">
          {this.state.roleType.length>0 ?(
            <div className="add-btn" onClick={() => this.addUser()}>新增用户</div>
          ):(
            <div className="add-btn-disabled">新增用户</div>
          )}
          <Modal title={'新增用户'}
                 visible={visible}
                 closeIcon={<img src={closeImg} alt=""/>}
                 onCancel={this.handleCancel}
                 onOk={this.handleCancel}
                 okText={'确定'}
                 footer={null}
                 width={640}
                 wrapClassName='modal-add-user'
                 destroyOnClose={true}
          >
            <Add close={this.closeModal}/>
          </Modal>
          <Modal title={'修改角色'}
                 visible={visibleRole}
                 closeIcon={<img src={closeImg} alt=""/>}
                 onCancel={this.cancelRole}
                 onOk={this.editRole}
                 okText={'确定'}
                 footer={null}
                 width={640}
                 wrapClassName='modal-edit-user'
                 destroyOnClose={true}
          >
            <Form
              name="basic"
              ref={this.formRef}
              onFinish={(values) => this.onFinish(values)}
              onFinishFailed={() => this.onFinishFailed()}>
              <Form.Item
                label="角色"
                name="role_name"
                className="not-extra"
                rules={is_select}
              >
                <Select placeholder="请选择角色">
                  {
                    this.state.roleType.map((item) => {
                      return <Option value={item.RoleName} key={item.RoleName}>{item.RoleName}</Option>
                    })
                  }

                </Select>
              </Form.Item>
              <Form.Item className="footer-btn">
                <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.cancelRole}>
                  取消
                </Button>
                <Button className={'submit-btn'} type="primary" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          {list&&list.length > 0? (
            <div className={'table-data'}>
              <Table columns={this.columns} dataSource={list}  pagination={false} rowKey='ID'/>
              <div className={'page-total-data'}>
                <div >
                  共计 <span className={'total-num'}>{total}</span> 条数据
                </div>
                <Pagination
                  className={'pageStyle'}
                  onChange={this.onChangePage}
                  defaultCurrent={1}
                  total={total}
                  pageSize={10}
                  showSizeChanger={false}
                  current={this.state.currentPage}
                />
              </div>
            </div>
          ): (<div className="news-list-content">
            <NoData />
          </div>)}
        </div>
      </div>
    )
  }
}
