import React from 'react';
import {Button, Form, Radio, message} from "antd";
import http from "../../../../../lib/service/http";
import {rules} from "../../../../../lib/help/rules";
import {withRouter} from "react-router-dom";
const {is_select} = rules;
 class Distribution extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      operationLogs: {}
    }
  }
  onFinishFailed(errorInfo) {
  };
  componentDidMount() {
  }
  close = () => {
    this.props.close();
  };
  onFinish =(values) =>{
    const productOrder= this.props.productOrder;
    let params = {
      product_order_id:productOrder.ID,
      sub_user_id:values.sub_user
    };
    const that =this;
    http.post(`/financial/product-order/distribute?query_status=${productOrder.OrderStatus}`, params).then(res => {
      if (Object.keys(res).length===0) {
        this.props.close();
        message.success({
          className: '',
          content: '分配成功',
          duration:2,
          onCancel() {
            that.props.history.push({
              pathname:'/transaction',
              state: ''
            })
          },
        });
        that.props.history.push({
          pathname:'/transaction',
          state: ''
        })
        // Modal.info({
        //   closable: true,
        //   closeIcon: (<img src={closeImg} alt=""/>),
        //   className: 'info-success',
        //   width: '320px',
        //   content: (
        //     <div>
        //       <img src={infoSuccess} alt=""/>
        //       <p className={'add-success'}>分配成功</p>
        //     </div>
        //   ),
        //   okText: '我知道了',
        //   onCancel() {
        //     that.props.history.push({
        //       pathname:'/transaction',
        //       state: ''
        //     })
        //   },
        //   onOk() {
        //     that.props.history.push({
        //       pathname:'/transaction',
        //       state: ''
        //     })
        //   },
        // });
      }
    }, () => {
    })
  };
  changeRadio=(e)=> {
  }
  render() {
    return (
      <div className={'sub-account-list'}>
        <Form
          name="basic"
          ref={this.formRef}
          onFinish={(values) => this.onFinish(values)}
          onFinishFailed={() => this.onFinishFailed()}
          initialValues={{
            sub_user: this.props.productOrder.FinancialSubUserID,
          }}
        >
          <Form.Item
            label=""
            name="sub_user"
            rules={is_select}
            className="not-extra"
          >
            <Radio.Group onChange={this.changeRadio()} >
              {
                this.props.distributeData.map((item) => {
                  return <Radio value={item.ID} key={item.ID}>
                    <span className={'userName'}>{item.UserName} </span>
                    <span className={'telephone'}> {'<'+ item.Telephone + '>'}</span></Radio>
                })
              }
            </Radio.Group>
          </Form.Item>
          <Form.Item className="footer-btn">
            <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.close}>
              取消
            </Button>
            <Button className={'submit-btn'} type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
export default withRouter(Distribution)
