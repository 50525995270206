import React from 'react';
import './index.scss';
import {Row, Col, Pagination, Form, Input,} from 'antd';
import CorporateInfo from "../CorporateInfo";
import BusinessInfo from "../BusinessInfo";
import InfoSupplementTip from "../InfoSupplementTip";
import iconUpload from "../../resource/images/public/jigou_logo.svg";
import vector from "../../resource/images/public/vector.svg"
import http from "../../lib/service/http";
import {withRouter} from "react-router-dom";
import {getImgPublicUrl, debounce} from "../../lib/help/helper";
import NoData from "../NoData";
import { setConfirmLink} from "../../lib/help/public";

 class BondFinancing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 16,
      currentPage: 1,
      totalPage: 100,
      financial_name: '',
      valuePrice:null,
      productInfo: [
      ],
      amount_floor: '',
      amount_ceil: '',
      rate_floor: '',
      rate_ceil: '',
      cycle_floor: '',
      cycle_ceil:'',
      isSelect: false,
      userInfo: {},
      colPrice :[
        {
          title: '不限',
          content: <CorporateInfo />,
          amount_ceil: '',
          amount_floor: '',
          isSelect: true
        },
        {
          title: '500万以下',
          content: <BusinessInfo />,
          amount_ceil: '500',
          amount_floor: '',
          isSelect: false
        },
        {
          title: '500-2000万',
          content: <InfoSupplementTip />,
          amount_ceil: '2000',
          amount_floor: '500',
          isSelect: false
        },
        {
          title: '2000-5000万',
          content: <InfoSupplementTip />,
          amount_ceil: '5000',
          amount_floor: '2000',
          isSelect: false
        },
        {
          title: '5000万以上',
          content: <InfoSupplementTip />,
          amount_ceil: '',
          amount_floor: '5000',
          isSelect: false
        },
      ],
      colDeadline: [
        {
          title: '不限',
          content: <InfoSupplementTip />,
          cycle_floor: '',
          cycle_ceil: '',
          isSelect: true
        },
        {
          title: '1-12个月',
          content: <CorporateInfo />,
          cycle_floor: '1',
          cycle_ceil: '12',
          isSelect: false
        },
        {
          title: '12-24个月',
          content: <BusinessInfo />,
          cycle_floor: '12',
          cycle_ceil: '24',
          isSelect: false
        },
        {
          title: '24个月以上',
          content: <InfoSupplementTip />,
          cycle_floor: '24',
          cycle_ceil: '',
          isSelect: false
        },
      ],
      colRate: [
        {
          title: '不限',
          content: <CorporateInfo />,
          rate_floor: '',
          rate_ceil: '',
          isSelect: true

        },
        {
          title: '0%-5%',
          content: <BusinessInfo />,
          rate_floor: '0',
          rate_ceil: '5',
          isSelect: false
        },
        {
          title: '5%-10%',
          content: <InfoSupplementTip />,
          rate_floor: '5',
          rate_ceil: '10',
          isSelect: false
        },
        {
          title: '10%以上',
          content: <InfoSupplementTip />,
          rate_floor: '10',
          rate_ceil: '',
          isSelect: false,
        },
      ],
      colWarrant: [
        {
          title: '不限',
          secured: '',
          isSelect: true
        },
        {
          title: '信用',
          secured: 'CREDIT',
          isSelect: false
        },
        {
          title: '抵押',
          secured: 'MORTGAGE',
          isSelect: false
        },
        {
          title: '质押',
          secured: 'PLEDGE',
          isSelect: false,
        },
        {
          title: '保证',
          secured: 'Secured',
          isSelect: false,
        },
      ],
    }
  }
  isSelect(select) {
    if (select) {
      this.setState({
        isSelect: false
      });
    } else {
      this.setState({
        isSelect: true
      });
    }
  }

  getWarrant = (item,index) => {
    let {colWarrant} = this.state;
    colWarrant.forEach((e,i)=> {
      if (index === 0) {
        if (i === index) {
          colWarrant[0].isSelect = !colWarrant[0].isSelect;
        }
        if(colWarrant[0].isSelect && (i > 0)) {
          e.isSelect = false;
        }
      } else {
        if (i === index) {
          colWarrant[index].isSelect = !colWarrant[index].isSelect;
        }
        if (e.isSelect) {
          colWarrant[0].isSelect = false;
        }
      }
    });
    let secured = this.state.colWarrant.filter(e => e.isSelect).map(e => e.secured).join(',')
    this.setState({
      colWarrant: [...colWarrant],
      secured: secured
    });
    this.searchList({secured: secured});
  };
  getRate = (item,index) => {
    this.state.colRate.forEach((i,e)=> {
      if(e===index)  {
        this.state.colRate[index].isSelect  = true;
      } else  {
        i.isSelect = false;
      }
    });
    this.setState({
      colRate: [...this.state.colRate],
      rate_floor: item.rate_floor || '',
      rate_ceil: item.rate_ceil || ''
    });
    this.searchList({
      rate_floor:  item.rate_floor || '',
      rate_ceil:  item.rate_ceil || ''
    });
  };
  getDeadLine = (item,index)=> {
    this.state.colDeadline.forEach((i,e)=> {
      if(e===index)  {
        this.state.colDeadline[index].isSelect  = true;
      } else  {
        i.isSelect = false;
      }
    });
    this.setState({
      colDeadline: [...this.state.colDeadline],
      cycle_floor: item.cycle_floor || '',
      cycle_ceil: item.cycle_ceil || ''
    });
    this.searchList({
      cycle_floor: item.cycle_floor || '',
      cycle_ceil: item.cycle_ceil || ''
    });
  };
  getPrice = (item,index) => {
    this.state.colPrice.forEach((i,e)=> {
      if(e===index)  {
        this.state.colPrice[index].isSelect  = true;
      } else  {
        i.isSelect = false;
      }
    });
    this.setState({
      colPrice: [...this.state.colPrice],
      amount_floor:  item.amount_floor || '',
      amount_ceil:  item.amount_ceil || '',
    });
    this.searchList({
      amount_floor:  item.amount_floor || '',
      amount_ceil:  item.amount_ceil || '',
    });
  };
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params = {
      page:page,
    };
    this.getListData(params);
  };

   searchList = (searchParam) => {
     let params = Object.assign({
       page: 1,
     }, searchParam);
     this.getListData(params);
     this.setState({
       currentPage: 1,
     });
   };
  getListData(specialParam) {
    const params = Object.assign({
      financial_name: this.state.financial_name || '',
      amount_floor: this.state.amount_floor || '',
      amount_ceil: this.state.amount_ceil || '',
      rate_floor: this.state.rate_floor || '',
      rate_ceil: this.state.rate_ceil || '',
      cycle_floor: this.state.cycle_floor || '',
      cycle_ceil: this.state.cycle_ceil || '',
      secured: this.state.secured || ''
    }, specialParam);
    http.get('/home/debt-product-list',params).then(res => {
      this.setState({
        productInfo: res.items,
        totalPage: res.total_page,
        total: res.total
      });
    })
  };
  componentWillMount() {
    let params = {
      page: 1,
    };
    this.getListData(params);
  };

   goDetail = (item)=> {
    this.props.history.push({pathname:'/bond-detail/'+item.ID});
  };
  onChangeFinance = debounce((e) => {
    this.setState({
      financial_name: e.target.value
    });
    this.searchList({financial_name: e.target.value});
  }, 500);

  render() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    const {total,productInfo,colPrice,colRate,colDeadline, colWarrant} =  this.state;
    return (
      <div className="range-content">
        <div className="debt">
          <Row className={'debtRow'}>
            <Col span={3} className="item-market">
              <span>金融机构:</span>
            </Col>
            <Col span={21} className="item-market">
              <div>
                <Input
                    className={'term-name'} placeholder={'请输入金融机构'}
                    onChange={this.onChangeFinance.bind(this)}
                />
              </div>
            </Col>
          </Row>
          <Row className={'debtRow'}>
            <Col span={3} className="item-market">
              <span>额度范围:</span>
            </Col>
            <Col span={21} className="item-market">
              <ul>
            {colPrice.map((item,index) => (
              <li key={index} title={item.title} onClick={() => this.getPrice(item,index)}>
                  <span key={index} className={item.isSelect ? 'active-select range-btn' : 'range-btn'}>{item.title}</span>
              </li>
            ))}
              </ul>
            </Col>
          </Row>
          <Row className={'debtRow'}>
            <Col span={3} className="item-market">
              <span>期限范围:</span>
            </Col>
            <Col span={21} className="item-market">
              <ul>
                {colDeadline.map((item,index) => (
                  <li key={index} title={item.title} >
                    <span key={index} className={item.isSelect ? 'active-select range-btn' : 'range-btn'} onClick={() => this.getDeadLine(item,index)}>{item.title}</span>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row className={'debtRow'}>
            <Col span={3} className="item-market">
              <span>利率范围:</span>
            </Col>
            <Col span={21} className="item-market">
              <ul>
                {colRate.map((item,index) => (
                  <li key={index} title={item.title} >
                    <span key={index} className={item.isSelect ? 'active-select range-btn' : 'range-btn'} onClick={() => this.getRate(item,index)}>{item.title}</span>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row className={'debtRow'}>
            <Col span={3} className="item-market">
              <span>担保方式:</span>
            </Col>
            <Col span={21} className="item-market">
              <ul>
                {colWarrant.map((item,index) => (
                    <li key={index} title={item.title} >
                      <span key={index} className={item.isSelect ? 'active-select range-btn' : 'range-btn'} onClick={() => this.getWarrant(item,index)}>{item.title}</span>
                    </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
        <div className="total-data">
          共计
         <span className="total-num">{total}</span>
          个产品
        </div>
          {productInfo && productInfo.length > 0 ? ( <div className="">
            {productInfo.map(item => (
              <Row key={item.Name+item.ID} title={item.title}  className=" table-data product-info">
                <Col span={4} className="img-detail">
                  {item.LogoFid ? ( <img src={getImgPublicUrl(item.LogoFid)} alt="" />): ( <img src={iconUpload} alt=""/>)}
                </Col>
                <Col span={15} className="detail">
                  <div className="title">
                    {item.Name}
                  </div>
                  <div className="address"> <img src={vector} className={'vector-img'} alt=""/>{item.FinName}</div>
                  <div className={'range-info'}>
                    <Row>
                      <Col span={6}>
                        <ul>
                          <li className={'title-num title-rate'}>{item.RateCeil? item.RateFloor+"%" +"-"+item.RateCeil+"%":item.RateFloor+"%以上"}</li>
                          <li className={'title-diff'}>利率范围</li>
                        </ul>
                      </Col>
                      <Col span={6}>
                        <ul>
                          <li className={'title-num'}>{item.CycleCeil? item.CycleFloor +"-"+item.CycleCeil+"个月":item.CycleFloor+"个月以上"}</li>
                          <li className={'title-diff'}>期限范围</li>
                        </ul>
                      </Col>
                      <Col span={6}>
                        <ul>
                          <li className={'title-num'}>{item.AmountCeil? item.AmountFloor +"-"+item.AmountCeil+"万元":item.AmountFloor+"万元以上"}</li>
                          <li className={'title-diff'}>金额范围</li>
                        </ul>
                      </Col>
                      <Col span={6}>
                        <ul>
                          <li className={'title-num'}>{item.Secured}</li>
                          <li className={'title-diff'}>担保方式</li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={4} className="btn">
                  <p className="look-detail" onClick={this.goDetail.bind(this, item)}>查看详情</p>
                  {(!userInfo.user|| userInfo.user&&userInfo.user.UserType === '企业账户')&& <p onClick={(event)=>setConfirmLink(event, this, item.ID, 'bond')} className={'apply-btn detail-btn'}>立即申请</p>}
                </Col>
              </Row>
            ))}
            <Pagination
              className={'pageStyle'}
              onChange={this.onChangePage}
              defaultCurrent={1}
              total={total}
              pageSize={10}
              showSizeChanger={false}
              current={this.state.currentPage}
            />
          </div>) : (<div className="news-list-content">
            <NoData />
          </div>)}
      </div>
    )
  }
}
export default withRouter(BondFinancing)
