import React from 'react';
import './index.scss';
import {rules} from '../../../../../lib/help/rules'
import {Breadcrumb, Button, Cascader, Form, Input, message, Select} from "antd";
import Upload from '../../../../../components/Upload'
import Img from '../../../../../resource/images/profile/img-default.jpg'
import http from "../../../../../lib/service/http";
import {Link} from "react-router-dom";
const {product_name,
  product_num,is_select,
  product_other,product_contact,upload_product_file,product_num_max,upload_file_not_required} = rules;
const {Option} = Select;
const { TextArea } = Input;
export default class AddCompany extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      validityType: '',
      validityArea: '',
      typeData: [{
        name: 1,
        key: 1,
        value:1
      }],
      confirmPriceMax: [
        ...product_num,
        {
          validator: this.handlePriceMax,
          trigger: 'onBlur'
        }
      ],
      confirmPriceMin: [
        ...product_num,
        {
          validator: this.handlePriceMin,
          trigger: 'onBlur'
        }
      ],
      optionsType:[],
      typeId: ''
    }
  }
  getType = ()=> {
    http.post('/product-type', {}).then(res => {
      res.forEach(item => {
        item.isLeaf = false
      });
      this.setState({
        optionsType:res
      });

    }, () => {
    })
  };
  componentDidMount() {
    this.getType()
  }

  handlePriceMin =(_, value, callback) =>{
    let productMin = Number(this.formRef.current.getFieldValue('price_floor'));
    let productMax = Number(this.formRef.current.getFieldValue('price_ceil'));
    if (productMax>0&&productMax <= Number(value)) {
      callback(new Error('请输入小于最大额度的数字'))
    } else if (Number(productMax)>0&&productMax > productMin) {
      this.formRef.current.setFieldsValue({price_ceil: productMax});
      callback()
    } else {
      callback()
    }
  };
  handlePriceMax =(_, value, callback) =>{
    let productMin = Number(this.formRef.current.getFieldValue('price_floor'));
    let productMax = Number(this.formRef.current.getFieldValue('price_ceil'));
    if (Number(value) > 0 &&productMin >= Number(value)) {
      callback(new Error('请输入大于最小额度的数字'))
    } else if (Number(productMin)>0&&productMax > productMin) {
      this.formRef.current.setFieldsValue({price_floor:productMin});
      callback()
    }else {
      callback()
    }
  };
  onFinish(values){
    values.product_type_id = this.state.typeId;
    values.image = values.image?values.image.url:'';
    values.price_ceil = String(values.price_ceil);
    values.price_floor = String(values.price_floor);
    const that =this;
    http.post('/financial/enterprise-service/publish', values).then(res => {
      // Modal.info({
      //   closable: true,
      //   closeIcon: (<img src={closeImg} alt=""/>),
      //   className: 'info-success',
      //   width: '320px',
      //   content: (
      //     <div>
      //       <img src={infoSuccess} alt=""/>
      //       <p className={'add-success'}>新增成功</p>
      //     </div>
      //   ),
      //   okText: '我知道了',
      //   onCancel() {
      //     that.props.history.push({
      //       pathname:'/user-info/sub-company-service',
      //       state: '企业服务'
      //     })
      //   },
      //   onOk() {
      //     that.props.history.push({
      //       pathname:'/user-info/sub-company-service',
      //       state: '企业服务'
      //     })
      //   },
      // });
      message.success({
        className: '',
        content: '新增成功',
        duration: 2,
        onCancel() {
          that.props.history.push({
                  pathname:'/user-info/sub-company-service',
                  state: '企业服务'
                })
        },
      });
      this.props.history.push({
        pathname:'/user-info/sub-company-service',
        state: '企业服务'
      })
    }, () => {
    })
  };
  handleCancel= ()=>{
    this.props.history.push({
      pathname:'/user-info/sub-company-service',
      state: '企业服务'
    })
  };
  onFinishFailed(errorInfo) {
  };
  onFileChange = (fileKey, file) => {
    this.formRef.current.setFieldsValue({[fileKey]: file});
    this.formRef.current.validateFields([fileKey]);
  };
  onChange=(checkedValues) => {
  };
  onChangeType =(e,selectedOptions) => {
    if(selectedOptions.length>0) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      let ID = targetOption.ID;
      this.setState({
        typeId: ID
      });
      http.post('/product-type', {ID}).then(res => {
        targetOption.loading = false;
        if (e.length <= 1) {
          targetOption.children = res;
          if (e.length <= 0) {
            targetOption.children.forEach(item => {
              item.isLeaf = false
            });
          }
        }
        this.setState({
          optionsType: [...this.state.optionsType],
        });
      }, () => {
      })
    }
  };
  loadData=()=> {
    this.setState({
      optionsType: [...this.state.optionsType],
    });
  };
  preventBubble =(e)=>{
    e.preventDefault();
  };
  render() {
    const {confirmPriceMax,optionsType,confirmPriceMin} = this.state;
    return (
      <div className="firm-register-container add-debt-finance">
        <div className={'breadcrumb detail-breadcrumb'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link className={'link'} to={'/profile'}>个人中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link className={'link'} to={'/user-info/sub-company-service'}>企业服务</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className={'link'}>新增企业服务</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="firm-register-content detail-breadcrumb-margin">
          <div className={'title'}>新增企业服务</div>
          <div className={'add-debt-content'}>
            <Form
              name="basic"
              ref={this.formRef}
              onFinish={(values) => this.onFinish(values)}
              onFinishFailed={() => this.onFinishFailed()}>
              <Form.Item
                label="产品名称"
                name="name"
                rules={product_name}
                className={'item-height'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入产品名称'} maxLength={20} />
              </Form.Item>
              <Form.Item
                className="select-type"
                label="类型"
                rules={is_select}
                name='product_type_id'>
                <Cascader
                  fieldNames={{ label: 'Name', value: 'ID',children: 'children'}}
                  options={optionsType}
                  onChange={this.onChangeType}
                  placeholder={'请选择'}
                  loadData={this.loadData}
                  changeOnSelect
                  className={'cascader-select'}
                />
              </Form.Item>
              <Form.Item
                label="图片"
                name="image"
                className={'not-extra upload-item input-password'}
                rules={upload_file_not_required}
                extra={'只支持.jpg/png格式，文件大小不超过2M,文件名中不要包含空格、&、=等特殊字符'}
                validateFirst={true}
              >
                <Upload
                  onChange={this.onFileChange.bind(this)}
                  defImg={Img}
                  fileKey={'image'}
                />
              </Form.Item>
              <div className={'price-range'}>
                <Form.Item
                  label="价格区间"
                  name="price_floor"
                  rules={confirmPriceMin}
                  extra={'输入非负金额，精度最大四位小数'}
                  className={'input-price input-flex input-password'}
                  validateFirst={true}
                >
                  <Input size="middle" placeholder={'请输入最小金额'} />
                </Form.Item>
                <div className={'unit-text'}>万元</div>
                <span className={'to-text'}>至</span>
                <Form.Item
                  name="price_ceil"
                  rules={confirmPriceMax}
                  className={'input-price input-flex'}
                  validateFirst={true}
                >
                  <Input size="middle" placeholder={'请输入最大金额'}/>
                </Form.Item>
                <div className={'unit-text'}>万元</div>
              </div>
              <Form.Item
                label="联系方式"
                rules={product_contact}
                name="contact"
                className={'contact-way item-extra input-password'}
                validateFirst={true}
                extra={'输入手机号或邮箱地址'}
              >
                <Input size="middle" placeholder={'请输入联系方式'}/>
              </Form.Item>
              <Form.Item
                label="项目介绍"
                rules={product_other}
                name="description"
                className="not-extra input-remark"
                validateFirst={true}
              >
                <TextArea size="middle" placeholder={'项目介绍'} />
              </Form.Item>
              <Form.Item className="footer-btn">
                <Button className={'submit-btn'} type="primary" htmlType="submit">
                  提交
                </Button>
                <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.handleCancel}>
                  取消
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
