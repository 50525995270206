import React from 'react';

export default class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="">
                detail
            </div>
        )
    }
}
