import React from 'react';
import {Button, Checkbox,  Form, Input, message} from "antd";
import http from "../../../../../lib/service/http";
import {rules} from "../../../../../lib/help/rules";
const {roleName,pactSecured} = rules;
export default class AddRole extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      options: [],
      initialValues: {
        role_name: '',
        role_items: []
      },
    }
  }
  onFinish =(values) =>{
    if (Object.keys(this.props.roleEditData).length >0) {
      let params = {
        ...values,
        role_name:this.props.roleEditData.RoleName
      };
      http.post('/financial/edit-role', params).then(res => {
        if (Object.keys(res).length===0) {
          this.props.close();
          message.success({
            className: 'role-add',
            content: '编辑成功',
            duration: 2,
          });
        }
      }, () => {
      })
    } else {
      http.post('/financial/create-role', values).then(res => {
        if (Object.keys(res).length===0) {
          this.props.close();
          message.success({
            className: 'role-add',
            content: '新增成功',

            duration: 2,
          });
        }
      }, () => {
      })
    }
  };
  onFinishFailed(errorInfo) {
  };
  componentDidMount () {
   this.getRoleOptions();
    if (Object.keys(this.props.roleEditData).length >0) {
      http.get('/financial/role-detail',{role_name:this.props.roleEditData.RoleName}).then(res => {
        const initialValues = this.state.initialValues;
        initialValues.role_items = res.data;
        this.setState({
          initialValues
        });
        this.formRef.current.setFieldsValue(initialValues);
      });
    }
  }
  getRoleOptions() {
    http.get('/financial/role-options').then(res => {
     this.setState({
       options: res.data
     })
    })
  };
  close = () => {
    this.props.close();
  };
  onChange=() => {
  };
  render() {
    const {options} = this.state;
    return (
      <div className="modal-add-content">
        <div className="form-content add-from-content">
          <div className="left-from">
            <Form
              name="basic"
              ref={this.formRef}
              onFinish={(values) => this.onFinish(values)}
              onFinishFailed={() => this.onFinishFailed()}
              initialValues={{
                role_items: ["发布贷款融资", "发布企业服务", "交易管理", "更新机构信息"],
              }}
            >
              {Object.keys(this.props.roleEditData).length >0 ?
                (<div className={'edit-flex'}>
                  <div className={'edit-title'}>角色名称</div>
                  <div className={'edit-text'}>{this.props.roleEditData.RoleName}</div>
                </div>)
                :
                (<Form.Item
                  label="角色名称"
                  name="role_name"
                  rules={roleName}
                  extra={'请输入20位字符'}
                  className={'input-password'}
                  validateFirst={true}
                >
                  <Input size="middle" placeholder={'请输入角色名称'}/>
                </Form.Item>)
              }
              <Form.Item
                label="权限范围"
                name="role_items"
                className={'checkout-box'}
                rules={pactSecured}
              >
                <Checkbox.Group options={options}   onChange={this.onChange} />
              </Form.Item>
              <Form.Item className="footer-btn">
                <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.close}>
                  取消
                </Button>
                <Button className={'submit-btn'} type="primary" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
