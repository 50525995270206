import React from 'react';
import './index.scss';
import { Form, Input, Row, Col, Pagination,DatePicker} from 'antd';
import iconUpload from "../../resource/images/public/jigou_logo.svg";
import http from "../../lib/service/http";
import {withRouter} from "react-router-dom";
import {getImgPublicUrl} from "../../lib/help/helper";
import NoData from "../NoData";

const { RangePicker } = DatePicker;

 class Financial extends React.Component {
  formRef = React.createRef();
  formRefDate = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      total: 16,
      currentPage: 1,
      totalPage: 100,
      valuePrice:null,
      keywords: '',
      startDate: '',
      endDate: '',
      productInfo: [],
      validates: {
        code: [
          {
          }
        ]
      },
    }
  }
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params = {
      keywords: this.state.keywords,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      page:page,
    };
    this.getListData(params)
  };
  onChangeCode = value => {
    this.setState({
      keywords: value.target.value,
    });
  };
  onChangeDate = (value, dateString) => {
    this.setState({
      startDate: dateString[0] + ' 00:00:00',
      endDate: dateString[1] + ' 23:59:59'
    });
  };
  getListData(params) {
    let tmp = {
      keywords:params.keywords,
      startDate:params.startDate,
      endDate:params.endDate,
      page:params.page
    };
    http.get('/home/financial-list',tmp).then(res => {
      this.setState({
        productInfo: res.items,
        totalPage: res.total_page,
        total: res.total
      });
    })
  };
  componentWillMount() {
    let params = {
      keywords: '',
      startDate: '',
      endDate: '',
      page: 1,
    };
    this.getListData(params)
  };
   componentDidUpdate(prevProps,prevState){
     if(this.props.activeKey === '2' && (prevProps.activeKey !== this.props.activeKey)) {
       let params = {
         keywords: '',
         startDate: '',
         endDate: '',
         page: 1,
       };
       this.getListData(params);
       this.reset()
       this.setState({
         currentPage: 1
       })
     }
   }
  search = () => {
    let params = {
      keywords: this.state.keywords,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      page:'',
    };
    this.getListData(params);
    this.setState({
      currentPage: 1,
    });
  };
  reset = () => {
    this.formRef.current.resetFields();
    this.formRefDate.current.resetFields();
    let params = {
      keywords: '',
      startDate: '',
      endDate: '',
      page: 1,
    };
    this.setState({
      currentPage: 1,
    });
    this.getListData(params)
  };
  goDetail = (item)=> {
    this.props.history.push({pathname:'/financial-detail/'+item.ID});
  };
  render() {
    const {total,productInfo} =  this.state;
    return (
      <div className="range-content">
        <div className="search">
          <Row className={'searchRow'}>
            <Col span={6} className="item-enterprise">
                  <Form
                    className="range"
                    name="basic"
                    ref={this.formRef}
                  >
                    <span>名称:</span>
                    <Form.Item className="" name='code' rules={this.state.validates.code}>
                      <Input
                        placeholder={'请输入名称'}
                        onChange={this.onChangeCode.bind(this)}
                      />
                    </Form.Item>
                  </Form>
            </Col>
            <Col span={10} className="item-enterprise">
              <Row>
                <Col span={24}>
                  <Form
                    className="range"
                    name="basic"
                    ref={this.formRefDate}
                  >
                    <span>注册时间:</span>
                    <Form.Item className="" name='priceMin' rules={this.state.validates.date}>
                      <RangePicker
                        placeholder={['开始时间', '结束时间']}
                        onChange={this.onChangeDate.bind(this)}/>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col span={8} className="item-enterprise btn">
              <span className="detail-btn" onClick={() => this.search()}>筛选</span>
              <span className="detail-btn" onClick={() => this.reset()}>重置</span>
            </Col>
          </Row>
        </div>
        <div className="total-data">
          共计
          <span className="total-num">{total}</span>
          个机构
        </div>
        {productInfo && productInfo.length > 0 ? ( <div className="company-detail">
          {productInfo.map(item => (
            <Row key={item.index} title={item.name}  className=" table-data product-info">
              <Col span={4} className="img-detail">
                {item.LogoFid ? ( <img src={getImgPublicUrl(item.LogoFid)} alt="" />): ( <img src={iconUpload} alt=""/>)}
              </Col>
              <Col span={15} className="detail">
                <div className="title">
                  {item.Name}
                </div>
                <div className="business-scope"> <div>主营业务: </div><div>{item.BusinessScope}</div></div>
              </Col>
              <Col span={4} className="btn"><span className="detail-btn" onClick={this.goDetail.bind(this, item)}>查看详情</span></Col>
            </Row>
          ))}
          <Pagination
            className={'pageStyle'}
            onChange={this.onChangePage}
            defaultCurrent={1}
            total={total}
            pageSize={10}
            showSizeChanger={false}
            current={this.state.currentPage}
          />
        </div>) :  (<div className="news-list-content">
          <NoData />
        </div>)}
      </div>
    )
  }
}
export default withRouter(Financial)
