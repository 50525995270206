import React from 'react';
import {Row, Col,Carousel} from 'antd';
import './index.scss'
import {Link} from 'react-router-dom'
import {setConfirmLink, setBtnStatus} from "../../lib/help/public";

import iconCurrent1 from '../../resource/images/index/icon-current-number-1.svg'
import iconCurrent2 from '../../resource/images/index/icon-current-number-2.svg'
import iconCurrent3 from '../../resource/images/index/icon-current-number-3.svg'
import iconCurrent4 from '../../resource/images/index/icon-current-number-4.svg'
import iconCurrent5 from '../../resource/images/index/icon-current-number-5.svg'

import imgProduct1 from '../../resource/images/index/img-product-1.svg'
import imgProduct2 from '../../resource/images/index/img-product-2.svg'
import imgProduct3 from '../../resource/images/index/img-product-3.svg'

import imgCoreAside1 from '../../resource/images/index/img-core-aside-1.svg'
import imgCoreAside2 from '../../resource/images/index/img-core-aside-2.svg'
import iconRight from '../../resource/images/index/icon-right.svg'

import imgPlatformAside from '../../resource/images/index/img-platform-aside.svg'

import iconBank1 from '../../resource/images/index/icon-bank-1.png'
import iconBank2 from '../../resource/images/index/icon-bank-2.png'
import iconBank3 from '../../resource/images/index/icon-bank-3.png'
import iconBank4 from '../../resource/images/index/icon-bank-4.png'
import iconBank5 from '../../resource/images/index/icon-bank-5.png'
import iconBank6 from '../../resource/images/index/icon-bank-6.png'
import iconBank7 from '../../resource/images/index/icon-bank-7.png'
import iconBank8 from '../../resource/images/index/icon-bank-8.png'
import http from "../../lib/service/http";
import imgNoData from '../../resource/images/public/img-no-data.svg'
import {getImgPublicUrl} from "../../lib/help/helper";



export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bannerList: [],
            registerInfo: [
                {
                    icon: iconCurrent1,
                    number: 0,
                    text: '注册企业&机构（家）'
                },
                {
                    icon: iconCurrent2,
                    number: 0,
                    text: '金融产品（个）'
                },
                {
                    icon: iconCurrent3,
                    number: 0,
                    text: '贷款融资项（个）'
                },
                {
                    icon: iconCurrent4,
                    number: 0,
                    text: '企业服务项（个）'
                },
                {
                    icon: iconCurrent5,
                    number: 0,
                    text: '达成融资额（万元）'
                },
            ],
            productInfo: [
                {
                    img: imgProduct1,
                    text: '产品服务超市',
                    link: '/'
                },
                {
                    img: imgProduct2,
                    text: '政策新闻与平台资讯',
                    link: '/'
                },
                {
                    img: imgProduct3,
                    text: '企业与机构',
                    link: '/'
                },
            ],
            productArr: [],
            serviceArr: [],
            productTotal: 0,
            serviceTotal: 0,
            bankIcon: [iconBank1, iconBank2, iconBank3, iconBank4, iconBank5, iconBank6, iconBank7, iconBank8]
        }
    }

    getHomeStatistics() {
        http.get(`home/overview`).then(res => {
            let tmpArr = this.state.registerInfo;
            tmpArr[0].number = res.EntFinTotal;
            tmpArr[1].number = res.ProductTotal;
            tmpArr[2].number = res.DebtProductTotal;
            tmpArr[3].number = res.EnterpriseServiceTotal;
            tmpArr[4].number = res.DebtAmountTotal;
            this.setState({
                registerInfo: tmpArr
            });
        })
    }

    getNewProductList() {
        http.get(`home/new-debt-product-list`).then(res => {
            if (Number(res.total) > 0) {
                this.setState({
                    productArr: res.items ? res.items : [],
                    productTotal: res.total
                });
            }
        })
    }

    getNewCompanyService() {
        http.get(`home/new-enterprise-service-list`).then(res => {
            if (Number(res.total) > 0) {
                this.setState({
                    serviceArr: res.items ? res.items : [],
                    serviceTotal: res.total
                });
            }
        })
    }

    toProductDetails=(id)=>{
        this.props.history.push({pathname:'/bond-detail/'+id});
    };

    toServiceDetails=(id)=>{
        this.props.history.push({pathname:'/corporate-detail/'+id});
    };
    getBanner=()=> {
        http.get(`home/banner`).then(res => {
           this.setState({
               bannerList: res

           })
        })
    };
    componentDidMount() {
        this.getHomeStatistics();
        this.getNewProductList();
        this.getNewCompanyService();
        this.getBanner()
    }

    componentWillUnmount() {
    }

    render() {
        const {registerInfo, bankIcon,bannerList} = this.state;

        return (
            <div className="index-container">
                <div className="index-banner-container container">
                    <Carousel autoplay autoplaySpeed={4000}>
                        {bannerList.map((item, index) => {
                            return <div className="banner-content" key={index}>
                                <div className={'banner-img'}>
                                    <img src={getImgPublicUrl(item.ImageFid)} alt=""/>
                                </div>
                            </div>
                        })}
                    </Carousel>
                </div>
                <div className="core-services current-info-box">
                    <div className={'current-number content'}>
                        <Row gutter={[16, 0]}>
                            {
                                registerInfo.map((item, index) => {
                                    return (
                                        <Col className="gutter-row" span={5} key={index}>
                                            <div className={'number-item'}>
                                                <img src={item.icon} alt="" className="item-icon"/>
                                                <div className="text-box">
                                                    <p className="number-text">{parseInt(item.number)}</p>
                                                    <p className="desc-text">{item.text}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="core-item-box">
                        <div className="core-item content bond-info">
                            <div className="aside-info">
                                <p className="aside-title">贷款融资</p>
                                <p className="desc">面向全网商业用户</p>
                                <p className="text">高效对接，为企业降低融资成本</p>
                                <img src={imgCoreAside2} alt="" className="aside-img"/>
                            </div>
                            <div className="core-item-content">
                                <div className="content-title-box">
                                    <p className="title-text">
                                        最新贷款融资
                                        <span className={'number-text'}>共{this.state.productTotal}个</span>
                                    </p>
                                    {
                                        this.state.productArr.length > 0 &&
                                        <Link to={'/product-supermarket?tab=1'} className={'link-right'}>
                                            查看更多
                                            <img src={iconRight} alt="" className="icon-right"/>
                                        </Link>
                                    }
                                </div>



                                <Row gutter={[0, 0]} className='number-info-box'>

                                    {
                                        this.state.productArr.length >0 ?

                                        this.state.productArr.map((item,index)=>{
                                            return (
                                                    <Col className={['gutter-row info-row product-box',{'first-line' : index===0||index===1},{'right-line' : index===2}]} span={12} key={'product'+index} onClick={this.toProductDetails.bind(this,item.ID)}>
                                                        <p className="title">
                                                            {item.Name}
                                                        </p>
                                                        <Row gutter={[0, 0]}>
                                                            <Col className="gutter-row" span={8}>
                                                                <div className="number-content">
                                                                    <p className="amount">{item.AmountCeil? item.AmountFloor +"-"+item.AmountCeil+"万元":item.AmountFloor+"万元以上"}</p>
                                                                    <p className="desc">金额范围</p>
                                                                </div>
                                                            </Col>
                                                            <Col className="gutter-row" span={8}>
                                                                <div className="number-content">
                                                                    <div className="number-item">
                                                                        <p className="number-text">{item.CycleCeil? item.CycleFloor +"-"+item.CycleCeil+"个月":item.CycleFloor+"个月以上"}</p>
                                                                        <p className="desc">期限范围</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col className="gutter-row" span={8}>
                                                                <div className="number-content">
                                                                    <div className="number-item">
                                                                        <p className="number-text">{item.Secured}</p>
                                                                        <p className="desc">担保方式</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            setBtnStatus() &&
                                                            <p onClick={(event)=>setConfirmLink(event, this, item.ID, 'bond')} className="apply-btn">立即申请</p>
                                                        }
                                                    </Col>
                                            )
                                        }) :
                                                <img src={imgNoData} alt="" className={'no-data-img'}/>
                                    }
                                </Row>
                            </div>
                        </div>

                        <div className="core-item content corporate-services">
                            <div className="aside-info">
                                <p className="aside-title">企业服务</p>
                                <p className="desc">面向全网企业用户</p>
                                <p className="text">以质取胜，用心服务，享受政府补贴及优惠</p>
                                <img src={imgCoreAside1} alt="" className="aside-img"/>
                            </div>
                            <div className="core-item-content">
                                <div className="content-title-box">
                                    <p className="title-text">
                                        企业服务
                                        <span className={'number-text'}>共{this.state.serviceTotal}个</span>
                                    </p>
                                    {
                                        this.state.serviceArr.length > 0 &&
                                        <Link to={'/product-supermarket?tab=2'} className={'link-right'}>
                                            查看更多
                                            <img src={iconRight} alt="" className="icon-right"/>
                                        </Link>
                                    }
                                </div>
                                <Row gutter={[0, 0]} className='number-info-box'>
                                    {
                                        this.state.serviceArr.length >0 ?

                                        this.state.serviceArr.map((item, index) => {
                                            return (
                                                    <Col className={['gutter-row info-row service-box',{'first-line' : index===0||index===1||index===2},{'right-line' : index === 1 || index===3||index===4}]} span={8}
                                                         key={'service' + index} onClick={this.toServiceDetails.bind(this,item.ID)}>
                                                        <Row gutter={[0, 0]}>
                                                            <div className="number-content">
                                                                <p className="title">{item.Name}</p>
                                                                <p className="desc">价格范围 <span
                                                                        className='amount'>{item.PriceFloor}-{item.PriceCeil}万元</span></p>
                                                                {
                                                                    setBtnStatus() &&
                                                                    <p onClick={(event)=>setConfirmLink(event, this, item.ID, 'company')} className="apply-btn">立即申请</p>
                                                                }
                                                            </div>
                                                        </Row>
                                                    </Col>
                                            )
                                        }) :
                                                <img src={imgNoData} alt="" className={'no-data-img'}/>
                                    }
                                </Row>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="platform-introduction-box">
                    <div className="title-box">
                        <p className="title">平台介绍</p>
                        <p className="desc">依托金融科技，整合各类需求场景，满足中小微企业的多样化需求</p>
                    </div>
                    <div className="platform-introduction content">
                        <div className="desc-box">
                            <img src={imgPlatformAside} alt="" className="aside-img"/>
                            <span className="platform-desc">
                                    大足兴农智慧金融服务平台是服务于重庆市中小企业的地方资本市场综合金融服务平台和政策运用平台。平台凭借自身强大的资源整合能力，广泛汇聚各类金融机构及金融产品，对接各类政府资源，充分整合专业化投资、咨询、服务机构力量，为企业提供贷款融资、企业发展等服务对接，为上市后备企业和中小微企业在打通融资瓶颈、规范公司治理、建立现代企业制度、增强创新发展能力、制定上市计划等方面提供全过程、全方位、多样化、一站式的综合服务。
                                </span>
                        </div>
                    </div>
                </div>
                <div className="cooperative-agency">
                    <div className="cooperative-agency-content content">
                        <p className="title">合作机构</p>
                        <p className="desc"></p>
                        <div className="cooperative-logo cooperative-logo-first">
                            <img src={bankIcon[0]} alt="" className="icon-bank"/>
                            <img src={bankIcon[1]} alt="" className="icon-bank"/>
                            <img src={bankIcon[2]} alt="" className="icon-bank"/>
                            <img src={bankIcon[3]} alt="" className="icon-bank"/>
                        </div>
                        <div className="cooperative-logo">
                            <img src={bankIcon[4]} alt="" className="icon-bank"/>
                            <img src={bankIcon[5]} alt="" className="icon-bank"/>
                            <img src={bankIcon[6]} alt="" className="icon-bank"/>
                            <img src={bankIcon[7]} alt="" className="icon-bank"/>
                        </div>
                    </div>
                </div>
                <div className="boost-container">
                    <div className="boost-content content">
                        <p className="title">
                            立即申请助力企业发展
                        </p>
                        <p className="desc">
                            专业金融服务，助力企业创新发展
                        </p>
                        {
                            !sessionStorage.getItem('userInfo') &&
                            <Link to={'/register-firm'} className="start-btn">
                                开始使用
                            </Link>
                        }

                    </div>
                </div>
            </div>
        )
    }
}
