import Cookie from "js-cookie";
import requestSign from "zigg-request-sign"
export function localTime(str) {
    if (!str) return '--';
    let date;
    if (str.indexOf('T') !== -1) { // date格式的字符串
        date = new Date(str);
    } else {
        // ios 无法识别 'YYYY-MM-DD HH:mm:ss' - ，转为 /
        date = new Date(str.replace(/-/g, "/"));
    }
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');
    let hour = (date.getHours() + '').padStart(2, '0');
    let minute = (date.getMinutes() + '').padStart(2, '0');
    let second = (date.getSeconds() + '').padStart(2, '0');

    return date.getFullYear() + "-" + month + "-" + day + " " +  hour + ":" + minute + ":" + second;
}
export function localDate(str) {
    if (!str) return '--';
    let date;
    if (str.indexOf('T') !== -1) { // date格式的字符串
        date = new Date(str);
    } else {
        // ios 无法识别 'YYYY-MM-DD HH:mm:ss' - ，转为 /
        date = new Date(str.replace(/-/g, "/"));
    }
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');
    let hour = (date.getHours() + '').padStart(2, '0');
    let minute = (date.getMinutes() + '').padStart(2, '0');
    let second = (date.getSeconds() + '').padStart(2, '0');

    return date.getFullYear() + "-" + month + "-" + day;
}
export function DateToLocalTime(date) {
    if(!date)return'--';
    let str = date.toISOString();
    let dateStr = str.split('T')[0];
    let timeStr = date.toTimeString().split(' ')[0];
    return dateStr + ' ' + timeStr;
}
function getImg(url,token){
    let params ={};
    if(token) {
         params =requestSign(process.env.REACT_APP_SECRET, url,{token: token });
    } else {
        params =requestSign(process.env.REACT_APP_SECRET, url);
    }
    let array = [];
    for(let i in params){
        let data  = i+'='+params[i];
        array.push(''+data+'');
    }
    return array.join('&')
}
export function getImgUrl(id) {
    return process.env.REACT_APP_API+'/v1/private-file/'+id+'?token='+Cookie.get('token')+'&'+getImg('/v1/private-file',Cookie.get('token'));
}
export function getImgPublicUrl(id) {
    return process.env.REACT_APP_API+'/v1/public-file/'+id+'?'+getImg('/v1/public-file');
}
export function genUuid() {
    let d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
}

export function setHidePhone(number) {
    if(!number)return '';
    return number.substr(0, 3) + '****' + number.substr(7, 4)
}
export function debounce(fn, delay) {
    const current = {
        fn,
        timer: null
    };

    return function f(...args) {
        if (current.timer) {
            clearTimeout(current.timer);
        }
        current.timer = setTimeout(() => {
            current.fn(...args);
        }, delay);
    };
}