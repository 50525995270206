import React from 'react';
import {Row, Col, Form, Input, Checkbox, Button, message} from "antd";
import {rules} from "../../../../../lib/help/rules";
import http from "../../../../../lib/service/http";
import {withRouter} from "react-router-dom";
const {product_num,product_rate,pactSecured, product_date} = rules;
 class transactionList extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      userInfo: {
        user: {}
      },
      productObject: {},
      orderObject: {},
      confirmRate: [
        ...product_rate,
        {
          validator: this.handleRate,
          trigger: 'onBlur'
        },
      ],
    }
  }
  close = () => {
    this.props.close();
  };
  getSecureMap = ()=> {
    http.get('/financial/debt-product/secure_map').then(res => {
      let obj = {};
      let array = [];
      Object.keys(res.data).forEach((key) => {
        obj.label = res.data[key];
        obj.value = key;
        return array.push({label:res.data[key],value:key})
      });
      this.setState({
        options: array
      });
    })
  };
   handleRate =(_, value, callback) =>{
     if (Number(value) > 0&&!(Number(value) <100)) {
       callback(new Error('请输入小于100的数字'))
     } else {
       callback()
     }
   };
  componentDidMount () {
    this.getSecureMap();
    this.setState({
      productObject: this.props.productObject,
      orderObject: this.props.orderObject
    })
  };
  onFinish =(values) =>{
    values.month_limit = Number(values.month_limit);
     let params = {
       product_order_id: this.state.orderObject.ID,
       ...values
     };
     const that =this;
     http.post('/financial/product-order/match-success', params).then(res => {
       message.success({
         className: '',
         content: '撮合成功',
         duration: 2,
         onCancel() {
           that.props.history.push({
             pathname:'/transaction',
             state: ''
           })
         },
       });
       that.props.history.push({
         pathname:'/transaction',
         state: ''
       })
     }, () => {
     })
   };

  onFinishFailed(errorInfo) {
  };
  onChange=() => {
  };
  render() {
    const {options,productObject,orderObject,confirmRate} = this.state;
    return (
      <div className={'transaction-list'}>
        <Row className={'enterprise-info-header'}>
          <Col span={4} className={'enterprise-info-title'}>交易产品</Col>
          <Col span={20} className={'enterprise-info-text'}>{productObject.Name}</Col>
        </Row>
        <Row className={'enterprise-info-header'}>
          <Col span={4} className={'enterprise-info-title'}>申请企业</Col>
          <Col span={20} className={'enterprise-info-text'}>{orderObject.EnterpriseName}</Col>
        </Row>
        <div className={'line'}/>
        <Form
          name="basic"
          ref={this.formRef}
          onFinish={(values) => this.onFinish(values)}
          onFinishFailed={() => this.onFinishFailed()}
        >
          { orderObject.ProductType === 'SERVICE'?(
            <div>
              <div style={{display: "flex"}}>
                <Form.Item
                  label="价格"
                  name="amount"
                  rules={product_num}
                  className={'input-price input-flex input-password'}
                  validateFirst={true}
                >
                  <Input size="middle" placeholder={'请输入金额'} />
                </Form.Item>
                <div className={'unit-text'}>万元</div>
              </div>
            </div>
          ):(<div>
            <div style={{display: "flex"}}>
              <Form.Item
                label="金额"
                name="amount"
                rules={product_num}
                className={'input-price input-flex input-password'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入金额'} />
              </Form.Item>
              <div className={'unit-text'}>万元</div>
            </div>
            <Form.Item
              rules={pactSecured}
              label="担保方式"
              name="order_secured"
              className={'item-height input-flex input-password'}
            >
              <Checkbox.Group options={options}   onChange={this.onChange.bind(this)} />
            </Form.Item>
            <div style={{display: "flex"}}>
              <Form.Item
                label="期限"
                name="month_limit"
                rules={product_date}
                className={'input-price input-flex input-password'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入期限'} />
              </Form.Item>
              <div className={'unit-text'}>个月</div>
            </div>
            <div style={{display: "flex"}}>
              <Form.Item
                label="利率"
                name="interest_rate"
                rules={confirmRate}
                className={'input-price input-flex input-password'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入利率'} />
              </Form.Item>
              <div className={'unit-text'}>%</div>
            </div>
          </div>)}

          <Form.Item className="footer-btn">
            <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.close}>
              取消
            </Button>
            <Button className={'submit-btn'} type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
export default withRouter(transactionList)
