import React from 'react';
import './index.scss';
import {Breadcrumb, Tabs} from "antd";
import Role from "./RolePage";
import User from "./UserPage";
import {Link} from "react-router-dom";
const {TabPane} = Tabs;
export default class AuthorityManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey:  '1'
    }
  }
  changeTab=(key)=> {
    this.setState({
      activeKey: key
    })
  };
  render() {
    return (
      <div className="firm-register-container authority-manage">
          <div className={'breadcrumb detail-breadcrumb'}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link className={'link'} to={'/profile'}>个人中心</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a className={'link'}>权限管理</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        <div className="firm-register-content detail-breadcrumb-margin">
         <div className={'title'}>权限管理</div>
          <Tabs defaultActiveKey="1" className={'tab-box'} tabBarGutter={48} onChange={this.changeTab.bind(this)}>
            <TabPane tab={
              <p className="tab-item tab-item-1">用户管理</p>
            } key="1">
              <User activeKey={this.state.activeKey} />
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">角色管理</p>
            } key="2">
              <Role activeKey={this.state.activeKey} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
