import React from 'react';
import {Form, Input, Row, Col, Radio, DatePicker, Select} from 'antd';
import './index.scss'
import http from './../../lib/service/http'
import Upload from '../../components/Upload'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

import imgLicense from '../../resource/images/public/img-license.svg'
import {rules} from "../../lib/help/rules";


const {time_zone, pact, not_null, upload_file, is_number, is_select, upload_file_not_required, credit_code} = rules
const {RangePicker} = DatePicker;
const {Option} = Select;


export default class Login extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            validityType: '',
            regexpTime: [
                ...not_null,
                ...pact
            ],
            enterpriseType: [
            ],
        }

        if (props.onRef) {//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    onFinish(values) {
        if (values.business_license_validity === '非长期') {
            values.business_license_validity = values.range_picker[0].format('YYYY-MM-DD') + ' - ' + values.range_picker[1].format('YYYY-MM-DD')
        }else {
            values.business_license_validity = 'PERMANENT';
        }
        if(values.logo) {
            values.logo = values.logo.url;
        }
        values.business_license = values.business_license.url;
        values.business_license_approval_date = values.business_license_approval_date.format('YYYY-MM-DD');
        values.founded_date = values.founded_date.format('YYYY-MM-DD');
        this.props.setData(values)
    };

    checkValidity = (type) => {
        this.setState({
            validityType: type.target.value
        })
    }
    onFileChange = (fileKey, file) => {
        this.formRef.current.setFieldsValue({[fileKey]: file});
        this.formRef.current.validateFields([fileKey]);
    }

    getEnterpriseType() {
        http.get('/enterprise/type').then(res => {
            this.setState({
                enterpriseType: res.ent_type,
            })
        })
    }

    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
        this.getEnterpriseType()
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="corporate-info-container">
                <div className="corporate-info-content content">
                    <div className="form-content">
                        <Row gutter={[0, 0]} className='number-info-box'>
                            <Col className="gutter-row left-from" span={16}>
                                <Form
                                    name="basic"
                                    ref={this.formRef}
                                    onFinish={(values) => this.onFinish(values)}
                                    onFinishFailed={(values) => this.onFinishFailed(values)}>

                                    <Form.Item
                                        label="企业名称"
                                        name="name"
                                        rules={not_null}
                                        className={'not-extra input-name'}
                                    >
                                        <Input size="middle" placeholder={'请输入企业名称'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="统一社会信用代码"
                                        name="unified_social_credit_code"
                                        rules={credit_code}
                                        className={'not-extra corporate-code'}
                                    >
                                        <Input size="middle" placeholder={'请输入统一社会信用代码'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="注册资本"
                                        name="registered_capital"
                                        rules={is_number}
                                        className={'input-phone'}
                                        extra={'请输入阿拉伯数字'}
                                    >
                                        <Input size="middle"
                                               suffix={<p className="suffix-text">元整</p>}
                                               placeholder={'请输入金额'}/>
                                    </Form.Item>

                                    <Form.Item
                                        name="type"
                                        label="企业类型"
                                        rules={is_select}
                                        className={'not-extra input-phone'}
                                    >
                                        <Select placeholder="请选择企业类型">
                                            {
                                                this.state.enterpriseType.map((item) => {
                                                     return <Option value={item.name} key={item.name}>{item.value}</Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="企业地址"
                                        name="address"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入企业地址'}/>
                                    </Form.Item>

                                    <Form.Item
                                        name="founded_date"
                                        label="成立日期"
                                        rules={time_zone}
                                        className={'not-extra create-time-item'}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            locale={locale}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="business_license_approval_date"
                                        label="营业执照核准日期"
                                        rules={time_zone}
                                        className={'not-extra create-time-item'}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            locale={locale}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="营业期限"
                                        name="business_license_validity"
                                        rules={pact}
                                        className={this.state.validityType === '非长期' ? 'not-extra cert-type-input' : 'not-extra'}
                                        validateFirst={true}

                                    >
                                        <Radio.Group onChange={this.checkValidity}>
                                            <Radio value={'非长期'}>非长期</Radio>
                                            <Radio value={'长期'}>长期</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    {
                                        this.state.validityType === '非长期' &&
                                        <Form.Item
                                            name="range_picker"
                                            label=""
                                            rules={time_zone}
                                            className={'not-extra date-time'}
                                        >
                                            <RangePicker locale={locale} format="YYYY-MM-DD"/>
                                        </Form.Item>
                                    }

                                    <Form.Item
                                        label="营业执照副本"
                                        name="business_license"
                                        rules={upload_file}
                                        className={'height-item upload-item'}
                                        extra={
                                            <div className="input-desc">
                                                <p className="desc-text">只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符</p>
                                            </div>}
                                        validateFirst={true}

                                    >
                                        <Upload
                                            defImg={imgLicense}
                                            onChange={this.onFileChange}
                                            fileKey={'business_license'}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="企业Logo"
                                        name="logo"
                                        rules={upload_file_not_required}
                                        className={'height-item upload-item'}
                                        extra={
                                            <div className="input-desc">
                                                <p className="desc-text">只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符</p>
                                            </div>}
                                        validateFirst={true}
                                    >
                                        <Upload
                                            onChange={this.onFileChange}
                                            fileKey={'logo'}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="经营范围"
                                        name={'business_scope'}
                                        rules={not_null}
                                        className={'not-extra desc-input'}
                                    >
                                        <Input.TextArea placeholder={'请输入经营范围'}/>
                                    </Form.Item>

                                </Form>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div className="desc-col">
                                    <p className="desc-title">注意事项</p>
                                    <div className="desc-box">
                                        <p className="item-text">
                                            1.注册企业账户只能办理企业事项，注册机构账号只能办理机构事项；若二者均是，需分别注册。
                                        </p>
                                        <p className="item-text">
                                            2.注册账号时，需要通过手机接收短信验证，请正确填写您的手机号码。
                                        </p>
                                        <p className="item-text">
                                            3.较多业务系统将使用填写的注册信息，请如实填写。
                                        </p>
                                        <p className="item-text">
                                            4.请牢记注册的登录账号和密码，切勿向他人泄漏。
                                        </p>
                                        <p className="item-text">
                                            5.如有问题，请联系我们，电话:<span className={'number-text'}>023-85233427</span>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}