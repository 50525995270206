import React from 'react';
import {Form, Input, Row, Col, Radio, DatePicker} from 'antd';
import './index.scss'
import Upload from '../../components/Upload'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {rules} from '../../lib/help/rules'
import moment from 'moment';
import certFront from '../../resource/images/public/img-cert-front.svg'
import certBack from '../../resource/images/public/img-cert-back.svg'

const {RangePicker} = DatePicker;
const {time_zone, id_card, pact, not_null, upload_file} = rules


export default class CorporateInfo extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            validityType: ''
        }
        if (props.onRef) {  //如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    onFinish(values) {
        if(values.legal_person_id_validity === '非长期'){
            values.legal_person_id_validity = values.range_picker[0].format('YYYY-MM-DD') + ' - ' + values.range_picker[1].format('YYYY-MM-DD')
        }else {
            values.legal_person_id_validity = 'PERMANENT';
        }
        values.legal_person_id_front = values.legal_person_id_front.url && values.legal_person_id_front.url;
        values.legal_person_id_back = values.legal_person_id_back.url && values.legal_person_id_back.url;
        delete values.range_picker;
        if(!values.legal_person_id_front) {
            delete values.legal_person_id_front
        }
        if(!values.legal_person_id_back) {
            delete values.legal_person_id_back
        }

        this.props.setData(values)
    };

    checkValidity = (type) => {
        this.setState({
            validityType: type.target.value
        })
    }
    onFileChange = (fileKey, file) => {
        this.formRef.current.setFieldsValue({[fileKey]: file});
        this.formRef.current.validateFields([fileKey]);
    }
    onValuesChange = () => {
        this.props.getInfoChange && this.props.getInfoChange()
    }
    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
        if(this.props.initialValues) {
            let tmpData = JSON.parse(JSON.stringify(this.props.initialValues));
            if(this.props.initialValues.legal_person_id_validity !== '长期') {
                tmpData.range_picker = [moment(tmpData.legal_person_id_validity.split(' - ')[0]), moment(tmpData.legal_person_id_validity.split(' - ')[1])];
                tmpData.legal_person_id_validity = '非长期'
                this.setState({
                    validityType: '非长期'
                })
            }
            this.formRef.current.setFieldsValue(tmpData);
        }
    }

    componentWillUnmount() {
    }

    render() {
        const {type} = this.props;

        return (
            <div className="corporate-info-container">
                <div className="corporate-info-content content">
                    <div className="form-content">
                        <Row gutter={[0, 0]} className='number-info-box'>
                            <Col className="gutter-row left-from" span={16}>
                                <Form
                                    name="basic"
                                    ref={this.formRef}
                                    onValuesChange={this.onValuesChange}
                                    onFinish={(values) => this.onFinish(values)}
                                    onFinishFailed={(values) => this.onFinishFailed(values)}>

                                    <Form.Item
                                        label="法定代表人姓名"
                                        name="legal_person_name"
                                        rules={not_null}
                                        className={'not-extra input-phone'}

                                    >
                                        <Input size="middle" placeholder={'请输入法定代表人姓名'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="法定代表人家庭住址"
                                        name="legal_person_address"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入法定代表人家庭住址'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="法定代表人身份证号"
                                        name="legal_person_id_number"
                                        rules={id_card}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入法定代表人身份证号'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="身份证有效期"
                                        name="legal_person_id_validity"
                                        rules={pact}
                                        className={this.state.validityType === '非长期' ? 'not-extra cert-type-input' : 'not-extra'}
                                    >
                                        <Radio.Group onChange={this.checkValidity}>
                                            <Radio value={'非长期'}>非长期</Radio>
                                            <Radio value={'长期'}>长期</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {
                                        this.state.validityType === '非长期' &&
                                        <Form.Item
                                            name="range_picker"
                                            label=""
                                            rules={time_zone}
                                            className={'not-extra date-time'}
                                        >
                                            <RangePicker locale={locale} format="YYYY-MM-DD"/>
                                        </Form.Item>
                                    }

                                    <div className="cert-upload-box">
                                        <Form.Item
                                            label="法定代表人身份证"
                                            name="legal_person_id_front"
                                            rules={upload_file}
                                            className={'not-extra upload-item id-card-upload'}
                                            extra={
                                                <div className="input-desc">
                                                    <p className="desc-title">身份证人像面</p>
                                                    <p className="desc-text">只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符</p>
                                                </div>}
                                            validateFirst={true}

                                        >
                                            <Upload
                                                defImg={certFront}
                                                onChange={this.onFileChange}
                                                fileKey={'legal_person_id_front'}
                                                imgPath={this.props.initialValues ? this.props.initialValues.legal_person_id_front : undefined}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name="legal_person_id_back"
                                            rules={upload_file}
                                            className={'not-extra input-phone upload-item id-card-upload'}
                                            extra={<div className="input-desc">
                                                <p className="desc-title">身份证国徽面</p>
                                                <p className="desc-text">只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符</p>
                                            </div>}
                                            validateFirst={true}

                                        >
                                            <Upload
                                                defImg={certBack}
                                                onChange={this.onFileChange}
                                                fileKey={'legal_person_id_back'}
                                                imgPath={this.props.initialValues ? this.props.initialValues.legal_person_id_back : undefined}
                                            />
                                        </Form.Item>
                                    </div>
                                </Form>
                            </Col>
                            {
                                !(type === 'edit') &&
                                <Col className="gutter-row" span={8}>
                                    <div className="desc-col">
                                        <p className="desc-title">注意事项</p>
                                        <div className="desc-box">
                                            <p className="item-text">
                                                1.注册企业账户只能办理企业事项，注册机构账号只能办理机构事项；若二者均是，需分别注册。
                                            </p>
                                            <p className="item-text">
                                                2.注册账号时，需要通过手机接收短信验证，请正确填写您的手机号码。
                                            </p>
                                            <p className="item-text">
                                                3.较多业务系统将使用填写的注册信息，请如实填写。
                                            </p>
                                            <p className="item-text">
                                                4.请牢记注册的登录账号和密码，切勿向他人泄漏。
                                            </p>
                                            <p className="item-text">
                                                5.如有问题，请联系我们，电话:<span className={'number-text'}>023-85233427</span>
                                            </p>
                                        </div>
                                    </div>
                                </Col>

                            }
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}