import React from 'react';
import './index.scss';
import {rules} from '../../../../../lib/help/rules'
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  DatePicker,
  Cascader,
  message,
  Breadcrumb
} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import http from "../../../../../lib/service/http";
import {Link} from "react-router-dom";
const {time_zone,product_name,
  product_num,product_rate,pactSecured,product_num_max,product_rate_max,
  product_date,product_date_max,product_other, product_contact,is_select, product_other_un_require} = rules;
const {RangePicker} = DatePicker;
const { TextArea } = Input;
export default class AddDebt extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      validityType: '',
      validityArea: 1,
      options: [],
      optionsArea: [],
      areaId: '',
      confirmPriceMax: [
        ...product_num_max,
        {
          validator: this.handlePriceMax,
          trigger: 'onBlur'
        }
      ],
      confirmPriceMin: [
        ...product_num,
        {
          validator: this.handlePriceMin,
          trigger: 'onBlur'
        }
      ],
      confirmRateMax: [
        ...product_rate_max,
        {
          validator: this.handleRateMax,
          trigger: 'onBlur'
        },
      ],
      confirmRateMin: [
        ...product_rate,
        {
          validator: this.handleRateMin,
          trigger: 'onBlur'
        }
      ],
      confirmDateMax: [
        ...product_date_max,
        {
          validator: this.handleDateMax,
          trigger: 'onBlur'
        }
      ],
      confirmDateMin: [
        ...product_date,
        {
          validator: this.handleDateMin,
          trigger: 'onBlur'
        }
      ]
    }
  }
  handlePriceMin =(_, value, callback) =>{
    let productMin = Number(this.formRef.current.getFieldValue('amount_floor'));
    let productMax = Number(this.formRef.current.getFieldValue('amount_ceil'));
    if (productMax>0&&productMax <= Number(value)) {
      callback(new Error('请输入小于最大额度的数字'))
    } else if (Number(productMax)>0&&productMax > productMin) {
      this.formRef.current.setFieldsValue({amount_ceil: productMax});
      callback()
    } else {
      callback()
    }
  };
  handlePriceMax =(_, value, callback) =>{
    let productMin = Number(this.formRef.current.getFieldValue('amount_floor'));
    let productMax = Number(this.formRef.current.getFieldValue('amount_ceil'));
    if (Number(value) > 0 &&productMin >= Number(value)) {
      callback(new Error('请输入大于最小额度的数字'))
    } else if (Number(productMin)>0&&productMax > productMin) {
      this.formRef.current.setFieldsValue({amount_floor:productMin});
      callback()
    }else {
      callback()
    }
  };
  handleRateMax =(_, value, callback) =>{
    let RateMin = Number(this.formRef.current.getFieldValue('rate_floor'));
    let RateMax = Number(this.formRef.current.getFieldValue('rate_ceil'));
    if (RateMax>0 && RateMin >= RateMax) {
      callback(new Error('请输入大于最小利率的数字'))
    } else if (Number(value) > 0&&!(Number(value) <100)) {
      callback(new Error('请输入小于100的数字'))
    } else if (Number(RateMin)>0&&RateMax > RateMin) {
      this.formRef.current.setFieldsValue({rate_floor:RateMin});
      callback()
    } else {
      callback()
    }
  };
  handleRateMin =(_, value, callback) =>{
    let RateMax = Number(this.formRef.current.getFieldValue('rate_ceil'));
    let RateMin = Number(this.formRef.current.getFieldValue('rate_floor'));
    if (RateMax>0 && RateMax <= RateMin) {
      callback(new Error('请输入小于最大利率的数字'))
    } else if (!(value <100)) {
      callback(new Error('请输入小于100的数字'));
    } else if (Number(RateMax)>0&&RateMax > RateMin) {
      this.formRef.current.setFieldsValue({rate_ceil:RateMax});
      callback()
    }else {
      callback()
    }
  };
  handleDateMax =(_, value, callback) =>{
    let DateMin = Number(this.formRef.current.getFieldValue('cycle_floor'));
    let DateMax = Number(this.formRef.current.getFieldValue('cycle_ceil'));
    if ((DateMax)>0&&DateMin >= (DateMax)) {
      callback(new Error('请输入大于最小期限的数字'))
    } else if (Number(DateMax)>0&&DateMax > DateMin&&String(DateMax).length<=3&&String(DateMin).length<=3) {
      this.formRef.current.setFieldsValue({cycle_floor: DateMin});
      callback()
    } else {
      callback()
    }
  };
  handleDateMin =(_, value, callback) =>{
    let DateMin = Number(this.formRef.current.getFieldValue('cycle_floor'));
    let DateMax = Number(this.formRef.current.getFieldValue('cycle_ceil'));
    if (DateMax>0 && DateMax <= (DateMin)) {
      callback(new Error('请输入小于最大期限的数字'))
    } else if (Number(DateMin)>0&&DateMax > DateMin&&String(DateMin).length<=3&&String(DateMax).length<=3) {
      this.formRef.current.setFieldsValue({cycle_ceil: DateMax});
      callback()
    } else {
      callback()
    }
  };
  getArea = ()=> {
    http.post('/area-info', {}).then(res => {
      res.forEach(item => {
        item.isLeaf = false
      });
     this.setState({
       optionsArea:res
     });

    }, () => {
    })
  };
  getSecureMap = ()=> {
    http.get('/financial/debt-product/secure_map').then(res => {
      let obj = {};
      let array = [];
      Object.keys(res.data).forEach((key) => {
      obj.label = res.data[key];
        obj.value = key;
       return array.push({label:res.data[key],value:key})
      });
      this.setState({
        options: array
      });
    })
  };
  componentDidMount () {
    this.getArea();
    this.getSecureMap();
  };
  onFinish =(values) =>{
    if(this.state.validityType === '固定日期') {
      values.validity_start = values.date[0].format('YYYY-MM-DD');
      values.validity_end = values.date[1].format('YYYY-MM-DD');
      delete values.date
    }
    if(this.state.validityArea === 1) {
      values.area_id =values.area[values.area.length-1];
    }
    if (values.amount_ceil=== undefined ||Number(values.amount_ceil)=== 0) {
      delete values.amount_ceil
    } else {
      values.amount_ceil = String(values.amount_ceil);
    }
    if (values.cycle_ceil=== undefined ||Number(values.cycle_ceil)=== 0) {
      delete values.cycle_ceil
    } else {
      values.cycle_ceil = Number(values.cycle_ceil);
    }
    if (values.rate_ceil=== undefined ||Number(values.rate_ceil)=== 0) {
      delete values.rate_ceil
    } else {
      values.rate_ceil = String(values.rate_ceil);
    }
    values.cycle_floor = Number(values.cycle_floor);
    values.rate_floor = String(values.rate_floor);
    values.amount_floor = String(values.amount_floor);
    const that=this;
    http.post('/financial/debt-product/issue', values).then(res => {
      message.success({
        className: '',
        content: '新增成功',
        duration: 2,
        onCancel() {
              that.props.history.push({
                pathname:'/user-info/sub-debt-finance',
                state: '贷款融资'
              })
            },
      });
      this.props.history.push({
        pathname:'/user-info/sub-debt-finance',
        state: '贷款融资'
      })
    }, () => {
    })
  };
  handleCancel=()=>{
    this.props.history.push({
      pathname:'/user-info/sub-debt-finance',
      state: '贷款融资'
    })
  };
  onFinishFailed(errorInfo) {
  };
  onChange=() => {
  };
  onChangeTime=(e) => {
    this.setState({
      validityType: e.target.value
    })
  };
  onChangeArea=(e) => {
    this.setState({
      validityArea: e.target.value
    });
  };
  onSelectArea =(e,selectedOptions) => {
    if(selectedOptions.length>0) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      let ID = targetOption.ID;
      this.setState({
        areaId: ID
      });
      http.post('/area-info', {ID}).then(res => {
        targetOption.loading = false;
        if (e.length <= 2) {
          targetOption.children = res;
          if (e.length <= 1) {
            targetOption.children.forEach(item => {
              item.isLeaf = false
            });
          }
        }
        this.setState({
          optionsArea: [...this.state.optionsArea],
        });
      }, () => {
      })
    }
  };
  loadData=(selectedOptions)=> {
    this.setState({
      optionsArea: [...this.state.optionsArea],
    });
  };
  onChangeTimeRange=()=>{
  };
  render() {
    const {options,confirmPriceMax,optionsArea,confirmRateMax,confirmDateMax,confirmRateMin,confirmDateMin,confirmPriceMin} = this.state;
    return (
      <div className="firm-register-container add-debt-finance">
        <div className={'breadcrumb detail-breadcrumb'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link className={'link'} to={'/profile'}>个人中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link className={'link'} to={'/user-info/sub-debt-finance'}>贷款融资</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className={'link'}>新增贷款融资</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="firm-register-content detail-breadcrumb-margin">
          <div className={'title'}>新增贷款融资</div>
          <div className={'add-debt-content'}>
            <Form
              name="basic"
              ref={this.formRef}
              onFinish={(values) => this.onFinish(values)}
              initialValues={{
                area_id: 1,
              }}
              onFinishFailed={() => this.onFinishFailed()}>
              <Form.Item
                label="产品名称"
                name="name"
                rules={product_name}
                className={'item-height'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入产品名称'} maxLength={20} />
              </Form.Item>
             <div className={'price-range'}>
              <Form.Item
                label="贷款额度范围"
                name="amount_floor"
                rules={confirmPriceMin}
                extra={'输入非负金额，精度最大四位小数'}
                className={'input-price input-flex input-password'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入最小金额'} />
              </Form.Item>
               <div className={'unit-text'}>万元</div>
              <span className={'to-text'}>至</span>
              <Form.Item
                name="amount_ceil"
                rules={confirmPriceMax}
                className={'input-price input-flex'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入最大金额'}/>
              </Form.Item>
               <div className={'unit-text'}>万元</div>
             </div>
              <div className={'price-range'}>
              <Form.Item
                label="贷款利率范围"
                name="rate_floor"
                rules={confirmRateMin}
                extra={'输入非负利率，精度最大两位小数'}
                className={'input-rate input-flex input-password'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入最小利率'}/>
              </Form.Item>
                <div className={'unit-text'}>%</div>
              <span className={'to-text'}>至</span>
              <Form.Item
                name="rate_ceil"
                rules={confirmRateMax}
                className={'input-rate input-flex'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入最大利率'}/>
              </Form.Item>
                <div className={'unit-text'}>%</div>
              </div>
              <Form.Item
                rules={pactSecured}
                label="担保方式"
                name="secured"
                className={'item-height'}
              >
                <Checkbox.Group options={options}   onChange={this.onChange.bind(this)} />
              </Form.Item>
              <div className={'price-range'}  style={{
                height: '55px',
              }}>
              <Form.Item
                label="期限范围"
                rules={confirmDateMin}
                name="cycle_floor"
                className={'input-range input-flex'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入最小期限'} />
              </Form.Item>
                <div className={'unit-text'}/>
                <span className={'to-text'}>至</span>
              <Form.Item
                name="cycle_ceil"
                rules={confirmDateMax}
                className={'input-range input-flex'}
                validateFirst={true}
              >
                <Input size="middle" placeholder={'请输入最大期限'} />
              </Form.Item>
                <div className={'unit-text'}>月</div>
              </div>
              {/*<Form.Item*/}
              {/*  label="有效期"*/}
              {/*  name="validity"*/}
              {/*  rules={pact}*/}
              {/*  className={'input-time item-height'}*/}
              {/*  validateFirst={true}*/}
              {/*>*/}
              {/*  <Radio.Group onChange={this.onChangeTime.bind(this)}>*/}
              {/*    <Radio value={'固定日期'}>固定日期</Radio>*/}
              {/*    <Radio value={'PERMANENT'}>长期</Radio>*/}
              {/*  </Radio.Group>*/}
              {/*</Form.Item>*/}
              {
                this.state.validityType === '固定日期' &&
                <Form.Item
                  name="date"
                  label=""
                  rules={time_zone}
                  className={'not-extra date-time no-title'}
                >
                  <RangePicker locale={locale} format="YYYY-MM-DD" onchange={this.onChangeTimeRange}/>
                </Form.Item>

              }
              <Form.Item
                label="联系方式"
                rules={product_contact}
                name="contact"
                className={'contact-way item-extra input-password'}
                validateFirst={true}
                extra={'输入手机号或邮箱地址'}
              >
                <Input size="middle" placeholder={'请输入联系方式'}/>
              </Form.Item>
              <Form.Item
                label="适用地区"
                name="area_id"
                className={'input-area not-extra'}
                validateFirst={true}
              >
                <Radio.Group onChange={this.onChangeArea.bind(this)}>
                  <Radio value={0}>全部地区</Radio>
                  <Radio value={1}>指定地区</Radio>
                </Radio.Group>
              </Form.Item>
              {
                this.state.validityArea === 1  &&
                <Form.Item
                  name="area"
                  label=""
                  rules={is_select}
                  className={'not-extra date-time no-title'}
                >
                  <Cascader
                    fieldNames={{ label: 'Name', value: 'ID',children: 'children'}}
                    options={optionsArea}
                    onChange={this.onSelectArea}
                    placeholder={'请选择区域'}
                    loadData={this.loadData}
                    changeOnSelect
                  />
                </Form.Item>

              }
              <Form.Item
                label="产品特点"
                rules={product_other}
                name="description"
                className="not-extra input-remark line"
                validateFirst={true}
              >
                <TextArea size="middle" placeholder={'请输入产品特点'} />
              </Form.Item>
              <Form.Item
                label="申请条件"
                rules={product_other}
                name="requirement"
                className="not-extra input-remark"
                validateFirst={true}
              >
                <TextArea size="middle" placeholder={'请输入申请条件'}/>
              </Form.Item>
              <Form.Item
                label="所需材料"
                rules={product_other}
                name="data"
                className="not-extra input-remark"
                validateFirst={true}
              >
                <TextArea size="middle" placeholder={'请输入所需材料'} />
              </Form.Item>
              <Form.Item
                label="其他说明"
                name="remark"
                rules={product_other_un_require}
                className="not-extra input-remark"
                validateFirst={true}
              >
                <TextArea size="middle" placeholder={'请输入其他说明'}/>
              </Form.Item>
              <Form.Item className="footer-btn">
                <Button className={'submit-btn'} type="primary" htmlType="submit">
                  提交
                </Button>
                <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.handleCancel}>
                  取消
                </Button>
              </Form.Item>
            </Form>
          </div>
      </div>
      </div>
    )
  }
}
