import React from 'react';
import {getUserInfo} from "../../../../lib/help/public";
import './index.scss'
import {withRouter} from "react-router-dom";
import Company from "./CompanyDetail"
import Agency from "./AgencyDetail"
 class Detail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newsInfo: {},
      params: {},
      history: {},
      userInfo: {
        user: {}
      },
    }
  }


  getUserInfo() {
    let tmpInfo = sessionStorage.getItem('userInfo');
    if(tmpInfo) {
      tmpInfo = JSON.parse(tmpInfo);
      this.setState({
        userInfo: tmpInfo
      })
    }
  }

  componentDidMount() {
    getUserInfo();
    this.getUserInfo();
   this.setState({
    params: this.props.match.params,
     history: this.props.history
   })
  }
  componentWillUnmount() {
  }

  render() {
    return (
      <div className="transaction-index-container">
        {
          this.state.userInfo.user.UserType === '企业账户' &&
          <Company params={this.state.params} history={this.state.history}/>
        }
        {
          this.state.userInfo.user.UserType === '机构主账户' &&
          <Agency params={this.state.params} history={this.state.history} />

        }
        {
          this.state.userInfo.user.UserType === '机构子账户' &&
          <Agency params={this.state.params} history={this.state.history} />
        }

      </div>
    )
  }
}
export default withRouter(Detail)
