import React from 'react';
import {Breadcrumb, Modal} from "antd";
import {Link,withRouter} from "react-router-dom";
import xian from "../../../../resource/images/profile/xian.svg";
import progress from "../../../../resource/images/profile/progress.svg"
import line from "../../../../resource/images/profile/line.svg"
import lineActive from "../../../../resource/images/profile/line-active.svg"
import progressSecond from "../../../../resource/images/profile/progress-2.svg"
import progressThree from "../../../../resource/images/profile/progress-3.svg"
import progressFour from "../../../../resource/images/profile/progress-4.svg"
import progressFail from "../../../../resource/images/profile/progress-fail.svg"
import splitLine from "../../../../resource/images/profile/split-line.svg"
import { Row, Col} from 'antd';
import http from "../../../../lib/service/http";
import {localTime} from "../../../../lib/help/helper";
import xianRed from "../../../../resource/images/profile/xian-red.svg";
 class TransactionCompanyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '撮合成功',
      orderObject: {},
      productObject: {}
    }
  }
  getListData(params) {
    http.get(`/enterprise/product-order-detail/${params.id}`).then(res => {
      this.setState({
        orderObject: res.data.order_object,
        productObject: res.data.product_object,
        status: res.data.order_object.OrderStatus
      });
    });
  };
   getValueByKey=(key,type)=> {
     let array = [];
     const arrayPickerData = [];
     if (type&&type===1) {
       array = [
         {'信用':'CREDIT'},
         {'保证':'ENSURE'},
         {'抵押':'MORTGAGE'},
         {'质押':'PLEDGE'}
       ];
     } else {
       array = [
         {'CREDIT':'信用'},
         {'ENSURE':'保证'},
         {'MORTGAGE':'抵押'},
         {'PLEDGE':'质押'}
       ];
     }
     if (key!==undefined) {
       let keyArr = key.split(",");
       keyArr.forEach((k)=>{
         array.forEach((Item) => {
           if (Item[k]!==undefined) {
             arrayPickerData.push(Item[k]);
           }
         });
       });
       return arrayPickerData.join(',');
     }
   };
   goClose=(item)=> {
     const that = this;
     Modal.confirm({
       className: 'info-disable',
       width: '416px',
       title: (
         <div className={'disable-title'}>确定关闭该笔交易申请?</div>
       ),
       content:(
         <div className={'disable-text'}>关闭后不可更改</div>
       ),
       okText: '确定',
       cancelText: '取消',
       onCancel() {},
       onOk() {
         http.post('/enterprise/close-apply', {product_order_id:item.ID}).then(res => {
           if (Object.keys(res).length===0) {
             that.props.history.push({
               pathname:'/transaction',
               state: ''
             });
           }
         }, () => {
         })
       },
     });
   };
  componentDidMount () {
    let params = {
      id:this.props.params.id
    };
    this.getListData(params);
  }
  goDetails =(record)=> {
    this.props.history.push({
      pathname:'/corporate-detail/'+record.ID,
      state: '产品详情'
    });
  };
  render() {
    const {status,orderObject,productObject} = this.state;
    return (
      <div className="firm-register-container transaction">
        <div className={'breadcrumb detail-breadcrumb'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link className={'link'} to={'/profile'}>个人中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link className={'link'} to={'/transaction'}>我的交易</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className={'link'}>交易详情</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="firm-register-content transaction-index-container">
          <div className={'title-detail'}>交易详情</div>
          <div className={'transaction-detail'}>
            <Row>
              <Col span={8}>
                <div className={'applicant applicant-enterprise'}><div>申请企业</div> <div className={'apply-title apply-title-width'}>{orderObject.EnterpriseName}</div></div>
                <div className={'applicant'}>申请时间 <span className={'apply-title'}> {localTime(orderObject.CreatedAt)}</span></div>
                {
                  orderObject.Memo?<div className={'applicant-enterprise-memo'}><div>备注</div> <div className={'apply-memo'}>{orderObject.Memo}</div></div>:''
                }
              </Col>
             <Col span={8}>
                <div className={'applicant applicant-enterprise'}><div>产品名称</div> <div className={'apply-title apply-title-width'}>{orderObject.ProductName} </div></div>
               {
                 orderObject.IntentAmount?<div className={'applicant applicant-enterprise'}><div>{orderObject.ProductType === 'SERVICE'?'预算金额':'申请金额'}</div> <div className={'apply-title apply-title-width'}>{orderObject.IntentAmount} 万元</div></div>:''
               }
              </Col>
              <Col span={8} className={'product-status-info'}>
                {status === 'PENDING-DISTRIBUTE'? (
                  <div>
                    <div className={'close-btn'}  onClick={this.goClose.bind(this, orderObject)}>关闭交易</div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>待分配</div>
                    <div className={'transaction-validity'}>
                      {orderObject.RemainDays&&Number(orderObject.RemainDays)>7?
                        <div>
                          <span className={'apply-title'}><img src={xian} alt=""/>有效期：{orderObject.RemainDays}天</span>
                        </div>: <div>
                          <span className={'apply-title'}><img src={xianRed} alt=""/>有效期：{orderObject.RemainDays}天</span>
                        </div>}
                    </div>
                  </div>
                ):(status === 'PENDING-MATCH'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>待撮合</div>
                  </div>
                ):(status === 'MATCH-SUCCESS'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>撮合成功</div>
                  </div>
                ):(status === 'MATCH-FAILED'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>撮合失败</div>
                  </div>
                ):(status === 'CLOSED'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>已关闭</div>
                  </div>
                ):(status === 'EXPIRED'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>已失效</div>
                  </div>
                ):(<div/>))))))}
              </Col>
            </Row>
          </div>
          <div className={'split-line'}>
            <img src={splitLine} alt=""/>
          </div>
          <div className={'title-detail'}>交易进度</div>
          <div className={'transaction-progress'}>
            <div className={'step'}>
              <div className={'progress-img'}>
                <img src={progress} alt="" className={'circle'}/>
                {status!== 'PENDING-DISTRIBUTE' && status!== 'CLOSED'&& status!== 'EXPIRED'?( <img src={lineActive} alt="" className={'line'}/>):(<img src={line} alt="" className={'line'}/>)}
              </div>
              <div className={'step-title'}>提交申请</div>
              <div className={'step-time'}>{localTime(orderObject.CreatedAt)}</div>
            </div>
            {(status=== 'CLOSED' || status === 'EXPIRED')?
              (
                <div>
                  <div className={'step'}>
                    <div className={'progress-img'}>
                      <img src={progressFail} alt="" className={'circle'}/>
                    </div>
                    <div className={'step-title-close'}> {status=== 'CLOSED'?"交易关闭":"交易失效"}</div>
                    <div className={'step-time'}>{status=== 'CLOSED'?(localTime(orderObject.ClosedAt)):(localTime(orderObject.ApplyExpiredAt))}</div>
                  </div>
                </div>
              )
             :(
                <div style={{display:"flex"}}>
                <div className={'step'}>
              <div className={'progress-img'}>
            {status !== 'PENDING-DISTRIBUTE'?( <img src={progress} alt="" className={'circle'}/>):(<img src={progressSecond} alt=""/>)}
            {status=== 'MATCH-SUCCESS'|| status=== 'MATCH-FAILED'?( <img src={lineActive} alt="" className={'line'}/>):(<img src={line} alt="" className={'line'}/>)}
              </div>
              <div className={'step-title'}>机构分配业务员</div>
              {status !== 'PENDING-DISTRIBUTE'&& <div className={'step-time'}>{localTime(orderObject.DistributedAt)}</div>}
              </div>
              <div className={'step'}>
              <div className={'progress-img'}>
              {status=== 'MATCH-FAILED'|| status=== 'MATCH-SUCCESS'?( <img src={progress} alt="" className={'circle'}/>):(<img src={progressThree} alt=""/>)}
              {status=== 'MATCH-SUCCESS'?( <img src={lineActive} alt="" className={'line'}/>):(<img src={line} alt="" className={'line'}/>)}
              </div>
              <div className={'step-title'}>确认交易信息</div>
              <div className={'step-time'}>{(status=== 'MATCH-FAILED'|| status=== 'MATCH-SUCCESS')&&localTime(orderObject.MatchedAt)}</div>
              </div>
              <div className={'step'}>
              <div className={'progress-img'}>
              {status=== 'MATCH-FAILED'?( <img src={progressFail} alt="" className={'circle'}/>):(status=== 'MATCH-SUCCESS'?( <img src={progress} alt="" className={'circle'}/>):(<img src={progressFour} alt=""/>))}
              </div>
              <div className={'step-title'}> {status=== 'MATCH-FAILED'?"撮合失败":"撮合成功"}</div>
              <div className={'step-time'}>{(status=== 'MATCH-FAILED'|| status=== 'MATCH-SUCCESS')&&localTime(orderObject.MatchedAt)}</div>
              </div>
              </div>
            )}
          </div>
          <div className={'split-line'}>
            <img src={splitLine} alt=""/>
          </div>
          <div className={'title-detail'}>交易结果</div>
          <div className={'applicant applicant-enterprise'}>发布机构 <span className={'apply-title apply-title-width'}>{productObject.FinancialName}</span></div>
          <div className={'applicant applicant-enterprise applicant-result'}>交易结果
            {
              orderObject.ProductType === 'SERVICE'?(
                <div className={'result-title'}>
                  <div className={'price price-title'}>价格</div>
                  {orderObject.Amount&& <div className={'price price-num'}>{orderObject.Amount}万元</div>}
                </div>
              ):(
                <div className={'result-title'}>
                  <Row >
                    <Col span={5} className={'price price-title'}>利率</Col>
                    <Col span={6} className={'price price-title'}>金额</Col>
                    <Col span={6} className={'price price-title'}>期限</Col>
                    <Col span={7} className={'price price-title'}>担保方式</Col>
                  </Row>
                    {orderObject.Amount&& <Row className={'list-item'}>
                      <Col span={5} className={'price price-num'}>
                        {orderObject.InterestRate}%
                      </Col>
                      <Col span={6} className={'price price-num'}>
                        {orderObject.Amount}万元
                      </Col>
                      <Col span={6} className={'price price-num'}>
                        {orderObject.MonthLimit}个月
                      </Col>
                      <Col span={7} className={'price price-num'}>
                        {this.getValueByKey(orderObject.OrderSecured)}
                      </Col>
                    </Row>}
                </div>
              )
            }
          </div>
          <div className={'split-line'}>
            <img src={splitLine} alt=""/>
          </div>
          <div className={'detail-bottom'}>
            <div className={'title-detail'}>交易产品</div>
            <div className={'go-detail'}>
              { orderObject.ProductType === 'SERVICE'?  <Link to={'/corporate-detail/'+ productObject.ID} className={'text-link'}>查看详情</Link>:
                <Link to={'/bond-detail/'+ productObject.ID} className={'text-link'}>查看详情</Link>}
            </div>
          </div>
          {
            orderObject.ProductType === 'SERVICE'? <div>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      产品名称
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.Name}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      服务类型
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{productObject.ProductTypeStr}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      发布机构
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.FinancialName}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      价格范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{orderObject.AmountFloor + "-"+  orderObject.AmountCeil}万元</Col>
                  </Row>
                </Col>
              </Row>
            </div>: <div>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      产品名称
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.Name}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      利率范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>
                      {productObject.RateCeil? productObject.RateFloor+"%" +"-"+productObject.RateCeil+"%":productObject.RateFloor+"%以上"}
                    </Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      期限范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{productObject.CycleCeil? productObject.CycleFloor +"-"+productObject.CycleCeil+"个月":productObject.CycleFloor+"个月以上"}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      发布机构
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.FinancialName}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      金额范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{productObject.AmountCeil? productObject.AmountFloor +"-"+productObject.AmountCeil+"万元":productObject.AmountFloor+"万元以上"}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      担保方式
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{this.getValueByKey(productObject.Secured)}</Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
        </div>
      </div>
    )
  }
}
export default withRouter(TransactionCompanyDetail)
