import React from 'react';
import './index.scss';
import {Tabs} from 'antd';
import BondFinancing from './BondFinancing'
import CorporateServices from './CorporateServices'
import banner from "../../resource/images/public/product-banner.jpg"

const {TabPane} = Tabs;
export default class ProductSupermarket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
        }
    }
    callback=(key)=> {
        this.setState({
            activeTab: key
        })
    }

    componentWillMount() {
        const tab = this.props.location.search;
        if (tab.indexOf('tab') > -1) {
            this.setState({activeTab: tab.substr(-1, 1)})
        }
    }
    render() {
        return (
            <div className="firm-register-container product-supermarket-container">
                <div className={'product-banner'}>
                    <img src={banner} alt=""/>
                </div>
                <div className="firm-register-content content">
                    <Tabs defaultActiveKey={`${this.state.activeTab}`} className={'tab-box'} tabBarGutter={48}  onChange={this.callback}>
                        <TabPane tab={
                            <p className="tab-item tab-item-1">贷款融资</p>
                        } key="1">
                            {this.state.activeTab === '1' && <BondFinancing activeKey={this.state.activeKey}/>}
                        </TabPane>
                        <TabPane tab={
                           <p className="tab-item tab-item-2">企业服务</p>
                        } key="2">
                            <CorporateServices activeKey={this.state.activeTab}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}
