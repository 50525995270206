import React from 'react';
import {Table, Space, Form, Input, Select, Pagination, Breadcrumb, Popconfirm, message} from "antd/lib/index";
import {Link, withRouter} from "react-router-dom";
import './AddPages/index.scss';
import http from "../../../../lib/service/http";
import {localTime} from "../../../../lib/help/helper";
import NoData from "../../../NoData";
const { Option } = Select;

class CompanyList extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleRole: false,
      name: '',
      status: '',
      total: '',
      currentPage:1,
      data: [],
      statusData: [
        {
          name: '待审核',
          key: 'PENDING-REVIEW',
          value:'PENDING-REVIEW'
        },
      {
        name: '审核通过',
        key: 'REVIEW-PASS',
        value:'REVIEW-PASS'
      },
      {
        name: '审核驳回',
        key: 'REVIEW-REJECTED',
        value:'REVIEW-REJECTED'
      },
      {
        name: '已发布',
        key: 'PUBLISHED',
        value:'PUBLISHED'
      },
      {
        name: '发布驳回',
        key: 'PUBLISH-REJECTED',
        value:'PUBLISH-REJECTED'
      },
        {
          name: '已下架',
          key: 'SHELVED',
          value:'SHELVED'
        }

      ],
      columns: [
        {
          title: '编号',
          render:(text,record,index)=>`${index+1}`,
        },
        {
          title: '产品名称',
          dataIndex: 'Name',
          key: 'Name',
        },
        {
          title: '类型',
          dataIndex: 'ProductTypeStr',
          key: 'ProductTypeStr',
        },
        {
          title: '创建时间',
          key: 'CreatedAt',
          render: (row) => {
            return (localTime(row.CreatedAt))
          }
        },
        {
          title: '状态',
          key: 'Status',
          dataIndex: 'Status',
          render: (val) => {
            switch (val) {
              case 'PENDING-REVIEW':
                return <div className={'product-status'}><p className={'red-black'}/>待审核</div>;
              case 'REVIEW-PASS':
                return <div className={'product-status'}><p className={'yellow'}/>审核通过</div>;
              case 'REVIEW-REJECTED':
                return <div className={'product-status'}><p className={'red'}/>审核驳回</div>;
              case 'PUBLISHED':
                return <div className={'product-status'}><p className={'green'}/>已发布</div>;
              case 'PUBLISH-REJECTED':
                return <div className={'product-status'}><p className={'gray'}/>发布驳回</div>;
              case 'SHELVED':
                return <div className={'product-status'}><p className={'gray'}/>已下架</div>;
              default: return <div className={'product-status'} >{val}</div>;
            }
          }
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <Space size="middle" className={'operate-btn'}>
              <a onClick={this.goDetails.bind(this, record)}>查看详情</a>
              {(record.Status === 'REVIEW-REJECTED' || record.Status === 'PUBLISH-REJECTED') &&
              <Popconfirm
                  title="删除后的产品不可找回，确定是否删除？"
                  onConfirm={this.delRow.bind(this, record)}
                  okText="确定"
                  cancelText="取消"
              >
                <a>删除</a>
              </Popconfirm>
              }
            </Space>
          ),
        },
      ]
    }
  }
  goDetails =(record) => {
    this.props.history.push({
      pathname:'/user-info/sub-company-services/sub-company-detail/'+record.ID,
      state: '产品详情'
    });
  };
  delRow = (record) => {
    http.post(`/financial/enterprise-service/delete/${record.ID}`).then(() => {
      let params = {
        page:1
      };
      this.setState({currentPage: 1});
      this.getDataList(params);
      message.success('删除成功');
    })
  };
  getDataList=(params)=> {
    let tmp= {
      query_status:params.status,
      query_name:params.name,
      page:params.page
    };
    http.get('/financial/enterprise-service/list',tmp).then(res => {
      if (res) {
        this.setState({
          data: res.items,
          total: res.total
        })
      }
    })
  };
  componentDidMount() {
    let params = {
      status: '',
      name: '',
      page: 1
    };
    this.getDataList(params)
  }
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params= {
      status: this.formRef.current.getFieldValue('status') || '',
      name: this.formRef.current.getFieldValue('name')  ||  '',
      page: page
    };
    this.getDataList(params)
  };
  search = () => {
    let params= {
      status: this.formRef.current.getFieldValue('status') || '',
      name: this.formRef.current.getFieldValue('name')  ||  '',
      page: ''
    };
    this.getDataList(params);
    this.setState({
      currentPage: 1,
    });
  };
  reset = () => {
    this.formRef.current.setFieldsValue({status: ''});
    this.formRef.current.setFieldsValue({name: ''});
    this.setState({
      currentPage: 1,
    });
    let params= {
      status: '',
      name: '',
      page: 1
    };
    this.getDataList(params)
  };
  add = () => {
    this.props.history.push({
      pathname:'/user-info/sub-company-service/sub-add-company',
        state: '新增企业服务'
    });
  };
  onChangeName = (value) => {
    this.setState({
      name: value.target.value
    })
  };
  onChangeStatus = (e) => {
    this.setState({
      status: e
    })
  };
  render() {
    const {data,total } = this.state;
    return (
      <div className="firm-register-container debt-finance">
        <div className={'breadcrumb detail-breadcrumb'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link className={'link'} to={'/profile'}>个人中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className={'link'} >企业服务</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="firm-register-content debt-content detail-breadcrumb-margin">
          <div className={'title'}>企业服务</div>
          <div className="user-manage">
            <div className="user-content">
              <div className={'search-box'}>
                <Form
                  className="search-form"
                  name="basic"
                  ref={this.formRef}
                  layout={'inline'}
                >
                  <div style={{display:"flex"}}>
                  <span className={'search-title'}>产品名称:</span>
                  <Form.Item className="" name='name'>
                    <Input
                      placeholder={'请输入'}
                      className={'search-input'}
                      onChange={this.onChangeName.bind(this)}
                    />
                  </Form.Item>
                  <span className={'search-title'}>状态:</span>
                  <Form.Item className="" name='status'>
                    <Select placeholder="请选择" className={'search-select'}  onChange={(e) =>this.onChangeStatus(e)}>
                      {
                        this.state.statusData.map((item) => {
                          return <Option value={item.value} key={item.name}>{item.name}</Option>
                        })
                      }

                    </Select>
                  </Form.Item>
                  </div>
                </Form>
                <div className="btn" style={{display:"flex"}}>
                  <span className="detail-btn" onClick={() => this.search()}>查询</span>
                  <span className="detail-btn reset-btn" onClick={() => this.reset()}>重置</span>
                  <span className="detail-btn add-btn" onClick={() => this.add()}>新增</span>
                </div>
              </div>
              {total !==0? (
                <div className={'table-data'}>
                  <Table columns={this.state.columns} dataSource={data} rowKey='ID'pagination={false}/>
                  <div className={'page-total-data'}>
                    <div >
                      共计 <span className={'total-num'}>{total}</span> 条数据
                    </div>
                    <Pagination
                      className={'pageStyle'}
                      onChange={this.onChangePage.bind(this)}
                      defaultCurrent={1}
                      total={total}
                      pageSize={10}
                      showSizeChanger={false}
                      current={this.state.currentPage}
                    />
                  </div>
                </div>
              ): (<div className="news-list-content">
                <NoData />
              </div>)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(CompanyList)
