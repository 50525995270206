import React from 'react';
import {Row, Col, Pagination, Modal} from 'antd';
import xian from '../../../../resource/images/profile/xian.svg'
import NoData from "../../../NoData";
import http from "../../../../lib/service/http";
import {getImgPublicUrl, localTime} from "../../../../lib/help/helper";
import {withRouter} from "react-router-dom";
import xianRed from "../../../../resource/images/profile/xian-red.svg";
import  imgQiye from "../../../../resource/images/public/img_qiyefuwu.svg"
import jigouLogo from "../../../../resource/images/public/jigou_logo.svg"
class CompanyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      currentPage: 1,
      total: 0,
      data: [],
      statusList: ['PENDING-DISTRIBUTE','PENDING-MATCH','MATCH-SUCCESS','MATCH-FAILED','EXPIRED','CLOSED']
    }
  }
  goDetails=(item)=> {
    this.props.history.push({
      pathname:'/transaction-detail/'+item.ID,
      state: '产品详情'
    });
  };
  getList = (key,params) => {
    let tmp ={
      query_status:key,
      page:params.page
    };
    http.get('/enterprise/product-order-list',tmp).then(res => {
      if (res) {
        if(this.props.type === 'index') {
          this.setState({
            data: res.data.items ? res.data.items.slice(0, 3) : [],
            total: res.data.total
          })
        }else {
          this.setState({
            data: res.data.items,
            total: res.data.total
          })
        }
      }
    })
  };
  componentDidUpdate(prevProps,prevState){
    const  key = this.state.statusList[this.props.activeKey];
    let params = {
      page:this.state.currentPage
    };
    if(prevProps.activeKey !== this.props.activeKey) {
      this.getList(key,params);
      this.setState({
        currentPage: 1
      })
    }
  }
  componentDidMount () {
    const  key = this.state.statusList[this.props.activeKey];
    let params = {
      page:1
    };
    this.getList(key,params)
  }
  goClose=(item)=> {
    const that = this;
    Modal.confirm({
      className: 'info-disable',
      width: '416px',
      title: (
        <div className={'disable-title'}>确定关闭该笔交易申请?</div>
      ),
      content:(
        <div className={'disable-text'}>关闭后不可更改</div>
      ),
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
      onOk() {
        http.post('/enterprise/close-apply', {product_order_id:item.ID}).then(res => {
          if (Object.keys(res).length===0) {
            let params = {
              page:''
            };
            that.getList(item.OrderStatus,params)
          }
        }, () => {
        })
      },
    });
  };
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    const  key = this.state.statusList[this.props.activeKey];
    let params = {
      page:page
    };
    this.getList(key,params)
  };
  render() {
    const {total,data} =  this.state;
    return (
      <div className="transaction-list">

        {total !==0? (
          <div>
          <Row className={'transaction-header-content'}>
            <Col span={9} className={'text'}>申请产品</Col>
            <Col span={6} className={'text'}>机构名称</Col>
            <Col span={6} className={'text'}>金额范围|价格范围</Col>
            <Col span={3} className={'text'}>操作</Col>
          </Row>
          <div className={'table-data'}>
            {
              data.map((item) => {
                return   <div className={'list-content'} key={item.ID}>
                  <div className={'list-header'}>
                    <div className={'list-header-title transaction-num' }>交易编号：<span className={'text'}>{item.ID}</span></div>
                    <div className={'list-header-title transaction-time'}>申请时间：<span className={'text'}>{localTime(item.CreatedAt)}</span></div>
                    <div className={'transaction-validity'}>
                      {item.OrderStatus ==='PENDING-DISTRIBUTE'&&
                      <div className={'text-type remain-days'}>
                        {item.RemainDays&&Number(item.RemainDays)>7?
                          <div>
                            <img src={xian} alt=""/>有效期：<span className={'text'}>{item.RemainDays?item.RemainDays+'天':'长期'}</span>
                          </div>: <div>
                            <img src={xianRed} alt=""/>有效期：<span className={'text'}>{item.RemainDays+'天'}</span>
                          </div>}
                      </div>}
                    </div>
                  </div>
                  <Row className={'list-detail'}>
                    <Col span={7} className={'product-name'}>
                      <img src={item.ProductType==='TRADING'?item.FinancialLogo ?getImgPublicUrl(item.FinancialLogo):jigouLogo:item.ServiceImageFid?getImgPublicUrl(item.ServiceImageFid):imgQiye} alt="" /><div>
                      <div className={'name'}>{item.ProductName}</div>
                      <div className={'type'}>类型：{item.ProductType==='TRADING'?'贷款融资':'企业服务'} </div>
                    </div>
                    </Col>
                    <Col span={8} className={'company-name'}>
                      <div>{item.FinancialName}</div>
                    </Col>
                    <Col span={5} className={'price-range'}>
                      <div>{item.AmountCeil? item.AmountFloor +"-"+item.AmountCeil+"万元":item.AmountFloor+"万元以上"}</div>
                    </Col>
                    <Col span={4} className={'btn-operate'}>
                      <div className={'btn btn-detail'} onClick={this.goDetails.bind(this, item)}>查看详情</div>
                      {
                        item.OrderStatus==='PENDING-DISTRIBUTE'&&  <div className={'btn btn-close'} onClick={this.goClose.bind(this, item)}>关闭交易</div>
                      }
                      {
                        (this.props.type === 'index' && this.props.activeKey === '0') &&
                        <p className="list-status-btn">待分配</p>
                      }
                    </Col>
                  </Row>
                </div>
              })
            }
            <div className={'page-total-data'}>
              <div >
                共计 <span className={'total-num'}>{total}</span> 条数据
              </div>
              <Pagination
                className={'pageStyle'}
                onChange={this.onChangePage.bind(this)}
                defaultCurrent={1}
                total={total}
                pageSize={10}
                showSizeChanger={false}
                current={this.state.currentPage}
              />
            </div>
          </div>
          </div>
        ): (<div className="news-list-content">
          <NoData />
        </div>)}
      </div>
    )
  }
}
export default withRouter(CompanyList)
