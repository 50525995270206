import React from 'react';
import './index.scss'
import imgNoData from '../../resource/images/public/img-no-data.svg'
export default class NoData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="no-data-container">
                <img src={imgNoData} alt=""/>
            </div>
        )
    }
}