import React from 'react';
import {Row, Col} from 'antd';
import http from "../../../../lib/service/http";
import {DateToLocalTime} from "../../../../lib/help/helper"
import {Link, withRouter} from "react-router-dom";
import Company from "../../Transaction/Company/CompanyList"
import Message from "../../../MyMessage"
import iconHot from '../../../../resource/images/public/icon-hot.svg'
import {setConfirmLink} from "../../../../lib/help/public";

class CompanyIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            messageTotal: 0,
            countInfo: {},
        }
    }


    getInfoData() {
        http.get('/enterprise/hot-product-list').then(res => {
            res.items[0].RateCeil = '';
            this.setState({
                listData: res.items ? res.items : []
            });
        })
    }

    getList = () => {
        let tmp = {
            query_status: 'PENDING-DISTRIBUTE',
            page:1
        };
        http.get('/enterprise/product-order-list',tmp).then(res => {
            if (res) {
                this.setState({
                    total: res.data.total
                })
            }
        })
    };

    getMessageList(page = 1) {
        http.get('user/message-list', {page: page}).then(res => {
            this.setState({
                messageTotal: res.data.total,
            });
        });
    }
    getCountData() {
        http.get('/enterprise/overview').then(res => {
            this.setState({
                countInfo: res
            });
        })
    }

    componentDidMount() {
        this.getInfoData();
        this.getList();
        this.getMessageList();
        this.getCountData();
    }

    componentWillUnmount() {
    }

    render() {
        const {total, messageTotal, countInfo} = this.state;
        return (
            <div className="company-info-container">
                <div className="account-info">
                    <div className="title-box">
                        <p className="title">账户概览</p>
                        <p className="update-time">数据更新于 {DateToLocalTime(new Date()).replace(/-/g, '/')}</p>
                    </div>
                    <div className="account-list">
                        <div className="account-item">
                            <p className="item-title">成功申请贷款融资(次)</p>
                            <p className="item-number">{countInfo.MatchTradingCount}</p>
                            <div className="item-current">
                                <span className="item-text">累计申请</span>
                                <p className="text-number">{countInfo.TradingCount} <span>次</span></p>
                            </div>
                        </div>
                        <div className="account-item">
                            <p className="item-title">成功申请企业服务(次)</p>
                            <p className="item-number">{countInfo.MatchServiceCount}</p>
                            <div className="item-current">
                                <span className="item-text">累计申请</span>
                                <p className="text-number">{countInfo.ServiceCount} <span>次</span></p>
                            </div>
                        </div>
                        <div className="line">
                        </div>
                        <div className="account-item">
                            <p className="item-title">累计申请金额(万元)</p>
                            <p className="item-number">{countInfo.ApplyAmountTotal}</p>
                            <div className="item-current">
                                <span className="item-text">昨日新增</span>
                                <p className="text-number">{countInfo.NewApplyAmountTotal}万元</p>
                            </div>
                        </div>
                        <div className="account-item">
                            <p className="item-title">累计达成交易额(万元)</p>
                            <p className="item-number">{countInfo.MatchAmountTotal}</p>
                            <div className="item-current">
                                <span className="item-text">昨日新增</span>
                                <p className="text-number">{countInfo.NewMatchAmountTotal}万元</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={total > 0 ? "recent-transaction recent-box has-list" : "recent-transaction recent-box"}>
                    <div className="title-box">
                        <p className="title">最近交易</p>
                        {
                            total > 0 ?
                                <Link className="show-all" to={'/transaction'}>查看全部</Link> :
                                <p className="no-data-text">暂无交易数据</p>
                        }
                    </div>
                    {
                        total > 0 &&
                        <div className="transaction">
                            <Company activeKey={'0'} type={'index'}/>
                        </div>
                    }
                </div>
                <div className={messageTotal > 0 ? "recent-transaction recent-box has-list" : "recent-transaction recent-box"}>
                    <div className="title-box">
                        <p className="title">最近消息</p>
                        {
                            messageTotal > 0 ?
                                <Link className="show-all" to={'/my-message'}>查看全部</Link> :
                                <p className="no-data-text">暂无消息数据</p>
                        }
                    </div>
                    {
                        messageTotal > 0 &&
                        <div className="transaction-list">
                            <Message type={'index'} />
                        </div>
                    }
                </div>
                <div
                    className={this.state.listData.length > 0 ? "recent-transaction recent-box has-list" : "recent-transaction recent-box"}>
                    <div className="title-box">
                        <p className="title">热门产品</p>
                        {
                            this.state.listData.length > 0 ?
                                <Link className="show-all" to={'/product-supermarket'}>查看全部</Link> :
                                <p className="no-data-text">暂无产品数据</p>
                        }
                    </div>
                    {
                        this.state.listData.length > 0 &&
                        <Row gutter={24} className="project-list">
                            {
                                this.state.listData.map((item) => {
                                    return (
                                        <Col span={8} key={item.ID}>
                                            <div className="project-item">
                                                <img src={iconHot} alt="" className="icon-hot"/>
                                                <p className="name">{item.Name}</p>
                                                {
                                                    item.RateCeil ?
                                                        <p className="interest-rate">{item.RateFloor}%-{item.RateCeil}%</p> :
                                                        <p className="interest-rate">{item.RateFloor}%起</p>
                                                }
                                                <p className="rate-title">贷款利率</p>
                                                <p className="max-text">
                                                    {
                                                        item.AmountCeil ?
                                                            <span>最高{item.AmountCeil}万元</span> :
                                                            <span>{item.AmountFloor}万元起</span>
                                                    }
                                                    {
                                                        item.CycleCeil ?
                                                            <span>最长{item.CycleCeil}个月</span> :
                                                            <span>{item.CycleFloor}个月起</span>
                                                    }
                                                </p>
                                                <p onClick={(event)=>setConfirmLink(event, this, item.ID, 'bond')} className="link-btn">立即申请</p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    }
                </div>
            </div>
        )
    }
}
export default withRouter(CompanyIndex)
