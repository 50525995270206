import React from 'react';
import './index.scss'
import {Badge, Pagination, Breadcrumb} from "antd";
import http from "../../lib/service/http";
import {Link} from "react-router-dom";
import moment from 'moment';
import imgNoData from '../../resource/images/public/img-no-data.svg'
import emitter from '../../lib/help/events';


export default class MyMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            currentPage: 1,
            total: 0,
            isAllRead: false
        }
    }

    componentDidMount() {
        this.getMessageList();
        this.checkUnread();
    }

    getMessageList(page = 1) {
        http.get('user/message-list', {page: page}).then(res => {
            if (Number(res.data.total) > 0) {
                if (this.props.type === 'index') {
                    this.setState({
                        list: res.data.items ? res.data.items.slice(0, 3) : [],
                        total: res.data.total,
                    });
                }else {
                    this.setState({
                        list: res.data.items,
                        total: res.data.total,
                    });
                }
            }
        });
    }

    checkUnread() {
        http.get('user/message-count').then(res => {
            this.setState({
                isAllRead: res.UnreadCount === 0
            });
        });
    }

    onChangePage = page => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getMessageList(this.state.currentPage)
        });
    };

    readMessage(isAll, id, unread) {
        if (isAll) {
            http.post('user/message-read', {}).then(() => {
                this.getMessageList();
                this.checkUnread();
                emitter.emit('changeMessage');
            });
        }
        if (!isAll && unread === 1) {
            http.post('user/message-read', {id: id}).then(() => {
                emitter.emit('changeMessage');
            });
        }
    }

    render() {
        return (
                <div className="users-breadcrumb my-message-container">
                    <div className={'breadcrumb detail-breadcrumb'}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link className={'link'} to={'/profile'}>个人中心</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a className={'link'}>我的消息</a>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="profile-title-box detail-breadcrumb-margin">
                        <p className="title">我的消息</p>
                    </div>
                    {
                        this.state.list.length > 0 && !this.state.isAllRead ? <div className={'all-read-text'}
                                                                                   onClick={this.readMessage.bind(this, true)}>全部标为已读</div> :
                                ""
                    }

                    <div className={'list-title-content'}>
                        <span>内容</span>
                        <span>操作</span>
                    </div>
                    {
                        this.state.list.length > 0 ?
                                this.state.list.map((item, index) => {
                                    return (
                                            <div className={`item-box ${Number(this.state.total) <= 10 ? 'one-page-end' : ''}`}
                                                 key={'item' + index}>
                                                <div className={'item-left'}>
                                                    {item.Unread === 1 ? <Badge status="error"/> :
                                                            <Badge color="#ffffff"/>}
                                                    <div>
                                                        <p className={`item-desc ${item.Unread === 0 ? 'time-text' : ''}`}>
                                                            {item.Content}
                                                        </p>
                                                        <p className={'time-text'}>{moment(item.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                                                    </div>
                                                </div>
                                                <Link className={'open-link'} to={item.Url}
                                                      onClick={this.readMessage.bind(this, false, item.ID, item.Unread)}>查看</Link>
                                            </div>
                                    )
                                }) :
                                <img src={imgNoData} alt="" className={'no-data-img'}/>
                    }

                    {
                        Number(this.state.total) > 10 ?
                                <div className={'pagination-box'}>
                                    <div>共计 <span style={{color: '#C38B3A'}}>{this.state.total}</span> 条数据</div>
                                    <Pagination
                                            className={'pagination-content'}
                                            onChange={this.onChangePage}
                                            defaultCurrent={1}
                                            total={this.state.total}
                                            pageSize={10}
                                            current={this.state.currentPage}
                                            hideOnSinglePage={true}
                                            showSizeChanger={false}
                                    />
                                </div> : ''

                    }

                </div>
        )
    }
}
