import React from 'react';
import {Row, Col, Pagination, Modal} from 'antd';
import "../index.scss"
import { withRouter} from "react-router-dom";
import xianRed from '../../../../resource/images/profile/xian-red.svg'
import NoData from "../../../NoData";
import xian from "../../../../resource/images/profile/xian.svg"
import http from "../../../../lib/service/http";
import closeImg from "../../../../resource/images/profile/cancle.svg";
import Distribution from "./Modal/Distribution"
import {localTime} from "../../../../lib/help/helper";
class AgencyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      total:0,
      data: [],
      visible: false,
      distributeData:[],
      isDistribute: true,
      productOrder: {},
      statusList: ['PENDING-DISTRIBUTE','PENDING-MATCH','MATCH-SUCCESS','MATCH-FAILED','EXPIRED','CLOSED']
    }
  }
  getList = (key,params) => {
    let tmp = {
      query_status:key,
      page:params.page
    };
    http.get('/financial/product-order/list',tmp).then(res => {
      if (res) {
        if(this.props.type === 'index') {
          this.setState({
            data: res.data.items ? res.data.items.slice(0, 3) : [],
            total: res.data.total
          })
        }else {
          this.setState({
            data: res.data.items,
            total: res.data.total
          })
        }
      }
    })
  };
  componentDidMount () {
    const  key = this.state.statusList[this.props.activeKey];
    let params = {
      page:1
    };
    this.getList(key,params)
  }
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params = {
      page:page,
    };
    this.getList(this.state.statusList[this.props.activeKey],params)
  };
  closeTransaction =(item)=> {
    const that =this;
    Modal.confirm({
      className: 'info-disable',
      width: '416px',
      title: (
        <div className={'disable-title'}>确定关闭该交易吗?</div>
      ),
      content:(
        <div className={'disable-text'}>关闭后不可更改</div>
      ),
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
      onOk() {
        let params = {
          product_order_id: item.ID
        };
        http.post(`/financial/product-order/close?query_status=${item.OrderStatus}`,params).then(res => {
          if (Object.keys(res).length===0) {
            that.getList(item.OrderStatus,{page: that.state.currentPage})
          }
        }, () => {
        })
      },
    });
  };
  getDistributeList = (key,params) => {
    http.get('/financial/product-order/distribute-sub-account-list',{query_status:key}).then(res => {
      if (res.data.item !== null) {
        this.setState({
          distributeData: res.data.items,
          visible: true,
        })
      } else if (res.err_code === 110013) {
        this.setState({
          visible: false,
        });
      }
    })
  };
  distribute=(item)=> {
    this.setState({
      productOrder: item
    });
    this.getDistributeList(item.OrderStatus);
  };
  componentDidUpdate(prevProps,prevState){
    const  key = this.state.statusList[this.props.activeKey];
    let params = {
      page:this.state.currentPage
    };
    if(prevProps.activeKey !== this.props.activeKey) {
      this.getList(key,params);
      this.setState({
        currentPage: 1
      })
    }
  }
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  goDetail= (item)=> {
    this.props.history.push({
      pathname:'/transaction-detail/'+item.ID,
      state: '产品详情'
    });
  };
  closeModal = () => {
    this.setState({
      visible: false
    });
    this.getList( this.state.statusList[this.props.activeKey],{page: this.state.currentPage})
  };
  render() {
    const {total,data ,visible} = this.state;
    return (
      <div className="transaction-list  agency-list">
            {total > 0 ? (  <div className={''}>
                <Row className={'transaction-header-content'}>
                  <Col span={3} className={'text'}>交易编号</Col>
                  <Col span={6} className={'text'}>申请产品</Col>
                  <Col span={6} className={'text'}>申请企业</Col>
                  <Col span={5} className={'text'}>申请日期</Col>
                  <Col span={4} className={'text'} style={{paddingLeft: '25px'}}>操作</Col>
                </Row>
                {data.map(item=> {
                  return   <div className={'list-content'} key={item.ID}>
                    <Row className={'list-item'}>
                      <Col span={3} className={'text'}>{item.ID}</Col>
                      <Col span={6} className={'text'}>
                        <div>{item.ProductName}</div>
                        <div className={'text-type'}>类型： <span>{item.ProductType==='TRADING'?'贷款融资':'企业服务'} </span></div>
                      </Col>
                      <Col span={6} className={'text'}>
                        <div>{item.EnterpriseName}</div>
                        <div className={'text-type'}>联系方式： <span>{item.Telephone}</span></div>
                      </Col>
                      <Col span={5} className={'apply-time'}>
                        <div>
                         <p>{localTime(item.CreatedAt)}</p>
                        {item.OrderStatus ==='PENDING-DISTRIBUTE'&&
                        <div className={'text-type remain-days'}>
                          {item.RemainDays&&Number(item.RemainDays)>7?
                            <div>
                              <img src={xian} alt="" className={'date-img'}/> <span>{item.RemainDays?item.RemainDays+'天':'长期'}</span>
                            </div>: <div>
                              <img src={xianRed} alt="" className={'date-img'}/> <span>{item.RemainDays+'天'}</span>
                            </div>}
                        </div>}
                        </div>
                      </Col>
                      <Col span={4} className={'btn'}>
                        <div>
                          {
                            (item.OrderStatus==='PENDING-DISTRIBUTE' || item.OrderStatus==='PENDING-MATCH')&&  <p onClick={this.distribute.bind(this, item)}>{item.FinancialSubUserID?'重新分配':'分配子账号'}</p>
                          }
                          <p onClick={this.goDetail.bind(this, item)}>查看详情</p>
                        {
                          item.OrderStatus==='PENDING-DISTRIBUTE'&&  <p onClick={this.closeTransaction.bind(this, item)}>关闭交易</p>
                        }
                       </div>
                      </Col>
                    </Row>
                  </div>
                })}
            <Modal title={'分配子账号'}
                   visible={visible}
                   closeIcon={<img src={closeImg} alt=""/>}
                   onCancel={this.handleCancel}
                   onOk={this.handleCancel}
                   okText={'确定'}
                   footer={null}
                   width={450}
                   wrapClassName='modal-distribute'
                   destroyOnClose={true}
            >
              <Distribution close={this.closeModal} productOrder={this.state.productOrder} distributeData={this.state.distributeData}/>
            </Modal>
                <div className={'page-total-data'}>
                  <div >
                    共计 <span className={'total-num'}>{total}</span> 条数据
                  </div>
                  <Pagination
                    className={'pageStyle'}
                    onChange={this.onChangePage.bind(this)}
                    defaultCurrent={1}
                    total={total}
                    pageSize={10}
                    showSizeChanger={false}
                    current={this.state.currentPage}
                  />
                </div>
          </div>
        ):<div className="news-list-content">
          <NoData />
        </div>}
      </div>
    )
  }
}
export default withRouter(AgencyList)
