import React from 'react';
import {Row, Col, Pagination, Modal, Space, message} from 'antd';
import NoData from "../../NoData";
import http from "../../../lib/service/http";
import {getImgPublicUrl, localTime} from "../../../lib/help/helper";
import {withRouter} from "react-router-dom";
class AllData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            currentPage: 1,
            total: 0,
            data: [],
           options: [
                {value:'LIQUIDITY',name: '流动资金'},
                {value:'FIXED',name: '固定资产'},
                {value:'FINANCING',name: '项目融资'},
                {value:'OTHER',name: '其他'}
            ],
        }
    }
    SelectOptions=(key)=> {
        return(this.state.options.filter(function(item){
            return item.value===key;
        })[0].name)
    };
    getList = (params) => {
        if (this.props.activeKey=== '0') {
            http.get('/enterprise/financing-list').then(res => {
                if (res) {
                    this.setState({
                        data:res.data.items,
                        total:res.data? res.data.total: 0
                    })
                    console.log(res.data)
                }
            })
        } else {
            let tmp  = {
                page:params.page
            };
            http.get('/enterprise/financing-deleted-list',tmp).then(res => {
            if (res) {
                this.setState({
                    data:res.data.items,
                    total:res.data? res.data.total: 0
                })
            }
        })
        }

    };
    componentDidUpdate(prevProps,prevState){
        if(prevProps.activeKey !== this.props.activeKey) {
            this.setState({
                currentPage: 1
            })
            let params = {
                page: 1,
            };
            this.getList(params);
        }
    }
    componentDidMount () {
        let params = {
            page: 1,
        };
        this.getList(params)
    }
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
        let params = {
            page:page
        };
        this.getList(params)
    };
    delete = item => {
        const that = this;
        Modal.confirm({
            className: 'info-disable',
            width: '416px',
            title: (
                <div className={'disable-title'}>确定删除该融资需求吗?</div>
            ),
            content: (
                <div className={'disable-text'}>删除后将无法找回</div>
            ),
            okText: '确定',
            cancelText: '取消',
            onCancel() {},
            onOk() {
                http.post('enterprise/deleted-financing', {id:item.ID}).then(res => {
                    if (Object.keys(res).length===0) {
                        message.success({
                            className: '',
                            content: '删除成功',
                            duration: 2
                        });
                        that.getList()
                    }
                }, () => {
                })
            },
        });
    };
    addData =()=> {
        this.props.history.push({
            pathname:'/add-requirements'
        })
    }
    render() {
        const {total,data} =  this.state;
        return (
            <div className="requirement-list">
                <p className={'apply-require-btn'} onClick={this.addData}>发布融资需求</p>
                {total !==0? (
                    <div>
                        <div className={'table-data'}>
                            {
                                data.map((item) => {
                                    return   <div className={'list-content'} key={item.ID}>
                                        <div className={'list-header'}>
                                            <div className={'list-header-title requirement-num' }>发布时间：<span className={'text'}>{localTime(item.CreatedAt)}</span></div>
                                            {this.props.activeKey==='1'&&<div className={'list-header-title requirement-num' }>删除时间：<span className={'text'}>{localTime(item.DeletedAt)}</span></div>}
                                        </div>
                                        <Row className="list-row">
                                            <Col span={20}>
                                        <div className="list-detail">
                                            <div>
                                                <span className="list-title">期望融资金额 </span>
                                                <span className="amount">{item.Amount}万元</span>
                                                <div className="other-more">
                                                    <span className="list-title">融资用途 </span>
                                                    <span className="text">{this.SelectOptions(item.FinancingPurpose)}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="list-title">期望年化利率 </span>
                                                <span className="text">{item.RateFloor}%-{item.RateCeil}%</span>
                                                <div className="other-more">
                                                    <span className="list-title">担保方式 </span>
                                                    <span className="text">{item.SecuredMode}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="list-title">融资期限 </span>
                                                <span className="text">{item.Cycle}个月</span>
                                            </div>
                                        </div>
                                            </Col>
                                            {
                                                this.props.activeKey==='0' && <Col span={4} className="del-box">
                                                <div className="del-btn" onClick={this.delete.bind(this, item)}>删除</div>
                                            </Col>
                                            }
                                        </Row>
                                    </div>
                                })
                            }
                            <div className={'page-total-data'}>
                                <div >
                                    共计 <span className={'total-num'}>{total}</span> 条数据
                                </div>
                                <Pagination
                                    className={'pageStyle'}
                                    onChange={this.onChangePage}
                                    defaultCurrent={1}
                                    total={total}
                                    pageSize={10}
                                    showSizeChanger={false}
                                    current={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                ): (<div className="news-list-content">
                    <NoData />
                </div>)}
            </div>
        )
    }
}
export default withRouter(AllData)
