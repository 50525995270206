import React from 'react';
import './index.scss'
import {getUserInfo} from "../../../lib/help/public";
import { Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import Company from "./Company"
import Agency from "./Agency"

export default class AuthorIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsInfo: {},
            userInfo: {
                user: {}
            },
        }
    }


    getUserInfo() {
        let tmpInfo = sessionStorage.getItem('userInfo');
        if(tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            this.setState({
                userInfo: tmpInfo
            })
        }
    }

    componentDidMount() {
        getUserInfo();
        this.getUserInfo();
    }

    componentWillUnmount() {
    }

    render() {
        return (
          <div className={'profile-index-container users-breadcrumb'}>
              <div className={'breadcrumb detail-breadcrumb'}>
                  <Breadcrumb separator=">">
                      <Breadcrumb.Item>
                          <Link className={'link'} to={'/profile'}>个人中心</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                          <a className={'link'}>工作台</a>
                      </Breadcrumb.Item>
                  </Breadcrumb>
              </div>
            <div className="detail-breadcrumb-margin">
                {
                    this.state.userInfo.user.UserType === '企业账户' &&
                        <Company />
                }
                {
                    (this.state.userInfo.user.UserType === '机构子账户' || this.state.userInfo.user.UserType === '机构主账户') &&
                    <Agency />

                }

            </div>
          </div>
        )
    }
}
