import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import './index.scss'
import imgSuccess from '../../resource/images/public/img-success-tip.svg'


class InfoSupplement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nextTime: 3,
        }
    }

    componentDidMount() {
        this.setNextTime();
    }

    componentWillUnmount() {
    }

    setNextTime() {
        let tmpVal = setInterval(() => {
            if(this.state.nextTime === 0) {
                clearInterval(tmpVal)
                tmpVal = null;
                this.props.history.replace('/profile')
            }
            this.setState({
                nextTime: this.state.nextTime - 1
            })
        }, 1000)
    }

    render() {
        return (
            <div className="info-tip-container">
                <div className="info-tip-content content">
                    <img src={imgSuccess} alt="" className="img-tip"/>
                    <p className="title">信息补充完成</p>
                    <p className="desc">
                        页面将在{this.state.nextTime}秒内跳转至个人中心。<Link to={'/profile'}>立即跳转</Link>
                    </p>
                </div>
            </div>
        )
    }
}

export default withRouter(InfoSupplement)