import React from 'react';
import './index.scss';
import {Col, Tabs} from 'antd';
import Financing from './Financing'
import banner from "../../resource/images/public/financing-banner.jpg"
import {setRequireLink} from "../../lib/help/public";

const {TabPane} = Tabs;
export default class FinancingRequirements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1
        }
    }
    callback=(key)=> {
        this.setState({
            activeKey: key
        })
    }

    componentWillMount() {
        const tab = this.props.location.search;
        if (tab.indexOf('tab') > -1) {
            this.setState({activeTab: tab.substr(-1, 1)})
        }
    }
    render() {
        let userInfo = sessionStorage.getItem('userInfo') || '{}';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
        }
        return (
            <div className="firm-register-container financing-requirement-container">
                <div className={'product-banner'}>
                    <img src={banner} alt=""/>
                </div>
                <div className="firm-register-content content">
                    <Tabs defaultActiveKey={`${this.state.activeTab}`} className={'tab-box'} tabBarGutter={48}  onChange={this.callback}>
                        <TabPane tab={
                            <p className="tab-item tab-item-1">融资需求</p>
                        } key="1">
                            {(!userInfo.user|| userInfo.user&&userInfo.user.UserType === '企业账户')&& <p onClick={(event)=>setRequireLink(event, this,'require')} className={'apply-require-btn detail-btn'}>发布融资需求</p>}
                            <Financing activeKey={this.state.activeKey}/>
                        </TabPane>
                    </Tabs>
                    </div>
            </div>
        )
    }
}
