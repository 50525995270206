import {
    regexps_password,
    regexps_account,
    regexps_phone,
    regexps_img_code,
    regexps_phone_code,
    regexps_email,
    regexps_invitation_code,
    regexps_id_card,
    regexps_capitalized_amount,
    regexps_credit_code,
    regexps_product_name,
    regexps_product_num,
    regexps_product_other,
    regexps_product_rate,
    regexps_product_date,
    regexps_product_email_phone,
    regexps_remark,
    regexps_product_zero,
    regexps_memo
} from './validates'

import {fileMax, fileName, fileType} from "./public";

export const rules = {
    account: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_account,
            message: '输入项错误，需输入6-20位字符,含数字,字母中的一种及以上',
        }
    ],
    roleName: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_product_name,
            message: '请输入20位字符',
        }
    ],
    password: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_password,
            message: '8-16位字符，含数字、大小写字母、特殊字符中的三种及以上',
        },
    ],
    phone: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_phone,
            message: '手机号码格式错误',
        },
    ],
    img_code: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_img_code,
            message: '格式错误',
        },
    ],
    phone_code: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_phone_code,
            message: '格式错误',
        },
    ],
    email: [
        {
            required: false,
        },
        {
            pattern: regexps_email,
            message: '格式错误',
        },
    ],
    emailUser: [
        {
            required: true,
            message: '请输入邮箱',
        },
        {
            pattern: regexps_email,
            message: '格式错误',
        },
    ],
    pact: [
        {
            required: true,
            message: '请勾选',
        },
        // {
        //     validator: (_, value) =>{
        //         value ? Promise.resolve() : Promise.reject('请勾选')
        //     }
        // },
    ],
    pactSecured: [
        {
            required: true,
            message: '请勾选',
        }
    ],
    invitation_code: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_invitation_code,
            message: '格式错误',
        },
    ],
    id_card: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_id_card,
            message: '格式错误',
        },
    ],
    upload_product_file: [
        {
            required: true,
            message: '请上传',
        },
        {
            validator: (_, value, callback) => {
                fileMax(_, value, callback, {size: process.env.REACT_APP_FILE_SIZE})
            }
        },
        {
            validator: (_, value, callback) => {
                fileName(_, value, callback);
            }
        },
        {
            validator: (_, value, callback) => {
                fileType(_, value, callback);
            }
        },
    ],
    upload_file: [
        {
            required: true,
            message: '请上传',
        },
        {
            validator: (_, value, callback) => {
                fileMax(_, value, callback, {size: process.env.REACT_APP_FILE_SIZE});
            }
        },
        {
            validator: (_, value, callback) => {
                fileName(_, value, callback);
            }
        },
        {
            validator: (_, value, callback) => {
                fileType(_, value, callback);
            }
        }
    ],
    upload_file_not_required: [
        {
            required: false,
        },
        {
            validator: (_, value, callback) => {
                fileMax(_, value, callback, {require: false, size: process.env.REACT_APP_FILE_SIZE})
            }
        },
        {
            validator: (_, value, callback) => {
                fileName(_, value, callback, {require: false});
            }
        },
        {
            validator: (_, value, callback) => {
                fileType(_, value, callback,{require: false});
            }
        }
    ],
    not_null: [
        {
            required: true,
            message: '请输入',
        }
    ],
    time_zone: [
        {
            required: true,
            message: '请选择'
        }
    ],
    is_number: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_capitalized_amount,
            message: '格式错误',
        }
    ],
    is_select: [
        {
            required: true,
            message: '请选择'
        },
    ],
    credit_code: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_credit_code,
            message: '格式错误',
        }
    ],
    product_name: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_name,
            message: '输入内容过长，最多输入20字符',
        }
    ],
    remark: [
        {
            required: false,
        },
        {
            pattern: regexps_remark,
            message: '输入内容过长，最多输入50字符',
        }
    ],
    product_num: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_num,
            message: '输入合法额度，精度最大四位小数',
        },
        {
            pattern: regexps_product_zero,
            message: '输入合法额度，精度最大四位小数'
        }
    ],
    product_num_max: [
        {
            required: false
        },
        {
            pattern: regexps_product_num,
            message: '输入合法额度，精度最大四位小数',
        },{
            pattern: regexps_product_zero,
            message: '输入合法额度，精度最大四位小数'
        }
    ],
    product_other: [
        {
            required: true,
            message: '请输入',
            whitespace: true,
        },
        // {
        //     pattern: regexps_product_other,
        //     message: '输入内容过长，最多输入1000字符',
        // },
        {
            validator: (_, value, callback) => {
                return new Promise((resolve, reject) => {
                    if (value.length!==0) {
                        if (regexps_product_other.test(value)) {
                            callback()
                            resolve()
                        } else {
                            callback(new Error('输入内容过长，最多输入3000字符'));
                            reject('输入内容过长，最多输入3000字符')
                        }
                    }else {
                        callback();
                        resolve()
                    }
                });
            }
        }
    ],
    product_other_un_require: [
        {
            required: false,
        },
        {
            validator: (_, value, callback) => {
                return new Promise((resolve, reject) => {
                    if (value && value.length!==0) {
                        if (regexps_product_other.test(value)) {
                            callback()
                            resolve()
                        } else {
                            callback(new Error('输入内容过长，最多输入3000字符'));
                            reject('输入内容过长，最多输入3000字符')
                        }
                    }else {
                        callback();
                        resolve()
                    }
                });
            }
        }
    ],
    memo: [
        {
            required: false,
        },
        {
            validator: (_, value, callback) => {
                    if (value && value.length!==0) {
                        let newValue = value.replace(/[\r\n]/g,"").replace(/(^\s*)|(\s*$)/g,"");
                        if (regexps_memo.test(newValue)) {
                            return Promise.resolve()
                        } else {
                            return Promise.reject('输入内容过长，最多输入200字符');
                        }
                    }else {
                        return Promise.resolve()
                    }

                // return new Promise((resolve, reject) => {
                //     if (value && value.length!==0) {
                //         let newValue = value.replace(/[\r\n]/g,"").replace(/(^\s*)|(\s*$)/g,"");
                //         if (regexps_memo.test(newValue)) {
                //             callback()
                //             resolve()
                //         } else {
                //             callback(new Error('输入内容过长，最多输入200字符'));
                //             reject('输入内容过长，最多输入200字符')
                //         }
                //     }else {
                //         resolve()
                //         callback();
                //     }
                // });
            }
        }
    ],
    product_rate: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_rate,
            message: '输入合法利率，精度最大两位小数',
        }
    ],
    product_rate_max: [
        {
            required: false
        },
        {
            pattern: regexps_product_rate,
            message: '输入合法利率，精度最大两位小数',
        }
    ],
    product_date: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_date,
            message: '请输入大于0的整数',
        },
        {
            validator: (_, value, callback) => {
                if (value && value.length > 3) {
                    return Promise.reject('期限已超过最大值');
                } else {
                    return Promise.resolve()
                }
            }
        }
    ],
    require_date: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_date,
            message: '请输入大于0的整数',
        },
        {
            validator: (_, value, callback) => {
                if (value && value > 120) {
                    return Promise.reject('期限已超过最大值');
                } else {
                    return Promise.resolve()
                }
            }
        }
    ],
    product_date_max: [
        {
            required: false,
        },
        {
            pattern: regexps_product_date,
            message: '请输入大于0的整数',
        },
        {
            validator: (_, value, callback) => {
                if (value && value.length > 3) {
                    return Promise.reject('期限已超过最大值');
                } else {
                    return Promise.resolve()
                }
            }
        }
    ],
    product_contact: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_email_phone,
            message: '请输入手机号或邮箱地址',
        }
    ]
}
