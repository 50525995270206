import React from 'react';
import {Layout, Menu} from 'antd';

import './index.scss'
import {Link, withRouter} from "react-router-dom";
import work from "../../resource/images/user-info/work.svg"
import product from "../../resource/images/user-info/product.svg"
import productActive from "../../resource/images/user-info/product-active.svg"
import deal from "../../resource/images/user-info/jiaoyi.svg"
import dealActive from "../../resource/images/user-info/deal-active.svg"
import account from "../../resource/images/user-info/account.svg"
import accountActive from "../../resource/images/user-info/account-active.svg"
import message from "../../resource/images/user-info/message.svg"
import messageActive from "../../resource/images/user-info/message-active.svg"
import workActive from "../../resource/images/user-info/work-active.svg"
import requre from "../../resource/images/user-info/require.svg"
import requreActive from "../../resource/images/user-info/require-active.svg"
import http from "../../lib/service/http";
import emitter from '../../lib/help/events';
const {  Sider } = Layout;
const {SubMenu} = Menu;
const MenuConfig = [
    {
        title:'工作台',
        url:'/profile',
        key:'/profile',
        icon: work,
        iconActive: workActive,
        children: [],
        state :'工作台',
    },
    {
        title:'我的产品',
        url:'/user-info/debt-finance',
        key:'/user-info',
        icon: product,
        state :'我的产品',
        iconActive: productActive,
        children:[
            {
                title: '贷款融资',
                url: '/user-info/debt-finance',
                key:'/user-info/debt-finance',
                state :'贷款融资',
            },
            {
                title: '企业服务',
                url: '/user-info/company-services',
                key:'/user-info/company-services',
                state :'企业服务',
            },
        ]
    },
    {
        title:'我的交易',
        url:'/transaction',
        key:'/transaction',
        icon: deal,
        iconActive: dealActive,
        children: [],
        state :'我的交易',
    },
    {
        title:'我的账户',
        url:'/my-account/account-info',
        key:'/my-account',
        state :'我的账户',
        icon: account,
        iconActive: accountActive,
        children: [
            {
                title: '基本信息',
                state :'基本信息',
                url: '/my-account/account-info',
                key: '/my-account/account-info'
            },
            {
                title: '账户安全',
                state :'账户安全',
                url: '/my-account/account-security',
                key: '/my-account/account-security'
            },
            {
                title: '权限管理',
                url: '/my-account/auth-manage',
                key: '/my-account/auth-manage',
                state :'权限管理'
            }
        ]
    },
    {
        title:'我的消息',
        url:'/my-message',
        key: '/my-message',
        icon: message,
        iconActive: messageActive,
        children: [],
        state :'我的消息'
    }
];
const firmMenuConfig = [
    {
        title:'工作台',
        url:'/profile',
        key:'/profile',
        icon: work,
        iconActive: workActive,
        children: [],
        state :'工作台',
    },

    {
        title:'我的交易',
        url:'/transaction',
        key:'/transaction',
        icon: deal,
        iconActive: dealActive,
        state :'我的交易',
        children: []
    },
    {
        title:'我的融资需求',
        url:'/requirements',
        key:'/requirements',
        icon: requre,
        iconActive: requreActive,
        state :'我的融资需求',
        children: []
    },
    {
        title:'我的账户',
        url:'/my-account/account-info',
        key:'/my-account',
        state :'我的账户',
        icon: account,
        iconActive: accountActive,
        children: [
            {
                title: '基本信息',
                state :'基本信息',
                url: '/my-account/account-info',
                key: '/my-account/account-info'
            },
            {
                title: '账户安全',
                state :'账户安全',
                url: '/my-account/account-security',
                key: '/my-account/account-security'
            }
        ]
    },
    {
        title:'我的消息',
        url:'/my-message',
        key: '/my-message',
        icon: message,
        iconActive: messageActive,
        children: [],
        state :'我的消息',
    }
];
class Aside extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            openKeys: ['/profile'],
            defaultSelectedKeys: ['/profile'],
            currentUrl: '',
            userInfo: {},
            menuTreeNode: [],
            breadcrumb: '',
            breadcrumbSub: '',
            sub: true,
            userRoute: {},
            SubMenu: [],
            isMenu:true,
            messageCount: 0,
         SubMenuConfig :[
            {
              title:'工作台',
              url:'/profile',
              key:'/profile',
              icon: work,
              state :'工作台',
              iconActive: workActive,
              children: [],
              auth: '工作台',
              isChildren: false,
            },
            {
              title:'我的产品',
              url:'/user-info/sub-debt-finance',
              key:'/user-info',
              icon: product,
              state :'我的产品',
              auth: '我的产品',
              isChildren: true,
              iconActive: productActive,
              children:[
                {
                  title: '贷款融资',
                  url: '/user-info/sub-debt-finance',
                  key:'/user-info/sub-debt-finance',
                  state :'贷款融资',
                  auth: '发布贷款融资'
                },
                {
                  title: '企业服务',
                  url: '/user-info/sub-company-service',
                  key:'/user-info/sub-company-service',
                  state :'企业服务',
                  auth: '发布企业服务'
                },
              ]
            },
            {
              title:'我的交易',
              url:'/transaction',
              key:'/transaction',
              icon: deal,
              iconActive: dealActive,
              state :'我的交易',
              auth:'交易管理',
              children: [],
              isChildren: false
            },
            {
              title:'我的账户',
              url:'/my-account/account-info',
              key:'/my-account',
              state :'我的账户',
              auth:'我的账户',
              icon: account,
              iconActive: accountActive,
              isChildren: true,
              children: [
                {
                  title: '基本信息',
                  state :'基本信息',
                  url: '/my-account/account-info',
                  key: '/my-account/account-info',
                  auth:'更新机构信息'
                },
                {
                  title: '账户安全',
                  state :'账户安全',
                  url: '/my-account/account-security',
                  key: '/my-account/account-security',
                  auth:'账户安全'
                }
              ]
            },
            {
              title:'我的消息',
              url: '/my-message',
              key: '/my-message',
              icon: message,
              iconActive: messageActive,
              children: [],
              state :'我的消息',
              auth: '我的消息',
              isChildren: false,
            }
          ]
        }
    }

    componentWillMount(){
        this.getSubRoute(this.state.SubMenuConfig);
    }
    getSubRoute=(data)=>{
       let  original  = ['更新机构信息','发布企业服务','发布贷款融资','交易管理'];
        let userRoute = sessionStorage.getItem('routeInfo') || '{}';
        let routeName = Object.keys(JSON.parse(userRoute));
        this.setState({
            userRoute : JSON.parse(userRoute)
        });
        original.forEach((item,index)=>{
            if(routeName.indexOf(item) > -1) {
                delete original[index]
            }
        });
    data.forEach((item,index)=> {
             if (item.isChildren) {
                 this.getSubRoute(item.children);
                     let length = 0;
                     for(let i in item.children){
                         length ++;
                     }
                    if(length===0) {
                        delete data[index];
                    }
             } else {
                 if (original.indexOf(item.auth) >  -1) {
                     delete data[index];
                 }
             }
        });
      this.setState({
        SubMenuConfig: data
      });
    };
    getMenu=(url)=> {
      let userInfo = sessionStorage.getItem('userInfo') || '{}'
      if (userInfo) {
        userInfo = JSON.parse(userInfo);
      }
      if (userInfo.user &&userInfo.user.UserType === '机构子账户') {
      //  this.getSubRoute(this.state.SubMenuConfig);
        return this.renderMenu(this.state.SubMenuConfig,url);
        // this.setState({
        //   menuTreeNode:menuTreeNode
        // })
      } else if (userInfo.user &&userInfo.user.UserType === '机构主账户'){
        return this.renderMenu(MenuConfig,url);
        // this.setState({
        //   menuTreeNode:menuTreeNode
        // })
      } else if (userInfo.user && userInfo.user.UserType === '企业账户') {
       return  this.renderMenu(firmMenuConfig,url);
        // this.setState({
        //   menuTreeNode:menuTreeNode
        // })
      }
    }
    componentDidMount() {
        const {location} = this.props.history;
        this.setState({
            currentUrl: location.pathname,
            openKeys: ['/'+location.pathname.split('/')[1]]
        });
        this.getUserInfo();
        this.props.history.listen(route => {
            this.getUserInfo();
          this.getSubRoute(this.state.SubMenuConfig);
            this.setState({
                openKeys: this.getOpenKeys(this.props.history.location.pathname.split('/')),
                currentUrl: route.pathname
            });
        });
        this.getMessageCount();

        this.eventEmitter = () => {
            this.getMessageCount();
        };


        // 组件装载完成以后声明一个自定义事件
        emitter.addListener('changeMessage', this.eventEmitter);
    }

    componentWillUnmount() {
        emitter.removeListener('changeMessage', this.eventEmitter);
    }

    getMessageCount() {
        http.get('user/message-count').then(res => {
            this.setState({
                messageCount: res.UnreadCount
            });
        });
    }

    getOpenKeys = (keys) => {
        if (keys.length >= 3) {
            return keys.filter((item, i) => i > 0 && i < keys.length - 1).map(item => '/' + item)
        } else {
            return ['/' + keys[1]]
        }
    };
    getUserInfo() {
        let tmpUserInfo = sessionStorage.getItem('userInfo');
        if (tmpUserInfo) {
            this.setState({
                userInfo: JSON.parse(tmpUserInfo)
            })
        }
    }
    onOpenChange = (openKeys) => {
        this.setState({
            openKeys: [openKeys[openKeys.length - 1]]
        })
    };
    setMenuIcon(url, classNameType = 0) {
        let tmpName = classNameType ? 'menu-icon-active' : 'menu-icon-default';
        return (
            <img src={url} alt="" className={'menu-icon ' + tmpName} />
        )
    }
    setSelected=({ item, key})=>{
        this.setState({
            breadcrumb: item.props.title
        })
    };
    renderMenu = (data,url)=>{
        return data.map((item)=>{
            if(item.children){
                if (item.children.length > 0){
                return (
                  <SubMenu title={item.title} key={item.key}
                           icon={<span>
                                     {
                                         this.setMenuIcon(item.icon,0)
                                     }
                               { this.setMenuIcon(item.iconActive,1)}
                                 </span>}
                  >
                    {this.renderMenu(item.children,url)}
                  </SubMenu>
                );
            }
                if (item.children.length === 0){
                    return (
                      <Menu.Item title={item.title} key={item.key}
                                 className={(url.indexOf(item.url) > -1) ? 'active-submenu' : ''}
                                 icon={<span>
                                     {
                                     this.setMenuIcon(item.icon,0)
                                 }
                                     { this.setMenuIcon(item.iconActive,1)}
                                 </span>}>
                          <Link className="back-btn" to={{pathname:item.url,state: item.state}} >{item.title}</Link>
                      </Menu.Item>
                    )
                }
            }
            return (
              <Menu.Item title={item.title} key={item.key}  className={(url.indexOf(item.url) > -1) ? 'active' : 'default'} >
                  <Link className="back-btn" to={{pathname:item.url, state: item.state}}>{item.title}</Link>
              </Menu.Item>
            )
        })
    };
    render() {
      let userInfo = sessionStorage.getItem('userInfo') || '{}';
      if (userInfo) {
        userInfo = JSON.parse(userInfo);
      }
        return (
          <div>
              <div className={'breadcrumb'} >
              </div>
            <Sider
                style={{
                    overflow: 'hidden',
                    minHeight: '80vh',
                    background: '#FFFFFF',
                }}
                width={233}
                className={'user-aside'}
            >
              {userInfo.user&& (<div className={'zhigui-logo'}>
               <p className={'zhigui-logo-text'}>
                  {userInfo.info.Name}
                </p>
              </div>)}

                <Menu mode="inline"
                      openKeys={this.state.openKeys}
                      onOpenChange={this.onOpenChange.bind(this)}
                      defaultSelectedKeys={this.state.defaultSelectedKeys}
                      selectedKeys={this.state.currentUrl}
                      onSelect={(item) => this.setSelected(item)}
                      className={''}
                      theme="light"
                >
                    {this.getMenu(this.state.currentUrl)}

                    {this.state.messageCount !== 0 ? <div className={'badge'}>{this.state.messageCount}</div> : ''}
                </Menu>
            </Sider>
             </div>
        )
    }
}

export default withRouter(Aside)
