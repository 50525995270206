import React from 'react';
import {Link, withRouter} from "react-router-dom";
import http from "../../../lib/service/http";
import './index.scss';
import { Row, Col, Breadcrumb} from 'antd';
import {getImgPublicUrl, localTime} from "../../../lib/help/helper";
import iconUpload from "../../../resource/images/public/img_qiyefuwu.svg";
import vector from "../../../resource/images/public/vector.svg";
import time from "../../../resource/images/public/time.svg"
import rectangle from "../../../resource/images/public/rectangle.svg"
import recommend from "../../../resource/images/public/recommend.svg"
import NoData from "../../NoData";
import { setConfirmLink} from "../../../lib/help/public";
class CorporateDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailList: {},
      contact: '',
      hotDebtList: [],
      userInfo: {},
    }
  }
  getListData(params) {
    http.get(`home/enterprise-service-detail/${params}`).then(res => {
      this.setState({
        detailList: res.detail
      });
    })
  };
  getContact() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    let user = JSON.parse(userInfo).user;
    if (user) {
      const params = {
        id: Number(this.props.match.params.id),
        product_type: "SERVICE",
      };
      http.post('/home/product-contact', params).then(res => {
        if(res) {
          this.setState({
            contact: res.contact
          })
        }
      }, () => {
      });
    } else {
      this.setState({
        contact: '******'
      })
    }
  };
  getHotDebt() {
    http.get('/home/hot-enterprise-service-list/'+ this.props.match.params.id).then(res => {
      this.setState({
        hotDebtList: res.items
      });
    })
  };
  componentWillMount () {
    const id = this.props.match.params.id;
    this.getListData(id);
    this.getContact();
    this.getHotDebt();
  }
  goDetail =(ID)=> {
    const id = ID;
    this.getListData(id);
    this.getContact();
    this.getHotDebt()
  };
  render() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    const {detailList,contact,hotDebtList} =  this.state;
    return (
      <div className="firm-register-container product-detail-container">
        <div className="firm-register-content content">
          <div className={'breadcrumb'}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link className={'link'} to={'/product-supermarket'}>产品超市</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detailList.Name||'--'}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col span={19}>
              <Row className={'detail-info part-one'}>
                <Col span={4} className={'img-box'}>
                  {detailList.ImageFid ? ( <img src={getImgPublicUrl(detailList.ImageFid)} alt="" />): ( <img src={iconUpload} alt=""/>)}
                </Col>
                <Col span={20} className={'info-box'}>
                  <div className={'title'}>{detailList.Name||'--'}</div>
                  <Row className={'bond-info'}>
                    <Col span={12}>
                      <div className={"fin-name"}>
                        <img src={vector} className={'vector-img'} alt=""/>
                        <span> {detailList.FinName||'--'}</span>
                      </div>
                    </Col>
                    <Col span={12}><img src={time} className={'vector-img'} alt=""/>{localTime(detailList.CreatedAt)||'--'}</Col>
                  </Row>
                  <Row className={'range-info range-service'}>
                    <Col span={11} >
                      <ul>
                        <li className={'title-num title-rate'}>{detailList.PriceFloor}-{detailList.PriceCeil||'--'}万元</li>
                        <li className={'title-diff'}>价格区间</li>
                      </ul>
                    </Col>
                    <Col span={1} className={'range-service-col'}/>
                    <Col span={12}>
                      <ul>
                        <li className={'title-num'}>{detailList.ProductTypeStr||'--'}</li>
                        <li className={'title-diff'}>服务类别</li>
                      </ul>
                    </Col>
                    {(!userInfo.user|| userInfo.user&&userInfo.user.UserType === '企业账户')&& <p onClick={(event)=>setConfirmLink(event, this, detailList.ID, 'company')} className={'apply-btn detail-btn'}>立即申请</p>}
                  </Row>
                </Col>
                <div className={'product-info'}>
                  <div className={'product-features'}>
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>项目介绍</span></div>
                      <div className={'service-introduction'}>{detailList.Description||'--'}</div>
                    {detailList.Remark&&detailList.Remark.length>0?(<div>
                      <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>其他说明</span></div>
                      <div className={'service-introduction'}>{detailList.Remark||'--'}</div>
                    </div>):(<div/>)}
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>联系方式</span></div>
                    <div className={'service-introduction'}>{contact||'--'}</div>
                  </div>
                </div>
              </Row>
            </Col>
            <Col span={5} className={'detail-info'}>
              <div className={'recommend'}>
                <img src={recommend} alt=""/> <span className={'other-product'}>其他产品推荐</span>
              </div>
              {hotDebtList&&hotDebtList.length>0 ? (hotDebtList.map((item,index) => (
                <div className={'other-product-info'} key={index} onClick={() =>this.goDetail(item.ID)}>
                  <div className={'other-product-img'}>
                    {item.LogoFid? <img src={getImgPublicUrl(item.LogoFid)} alt=""/>: <img src={iconUpload} alt=""/>}
                  </div>
                  <div className={'other-product-title'}>{item.Name||'--'}</div>
                  <div className={'hot-rate'}><span className={'rate-title'}>价格区间：</span><span className={'rate-range'}><li className={'title-num title-rate'}>{item.PriceCeil? item.PriceFloor +"-"+item.PriceCeil+"万元":item.PriceFloor+"万元以上"||'--'}</li></span></div>
                  {(!userInfo.user|| userInfo.user&&userInfo.user.UserType === '企业账户')&& <p onClick={(event)=>setConfirmLink(event, this, item.ID, 'company')} className={'apply-btn detail-btn'}>立即申请</p>}
                </div>
              ))):((<div className="news-list-content">
                <NoData />
              </div>))}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default withRouter(CorporateDetail)
