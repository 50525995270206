import http from "../service/http";
import {regexps_file_name} from "./validates";
import Cookie from "js-cookie";
import {message} from "antd";

export function getUserInfo(callback) {
    http.get('/user/info').then(res => {
        sessionStorage.setItem('userInfo', JSON.stringify(res));
        callback && callback(JSON.stringify(res));
    })
}

export function fileMax(_, value, callback, params = {require: true}) {
    return new Promise((resolve, reject) => {
        if (!params.require && !value) {
            resolve()
            callback()
        }
        if (value.size) {
            if (params.size && (value.size <= params.size)) {
                callback()
                resolve()
            } else {
                callback(new Error('文件过大'))
                reject('文件过大')
            }
        }else {
            callback()
            resolve()
        }
    })
}

export function fileName(_, value, callback, params = {require: true}) {
    return new Promise((resolve, reject)=>{
        if (!params.require && !value) {  // 为字段为非必填字段处理
            resolve()
            callback()
            return;
        }
        if(value.name) {    // 编辑时候传进来的file是个字符串 为点击编辑后直接点保存处理
            if (regexps_file_name.test(value.name.slice(0, value.name.indexOf('.')))) {
                resolve()
                callback()
            } else {
                reject('文件名不合法')
                callback(new Error('文件名不合法'));
            }
        }else {
            callback()
            resolve()
        }
    })
}

export function fileType(_, value, callback, params = {require: true}) {
    return new Promise((resolve, reject)=>{
        if (!params.require && !value) {
            resolve()
            callback()
            return;
        }
        if(value.name) {
            if (value.name.indexOf('png') === -1 && value.name.indexOf('jpg') === -1&&value.name.indexOf('jpeg') === -1 ) {
                reject('文件类型错误');
                callback(new Error('文件类型错误'));
            } else {
                resolve()
                callback()
            }
        }else {
            callback()
            resolve()
        }
    })

}
export function setConfirmLink(e, that, id, type) {
    e.stopPropagation();
    e.returnValue = false;
    let tmpInfo = sessionStorage.getItem('userInfo') || '{}';
    let userInfo = JSON.parse(tmpInfo);
    sessionStorage.setItem('orderInfo', JSON.stringify({id: id, type: type}))
    if(!userInfo.user) {
        message.warning('请先登录');
        that.props.history.push('/login');
        return
    }
    if(userInfo.user && userInfo.info && userInfo.user.UserType === '企业账户') {
        that.props.history.push('/confirm-list?id='+id+'&type='+type)
        return
    }
    if(userInfo.user && userInfo.user.UserType === '企业账户' && !userInfo.info) {
        message.warning('企业资料未完善，请先完善资料');
        that.props.history.push('/firm-replenish');
    }

}
export function setRequireLink(e, that,type) {
    e.stopPropagation();
    e.returnValue = false;
    let tmpInfo = sessionStorage.getItem('userInfo') || '{}';
    let userInfo = JSON.parse(tmpInfo);
    sessionStorage.setItem('orderInfo', JSON.stringify({type: type}))
     if(!userInfo.user) {
        message.warning('请先登录');
        that.props.history.push('/login');
        return
    }
    if(userInfo.user && userInfo.info && userInfo.user.UserType === '企业账户') {
        that.props.history.push('/add-requirements');
        return
    }
    if(userInfo.user && userInfo.user.UserType === '企业账户' && !userInfo.info) {
        message.warning('企业资料未完善，请先完善资料');
        that.props.history.push('/firm-replenish');
    }

}
export function setBtnStatus(){
    let tmpInfo = sessionStorage.getItem('userInfo') || '{}';
    let info = JSON.parse(tmpInfo);
    return !info.user || info.user.UserType === '企业账户'
}
