import React from 'react';
import './index.scss';
import {Breadcrumb, Tabs} from "antd";
import CompanyList from "./CompanyList";
import {Link} from "react-router-dom";
const {TabPane} = Tabs;
export default class CompanyServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey:  '1'
    }
  }
  changeTab=(key)=> {
    this.setState({
      activeKey: key
    })
  };
  render() {
    return (
      <div className="firm-register-container debt-finance">
        <div className={'breadcrumb detail-breadcrumb'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link className={'link'} to={'/profile'}>个人中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className={'link'} >企业服务</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="firm-register-content debt-content detail-breadcrumb-margin">
          <div className={'title'}>企业服务</div>
          <Tabs defaultActiveKey="1" className={'tab-box'} onChange={this.changeTab.bind(this)}>
            <TabPane tab={
              <p className="tab-item tab-item-1">待审核</p>
            } key="1">
              {this.state.activeKey === '1'? <CompanyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">审核通过</p>
            } key="2">
              {this.state.activeKey === '2'? <CompanyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">审核驳回</p>
            } key="3">
              {this.state.activeKey === '3'? <CompanyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">已发布</p>
            } key="4">
              {this.state.activeKey === '4'? <CompanyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">发布驳回</p>
            } key="5">
              {this.state.activeKey === '5'? <CompanyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">已下架</p>
            } key="6">
              {this.state.activeKey === '6'? <CompanyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
