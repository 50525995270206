import React from 'react';
import "./index.scss"
import {Tabs} from "antd";
import AgencyList from "./AgencyList";
const {TabPane} = Tabs;
export default class Agency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey:  '0'
    }
  }
  changeTab=(key)=> {
    this.setState({
      activeKey: key
    });
  };

  componentWillMount() {
    const props = this.props.props;
    const tab = props.location.search!==undefined? props.location.search:'';
    if (tab.indexOf('tab') > -1) {
      this.setState({activeTab: tab.substr(-1, 1)})
    }
  }
  render() {
    return (
      <div className="company-transaction">
        <div className={'title'}>我的交易</div>
        <div className={'company-manage'}>
          <Tabs defaultActiveKey="0" className={'tab-box'} onChange={this.changeTab.bind(this)}>
            <TabPane tab={
              <p className="tab-item tab-item-1">待分配</p>
            } key="0">
              {this.state.activeKey === '0'?  <AgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">待撮合</p>
            } key="1">
              {this.state.activeKey === '1'?  <AgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">撮合成功</p>
            } key="2">
              {this.state.activeKey === '2'?  <AgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">撮合失败</p>
            } key="3">
              {this.state.activeKey === '3'?  <AgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">已失效</p>
            } key="4">
              {this.state.activeKey === '4'?  <AgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">已关闭</p>
            } key="5">
              {this.state.activeKey === '5'?  <AgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
