import React from 'react';
import './index.scss'
import {getUserInfo} from "../../../lib/help/public";
import {Breadcrumb, Tabs} from "antd";
import {Link} from "react-router-dom";
import Data from "./AllData";
const {TabPane} = Tabs;
export default class Requirements extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsInfo: {},
            userInfo: {
                user: {}
            },
            activeKey:  '0'
        }
    }

    changeTab=(key)=> {
        this.setState({
            activeKey: key
        })
    };
    getUserInfo() {
        let tmpInfo = sessionStorage.getItem('userInfo');
        if(tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            this.setState({
                userInfo: tmpInfo
            })
        }
    }

    componentDidMount() {
        getUserInfo();
        this.getUserInfo();
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className={'firm-register-container requirement'}>
                <div className={'breadcrumb detail-breadcrumb'}>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link className={'link'} to={'/profile'}>个人中心</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a className={'link'}>我的融资需求</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="requirement-index-container">
                    <div className={'title'}>我的融资需求</div>
                    <div className={'company-manage'}>
                        <Tabs defaultActiveKey="0" className={'tab-box'} onChange={this.changeTab}>
                            <TabPane tab={
                                <p className="tab-item tab-item-1">全部</p>
                            } key="0">
                                {this.state.activeKey === '0'?  <Data activeKey={this.state.activeKey}/>: <div/>}
                            </TabPane>
                            <TabPane tab={
                                <p className="tab-item tab-item-2">已删除</p>
                            } key="1">
                                {this.state.activeKey === '1'?  <Data activeKey={this.state.activeKey}/>: <div/>}
                            </TabPane>
                        </Tabs>
                    </div>

                </div>
            </div>
        )
    }
}
