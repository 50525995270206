import React from 'react';
import {Link, withRouter} from "react-router-dom";
import http from "../../../lib/service/http";
import './index.scss';
import { Row, Col, Breadcrumb} from 'antd';
import {getImgPublicUrl, localDate,localTime} from "../../../lib/help/helper";
import iconUpload from "../../../resource/images/public/jigou_logo.svg";
 class EnterPriseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailList: {}
    }
  }
   getListData(params) {
     http.get(`home/enterprise-detail/${params}`).then(res => {
       this.setState({
         detailList: res.detail
       });
     })
   };
  componentWillMount () {
    const id = this.props.match.params.id;
    this.getListData(id)
  }
  render() {
    const {detailList} =  this.state;
    return (
      <div className="firm-register-container detail-container">
        <div className="firm-register-content content">
          <div className={'breadcrumb'}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link className={'link'} to={'/enterprise-financial'}>企业与机构</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detailList.Name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={'detail-info'}>
            <Row>
              <Col span={4} className={'img-box'}>
                {detailList.LogoFid ? (  <img src={getImgPublicUrl(detailList.LogoFid)} alt="" />): ( <img src={iconUpload} alt=""/>)}
              </Col>
              <Col span={20} className={'info-box'}>
                <div className={'title'}>{detailList.Name}</div>
                <div className={'info-text'}><span className={'diff-title'}>统一社会信用代码:</span><span className={'diff-info'}>{detailList.UnifiedSocialCreditCode}</span></div>
                <div className={'info-text'}><span className={'diff-title'}>企业类型:</span><span className={'diff-info'}>{detailList.Type}</span></div>
                <div className={'info-text info-line'}/>
                <div className={'info-text'}><span className={'diff-title'}>营业执照核准日期:</span><span className={'diff-info'}>{localTime(detailList.BusinessLicenseApprovalDate).split(' ')[0]}</span></div>
                <div className={'info-text'}><span className={'diff-title'}>企业地址:</span><span className={'diff-info'}>{detailList.Address}</span></div>
                <div className={'info-text'}><span className={'diff-title'}>注册资本:</span><span className={'diff-info'}>{detailList.RegisteredCapital}元整</span></div>
                <div className={'info-text'}><span className={'diff-title'}>成立日期:</span><span className={'diff-info'}>{localTime(detailList.FoundedDate).split(' ')[0]}</span></div>
                <div className={'info-text'}><span className={'diff-title'}>营业期限:</span><span className={'diff-info'}>{detailList.BusinessLicenseValidityStr}</span></div>
                <div className={'info-text'}><span className={'diff-title'}>经营范围:</span><span className={'diff-info diff-range'}>{detailList.BusinessScope}</span></div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(EnterPriseDetail)
