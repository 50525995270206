import React from 'react';
import './index.scss';
import {Col, Row} from "antd";
import iconPhone from "../../resource/images/index/icon-phone.svg";
import iconAddress from "../../resource/images/index/icon-address.svg";
import Footer from "./../Footer"
export default class OtherFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="">
        <div className="contact-us-container">
          <div className="contact-content content">
            <Row gutter={[0, 0]} className='number-info-box'>
              <Col className="gutter-row info-row " span={8} offset={1}>
                <div className="footer-item">
                  <p className="title">联系我们</p>
                  <p className="desc">
                    <img src={iconPhone} alt="" className="title-icon"/>
                    023-85233427
                  </p>
                  <p className="desc">
                    <img src={iconAddress} alt="" className="title-icon"/>
                    重庆市大足区棠香街道五星大道266号广电大厦22楼
                  </p>
                </div>
              </Col>
              <Col className="gutter-row info-row " span={14} offset={1}>
                <div className="footer-item">
                  <p className="title">友情链接</p>
                  <Row>
                    <Col span={8}>
                      <a href={'http://www.cq.gov.cn/'} target='_blank'  rel="noopener noreferrer" className="desc">重庆市人民政府</a>
                      <a href={'http://www.dazu.gov.cn/'} target='_blank' rel="noopener noreferrer" className="desc">大足区人民政府</a>
                      {/*<a href={'http://www.cqdbxh.org'} target='_blank' rel="noopener noreferrer" className="desc">重庆市融资担保协会</a>*/}
                    </Col>
                    <Col span={8}>
                      <a href={'http://chongqing.pbc.gov.cn/'} target='_blank' rel="noopener noreferrer" className="desc">人民银行重庆营业部</a>
                      <a href={'http://jrjgj.cq.gov.cn/'} target='_blank' rel="noopener noreferrer" className="desc">重庆市地方金融监管局</a>
                      <a href={'http://www.dazu.gov.cn/qzfbm/qczj/'} target='_blank' rel="noopener noreferrer" className="desc">大足区财政局</a>
                    </Col>
                    <Col span={8}>
                      <a href={'http://www.dazu.gov.cn/qzfbm/qnyncw/'} target='_blank' rel="noopener noreferrer" className="desc">大足区农业农村委</a>
                      <a href={'http://www.dazu.gov.cn/qzfbm/qjjxxw/'} target='_blank' rel="noopener noreferrer" className="desc">大足区经济信息委</a>
                      <a href={'http://www.dazu.gov.cn/qzfbm/qzstzj/'} target='_blank' rel="noopener noreferrer" className="desc">大足区招商投资局</a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}
