import React from 'react';
import {Button, Select, Checkbox, Form, Input, message, Modal} from "antd";
import {rules} from '../../../lib/help/rules';
import http from "../../../lib/service/http";
import Cookie from "js-cookie";
const {require_date,
    product_num,pactSecured,product_num_max,product_rate_max,
    product_rate,product_date_max,product_other,phone,is_select} = rules;
const { Option } = Select;
export default class Add extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            options: [
                {value:'信用',label: '信用'},
                {value:'质押',label: '质押'},
                {value:'抵押',label: '抵押'},
                {value:'保证',label: '保证'},
                {value:'其他',label: '其他'},
            ],
            SelectOptions: [
                {value:'LIQUIDITY',name: '流动资金'},
                {value:'FIXED',name: '固定资产'},
                {value:'FINANCING',name: '项目融资'},
                {value:'OTHER',name: '其他'}
            ],
            status: '',
            confirmPrice: [
                ...product_num,
                {
                    validator: this.handlePrice,
                    trigger: 'onBlur'
                }
            ],
            confirmRateMin: [
                ...product_rate,
                {
                    validator: this.handleRateMin,
                    trigger: 'onBlur'
                }
            ],
            confirmRateMax: [
                ...product_rate,
                {
                    validator: this.handleRateMax,
                    trigger: 'onBlur'
                },
            ],
            confirmDateMax: [
                ...product_date_max,
                {
                    validator: this.handleDateMax,
                    trigger: 'onBlur'
                }
            ],
            total: 0
        }
    }
    componentDidMount () {
       // this.getSecureMap();
        this.getTotal()
    };
    handlePrice =(_, value, callback) =>{
        let price = Number(this.formRef.current.getFieldValue('amount'));
        if (price>100000) {
            callback(new Error('请输入不大于100000的数字'))
        }  else {
            callback()
        }
    };
    handleRateMax =(_, value, callback) =>{
        let RateMin = Number(this.formRef.current.getFieldValue('rate_floor'));
        let RateMax = Number(this.formRef.current.getFieldValue('rate_ceil'));
        if (RateMax>0 && RateMin >= RateMax) {
            callback(new Error('请输入大于最小利率的数字'))
        } else if (Number(value) > 0&&!(Number(value) <100)) {
            callback(new Error('请输入小于100的数字'))
        } else if (Number(RateMin)>0&&RateMax > RateMin) {
            this.formRef.current.setFieldsValue({rate_floor:RateMin});
            callback()
        } else {
            callback()
        }
    };
    handleRateMin =(_, value, callback) =>{
        let RateMax = Number(this.formRef.current.getFieldValue('rate_ceil'));
        let RateMin = Number(this.formRef.current.getFieldValue('rate_floor'));
        if (RateMax>0 && RateMax <= RateMin) {
            callback(new Error('请输入小于最大利率的数字'))
        } else if (!(value <100)) {
            callback(new Error('请输入小于100的数字'));
        } else if (Number(RateMax)>0&&RateMax > RateMin) {
            this.formRef.current.setFieldsValue({rate_ceil:RateMax});
            callback()
        }else {
            callback()
        }
    };
    onChangeStatus = (e) => {
        this.setState({
            status: e
        })
    };
    getTotal=()=> {
        http.get('/enterprise/financing-list').then(res => {
            if (res) {
                this.setState({
                    total:res.data.total
                })
            }
        })
    };
    onFinish(values) {
        const that = this;
        if (this.state.total>=5) {
            Modal.warning({
                className: 'info-more',
                width: '416px',
                title: (
                    <div className={'disable-title'}>您正在发布第6条需求，超过最多发布数，请先删除后再次发布。</div>
                ),
                okText: '确定',
                onOk() {
                    that.props.history.push({
                        pathname:'/requirements'
                    })
                },
            });
        } else {
            values.secured_mode =values.secured_mode.join(',');
            values.cycle = Number(values.cycle);
            values.rate_ceil = String(values.rate_ceil);
            values.rate_floor = String(values.rate_floor);
            const that = this;
            Modal.confirm({
                className: 'info-disable',
                width: '416px',
                title: (
                    <div className={'disable-title'}>确定提交该融资需求吗?</div>
                ),
                okText: '确定',
                cancelText: '取消',
                onCancel() {},
                onOk() {
                    http.post('/enterprise/apply-financing', values).then(res => {
                        if (Object.keys(res).length===0) {
                            that.props.history.push({
                                pathname:'/requirements'
                            })
                        }
                    }, () => {
                    })
                },
            });
        }
    };
    onFinishFailed(errorInfo) {
    };
    handleCancel=()=> {
        window.history.back();
    };
    getSecureMap = ()=> {
        http.get('/financial/debt-product/secure_map').then(res => {
            let obj = {};
            let array = [];
            Object.keys(res.data).forEach((key) => {
                obj.label = res.data[key];
                obj.value = res.data[key];
                return array.push({label:res.data[key],value:res.data[key]})
            });
            this.setState({
                options: array
            });
        })
    };

    render() {
        let userInfo = sessionStorage.getItem('userInfo') || '{}';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
        }
        const {options,confirmPrice,confirmRateMax,confirmRateMin} = this.state;
        return (
                <div className="firm-register-container add-requirement">
                <p className="add-title">发布融资需求</p>
                <div className="add-form">
                    <Form
                        name="basic"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                        initialValues={{
                            contact: userInfo.user.Telephone,
                        }}
                    >
                        <div className={'price-range'}>
                            <Form.Item
                                label="期望融资金额"
                                name="amount"
                                rules={confirmPrice}
                                className={'item-height input-flex input-password'}
                                validateFirst={true}
                            >
                                <Input size="middle" placeholder={'请输入融资金额'} />
                            </Form.Item>
                            <div className={'unit-text'}>万元</div>
                        </div>
                        <div className={'price-range'}>
                            <Form.Item
                                label="期望年化利率"
                                name="rate_floor"
                                rules={confirmRateMin}
                                className={'input-rate input-flex input-password'}
                                validateFirst={true}
                            >
                                <Input size="middle" placeholder={'请输入最低年化利率'}/>
                            </Form.Item>
                            <div className={'unit-text'}>%</div>
                            <span className={'to-text'}>至</span>
                            <Form.Item
                                name="rate_ceil"
                                className={'input-rate input-flex'}
                                validateFirst={true}
                                rules={confirmRateMax}
                            >
                                <Input size="middle" placeholder={'请输入最高年化利率'}/>
                            </Form.Item>
                            <div className={'unit-text'}>%</div>
                        </div>
                        <div className={'price-range'}>
                            <Form.Item
                                label="期望期限"
                                name="cycle"
                                rules={require_date}
                                className={'item-height input-flex input-password'}
                                validateFirst={true}
                            >
                                <Input size="middle" placeholder={'请输入期限'} />
                            </Form.Item>
                            <div className={'unit-text'}>个月</div>
                        </div>
                        <Form.Item
                            rules={pactSecured}
                            label="担保方式"
                            name="secured_mode"
                            className={'item-height'}
                        >
                            <Checkbox.Group options={options}  />
                        </Form.Item>
                        <Form.Item
                            label="联系人电话"
                            name="contact"
                            className={'contact-way item-extra input-password'}
                            validateFirst={true}
                            rules={phone}
                        >
                            <Input size="middle" placeholder={'请输入联系方式'}/>
                        </Form.Item>
                        <Form.Item
                            label="融资用途"
                            name="financing_purpose"
                            rules={is_select}
                            className={'contact-way item-extra input-password'}
                            validateFirst={true}
                        >
                            <Select placeholder="请选择融资用途" className={'search-select'}  onChange={(e) =>this.onChangeStatus(e)}>
                                {
                                    this.state.SelectOptions.map((item) => {
                                        return <Option value={item.value} key={item.name}>{item.name}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item className="footer-btn">
                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                提交
                            </Button>
                            <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.handleCancel}>
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}
