import React from 'react';
import {Button, Form, Input, Tabs, message} from 'antd';
import {Link} from 'react-router-dom';
import './index.scss'
import http from './../../lib/service/http'
import Cookie from 'js-cookie';
import {rules} from "../../lib/help/rules";
const {account, password, img_code} = rules;


const {TabPane} = Tabs;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}

export default class Login extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            imageCode: '',
            uuid: '',
            user_type: 'ENTERPRISE'
        }
    }

    onFinish(values) {
        values.uuid = this.state.uuid;
        values.user_type = this.state.user_type;

        http.post('/user/login', values).then(res => {
            Cookie.set('token', res.token);
            this.getUserInfo();
            this.getRoute()
        }, () => {
            this.formRef.current.setFieldsValue({captcha: ''})
            this.getImgCode();
        })
    };

    getUserInfo() {
        http.get('/user/info').then(res => {
            sessionStorage.setItem('userInfo', JSON.stringify(res));
            let orderInfo = sessionStorage.getItem('orderInfo');
            if(orderInfo && res.user && res.user.UserType === '企业账户') {
                if(res.info && res.user) {
                    let tmpInfo = JSON.parse(orderInfo);
                    const {id, type} = tmpInfo
                    if (type==='require') {
                        this.props.history.replace('/add-requirements');
                    } else {
                        this.props.history.replace('/confirm-list?id='+id+'&type='+type)
                    }
                    return;
                }
                if(!(res.info)) {
                    message.warning('企业资料未完善，请先完善资料');
                    this.props.history.replace('/firm-replenish');
                    sessionStorage.removeItem('orderInfo');
                    return;
                }
            }
            this.props.history.replace('/')
        })
    };
    getRoute() {
        http.get('/user/permissions').then(res => {
            sessionStorage.setItem('routeInfo',  JSON.stringify(res.modules));
        })
    };
    callback= (key)=>{
        this.setState({
            user_type: key
        })
    }


    getImgCode = () => {
        http.get('/image-captcha/generate').then(res => {
            this.setState({
                imageCode: res.data.image,
                uuid: res.data.uuid
            })
        })
    }
    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
        this.getImgCode()
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="login-container">
                <div className="login-content">
                    <div className="login-left">
                        <p className="left-title">开放包容 助力发展</p>
                        <p className="left-desc">依托“智慧政府+金融科技”助力解决中小企业金融痛点</p>
                    </div>
                    <div className="login-right">
                        <p className="login-title">
                            登录
                        </p>
                        <p className="left-desc">
                            登录大足兴农信用金服，发现更大的世界
                        </p>
                        <Tabs defaultActiveKey="ENTERPRISE" className={'tab-box'} tabBarGutter={0} onChange={this.callback}>
                            <TabPane tab={
                                <p className={'tab-item'}>
                                    <svg className={'icon-tag'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M15.6 15.2H15.2V5.4C15.2 5.18 15.02 5 14.8 5H10.2V0.4C10.2 0.28 10.14 0.16 10.04 0.08C9.94 0 9.82 -0.02 9.7 0.02L1.1 2.42C0.92 2.46 0.8 2.62 0.8 2.8V15.2H0.4C0.18 15.2 0 15.38 0 15.6C0 15.82 0.18 16 0.4 16H15.6C15.82 16 16 15.82 16 15.6C16 15.38 15.82 15.2 15.6 15.2ZM1.6 3.1L9.4 0.92V12.8C9.4 13.02 9.58 13.2 9.8 13.2C10.02 13.2 10.2 13.02 10.2 12.8V5.8H14.4V15.2H1.6V3.1Z" fill="#2F3438"/>
                                        <path opacity="0.3" d="M7.00022 5H4.00022C3.78022 5 3.60022 5.18 3.60022 5.4C3.60022 5.62 3.78022 5.8 4.00022 5.8H7.00022C7.22022 5.8 7.40022 5.62 7.40022 5.4C7.40022 5.18 7.22022 5 7.00022 5ZM7.00022 7.8H4.00022C3.78022 7.8 3.60022 7.98 3.60022 8.2C3.60022 8.42 3.78022 8.6 4.00022 8.6H7.00022C7.22022 8.6 7.40022 8.42 7.40022 8.2C7.40022 7.98 7.22022 7.8 7.00022 7.8V7.8ZM7.00022 10.6H4.00022C3.78022 10.6 3.60022 10.78 3.60022 11C3.60022 11.22 3.78022 11.4 4.00022 11.4H7.00022C7.22022 11.4 7.40022 11.22 7.40022 11C7.40022 10.78 7.22022 10.6 7.00022 10.6ZM11.8002 8.6H12.8002C13.0202 8.6 13.2002 8.42 13.2002 8.2C13.2002 7.98 13.0202 7.8 12.8002 7.8H11.8002C11.5802 7.8 11.4002 7.98 11.4002 8.2C11.4002 8.42 11.5802 8.6 11.8002 8.6ZM12.8002 10.6H11.8002C11.5802 10.6 11.4002 10.78 11.4002 11C11.4002 11.22 11.5802 11.4 11.8002 11.4H12.8002C13.0202 11.4 13.2002 11.22 13.2002 11C13.2002 10.78 13.0202 10.6 12.8002 10.6Z" fill="#2F3438"/>
                                    </svg>
                                    企业登录
                                </p>
                            } key="ENTERPRISE">
                            </TabPane>
                            <TabPane tab={
                                <p className={'tab-item'}>
                                    <svg className={'icon-tag'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path opacity="0.3" d="M14 8.8607V7.9407H14.5794C15.1988 7.9407 15.7382 7.5407 15.938 6.9207C16.1378 6.2807 15.938 5.6207 15.3986 5.24071L8.24574 0.0807068C8.10588 -0.0192932 7.92606 -0.0192932 7.7862 0.0807068L0.613378 5.24071C0.0938984 5.6207 -0.125882 6.2807 0.0739184 6.9207C0.273718 7.5407 0.793198 7.9407 1.41258 7.9407H2.01198V15.2007H0.413578C0.193798 15.2007 0.0139784 15.3807 0.0139784 15.6007C0.0139784 15.8207 0.193798 16.0007 0.413578 16.0007H12.2018C14.2997 16.0007 15.998 14.3007 15.998 12.2007C15.998 10.7607 15.1788 9.5007 14 8.8607ZM13.2008 8.5407C12.8811 8.4607 12.5414 8.4007 12.2018 8.4007C11.3826 8.4007 10.6234 8.6607 10.004 9.1007V7.9407H13.2008V8.5407ZM9.20478 14.5207V15.2007H6.80718V7.9407H9.20478V9.8807C8.70528 10.5207 8.40558 11.3207 8.40558 12.2007C8.40558 13.0807 8.70528 13.8807 9.20478 14.5207ZM0.853138 6.6807C0.773218 6.4407 0.793198 6.10071 1.0929 5.9007L8.00598 0.900707L14.9191 5.9007C15.2188 6.12071 15.2387 6.4407 15.1588 6.7007C15.0789 6.9207 14.8991 7.1607 14.5594 7.1607H13.7003C13.6603 7.1407 13.6204 7.1407 13.5804 7.1407H9.60438C9.56442 7.1407 9.52446 7.1407 9.4845 7.1607H6.52746C6.4875 7.1407 6.44754 7.1407 6.40758 7.1407H2.41158C2.37162 7.1407 2.33166 7.1407 2.2917 7.1607H1.43256C1.03296 7.1407 0.893098 6.7807 0.853138 6.6807ZM2.81118 15.2007V7.9407H6.00798V15.2007H2.81118ZM12.2018 15.2007C11.3027 15.2007 10.4835 14.8007 9.94404 14.1607C9.92406 14.1407 9.90408 14.1007 9.8841 14.0807C9.46452 13.5807 9.20478 12.9207 9.20478 12.2007C9.20478 10.5407 10.5434 9.2007 12.2018 9.2007C13.8601 9.2007 15.1988 10.5407 15.1988 12.2007C15.1988 13.8607 13.8601 15.2007 12.2018 15.2007Z" fill="#2F3438"/>
                                            <path opacity="0.3" d="M12.4802 10.4999C12.3204 10.3399 12.0806 10.3399 11.9208 10.4999L10.5022 11.9199C10.3424 12.0799 10.3424 12.3199 10.5022 12.4799L11.9208 13.8999C12.0007 13.9799 12.1006 14.0199 12.2005 14.0199C12.3004 14.0199 12.4003 13.9799 12.4802 13.8999L13.8988 12.4799C14.0586 12.3199 14.0586 12.0799 13.8988 11.9199L12.4802 10.4999ZM12.2005 13.0399L11.3613 12.1999L12.2005 11.3599L13.0397 12.1999L12.2005 13.0399Z" fill="#2F3438"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    机构登录
                                </p>
                            } key="FINANCIAL">
                            </TabPane>
                        </Tabs>
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            ref={this.formRef}
                            onFinish={(values) => this.onFinish(values)}
                            onFinishFailed={() => this.onFinishFailed()}
                        >
                            <Form.Item
                                label=""
                                name="username"
                                rules={account}
                            >
                                <Input size="large" placeholder={'请输入用户名'}/>
                            </Form.Item>

                            <Form.Item
                                label=""
                                name="password"
                                rules={password}
                            >
                                <Input.Password size="large" placeholder={'请输入密码'}/>
                            </Form.Item>

                            <Form.Item
                                label=""
                                name="captcha"
                                rules={img_code}
                                className="number-code"
                            >
                                <Input
                                    suffix={<img src={'data:image/jpeg;base64,' + this.state.imageCode}
                                                 onClick={this.getImgCode} alt=""
                                                 className='img-code'/>}
                                    size="large" placeholder={'请输入图片识别码'}/>
                            </Form.Item>

                            <Form.Item {...tailLayout} className="submit-btn-box">
                                <Button className={'submit-btn'} type="primary" htmlType="submit">
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className="register-box">
                            <Link to={'/retrieve-password'} className={'text-link'}>忘记密码</Link>
                            <Link to={'/register-type'} className={'text-link'}>注册账号</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
