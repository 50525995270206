import React from 'react';
import {Table, Space, Modal, Row, Col, Pagination} from "antd";
import Add from './Modal/AddRole'
import closeImg from "../../../../resource/images/profile/cancle.svg";
import http from "../../../../lib/service/http";
import {localTime} from "../../../../lib/help/helper";
import NoData from "../../../NoData";
export default class UserPage extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleRole: false,
      lookRole: false,
      total: 0,
      currentPage: 1,
      list: [],
      roleEditData: {},
      roleType: [
        {
          name: 1,
          key: 1,
          value:1
        }
      ],
      roleData: [],
      columns: [
        {
          title: '角色名',
          dataIndex: 'RoleName',
          key: 'RoleName',
        },
        {
          title: '添加时间',
          key: 'CreatedAt',
          render: (row) => {
            return (localTime(row.CreatedAt))
          }
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <Space size="middle" className={'operate-btn'}>
              <a  onClick={this.look.bind(this, record)}>查看权限</a>
              <a  onClick={this.edit.bind(this, record)}>编辑权限</a>
              <a onClick={this.delete.bind(this, record)}>删除角色</a>
            </Space>
          ),
        },
      ]
    }
  }
  addUser=() => {
    this.setState({
      visible: true,
    });
    this.setState({
      roleEditData: {}
    });
  };
  look =(record) => {
    http.get('/financial/role-detail',{role_name:record.RoleName}).then(res => {
      this.setState({
        roleData: res.data
      })
    });
    this.setState({
      lookRole: true
    })
  };
  cancelLook = () => {
    this.setState({
      lookRole: false
    })
  };
  delete = (record) => {
    const that = this;
    Modal.confirm({
      className: 'info-disable',
      width: '416px',
      title: (
        <div className={'disable-title'}>确定删除该角色吗?</div>
      ),
      content:(
        <div className={'disable-text'}>删除后将无法撤回</div>
      ),
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
      onOk() {
        http.post('/financial/delete-role', {role_name:record.RoleName}).then(res => {
          if (Object.keys(res).length===0) {
            that.getList({page: that.state.currentPage})
          }
        }, () => {
        })
      },
    });
  };
  edit = (record) => {
    this.setState({
      roleEditData: record
    });
    this.setState({
      visible: true
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  closeModal = () => {
    this.setState({
      visible: false
    });
    this.getList({page: this.state.currentPage})
  };
  getList = (params) => {
    http.get('/financial/role-list',{page:params.page}).then(res => {
      this.setState({
        list: res.data.items,
        total: res.data.total
      })
    })
  };
  componentDidMount () {
    this.getList({page:1});
  }
  componentDidUpdate(prevProps,prevState){
    if(this.props.activeKey === '2' && (prevProps.activeKey !== this.props.activeKey)) {
      this.getList({page:1});
      this.setState({
        currentPage: 1
      })
    }
  }
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params = {
      page:page,
    };
    this.getList({page:page})
  };
  render() {
    const { visible,list,lookRole,roleData,total} = this.state;
    return (
      <div className="firm-register-container user-manage">
        <div className="firm-register-content user-content">
          <div className="add-btn" onClick={() => this.addUser()}>新增角色</div>
          <Modal title={Object.keys(this.state.roleEditData).length===0? '新增角色':'编辑权限'}
                 visible={visible}
                 closeIcon={<img src={closeImg} alt=""/>}
                 onCancel={this.handleCancel}
                 onOk={this.handleCancel}
                 okText={'确定'}
                 footer={null}
                 width={640}
                 wrapClassName='modal-add-user'
                 destroyOnClose={true}
          >
            <Add close={this.closeModal} roleEditData={this.state.roleEditData}/>
          </Modal>

          <Modal
            title={'查看角色'}
            visible={lookRole}
            closeIcon={<img src={closeImg} alt=""/>}
            onCancel={this.cancelLook}
            footer={null}
            width={640}
            wrapClassName='modal-edit-user'
            destroyOnClose={true}
          >
           <Row className={'auth-scope'}>
             <Col span={4}>权限范围</Col>
             <Col span={20} className={'role-auth'}>
               {
                 roleData.map((item) => {
                   return <div className={'role-name'} key={item}>{item}</div>
                 })
               }
             </Col>
           </Row>
          </Modal>
          {list&&list.length>0?(<div className={'table-data'}>
            <Table columns={this.state.columns} dataSource={list} pagination={false} rowKey='ID'/>
              <div className={'page-total-data'}>
                <div >
                  共计 <span className={'total-num'}>{total}</span> 条数据
                </div>
                <Pagination
                  className={'pageStyle'}
                  onChange={this.onChangePage}
                  defaultCurrent={1}
                  total={total}
                  pageSize={10}
                  showSizeChanger={false}
                  current={this.state.currentPage}
                />
              </div>
            </div>
            ):(<div className="news-list-content">
            <NoData />
          </div>)}
        </div>
      </div>
    )
  }
}

