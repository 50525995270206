import React from 'react';
import {Link, withRouter} from "react-router-dom";
import http from "../../../lib/service/http";
import './index.scss';
import {
  Row,
  Col,
  Breadcrumb
} from 'antd';
import {getImgPublicUrl} from "../../../lib/help/helper";
import iconUpload from "../../../resource/images/public/jigou_logo.svg";
import website from "../../../resource/images/public/website.svg"
class FinancialDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailList: {}
    }
  }
  getListData(params) {
    http.get(`home/financial-detail/${params}`).then(res => {
      this.setState({
        detailList: res.detail
      });
    })
  };
  componentWillMount () {
    const id = this.props.match.params.id;
    this.getListData(id)
  }
  render() {
    const {detailList} =  this.state;
    return (
      <div className="firm-register-container detail-container">
        <div className="firm-register-content content">
          <div className={'breadcrumb'}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link className={'link'} to={'/enterprise-financial'}>企业与机构</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detailList.Name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={'detail-info'}>
            <Row>
              <Col span={4} className={'img-box'}>
                {detailList.LogoFid ? (  <img src={getImgPublicUrl(detailList.LogoFid)} alt="" />): ( <img src={iconUpload} alt=""/>)}
              </Col>
              <Col span={20} className={'info-box'}>
                <div className={'name'}>{detailList.Name}</div>
                <div className={'website'}><img src={website} className={'website-img'} alt=""/>{detailList.Website}</div>
                <div className={'info-text info-line'}/>
                <div className={'info-text main-business'}><span className={'diff-title'}><span className={'business-title'}>主营业务:</span></span><span className={'diff-range'}>{detailList.BusinessScope}</span></div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(FinancialDetail)
