import React from 'react';
import './index.scss';
import {Tabs} from 'antd';
import Enterprise from './Enterprise'
import Financial from './Financial'
import banner from "../../resource/images/public/financial-banner.svg"
const {TabPane} = Tabs;
export default class EnterpriseFinancial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    }
  }
  callback=(key)=> {
    this.setState({
      activeKey: key
    })
  };
  render() {
    return (
        <div className="firm-register-container enterprise-financial-container">
          <div className={'financial-banner'}>
            <img src={banner} alt=""/>
          </div>
          <div className="firm-register-content content">
            <Tabs defaultActiveKey="1" className={'tab-box'} tabBarGutter={48}  onChange={this.callback}>
              <TabPane tab={
                <p className="tab-item tab-item-1">企业列表</p>
              } key="1">
                <Enterprise activeKey={this.state.activeKey}/>
              </TabPane>
              <TabPane tab={
                <p className="tab-item tab-item-2">机构列表</p>
              } key="2">
                <Financial activeKey={this.state.activeKey}/>
              </TabPane>
            </Tabs>
          </div>
        </div>
    )
  }
}
