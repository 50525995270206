import React from 'react';
import './index.scss'

export default class RegisterAgreement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="register-agreement-container">
                <div className="content register-agreement-content">
                    <p className="agreement-title">
                        天下大足智慧金融服务平台用户注册协议
                    </p>
                    <p className="item-title">第一条  目的意义</p>
                    <p className="item-desc">
                        为保证天下大足智慧金融服务平台用户信息的真实性、合法性和有效性，用户注册、验证应采用实名制。在您成为大足智慧金融服务平台实名注册用户（简称“注册用户”）之前，请确认您已充分阅读、理解并接受本协议的全部内容，同意遵循本协议的所有约定。
                    </p>
                    <p className="item-title">第二条  协议适用主体范围</p>
                    <p className="item-desc">
                        大足智慧金融服务平台的运营管理权归重庆市大足区兴农融资担保有限责任公司所有。本协议是您和重庆市大足区兴农融资担保有限责任公司（简称“兴农担保公司”），明确双方通过天下大足智慧金融服务平台办理服务事项过程中的权利义务所订立的协议。
                    </p>
                    <p className="item-title">第三条  服务内容</p>
                    <p className="item-desc">
                        注册用户能够访问天下大足智慧金融服务平台网站、检索静态信息、进行在线咨询、申办服务事项，可在专属用户空间查询所办事项的过程信息和历史信息，储存信息资料，并自行维护用户空间信息，并对服务事项的经办人进行分级分类授权管理。
                    </p>
                    <p className="item-title">第四条  用户承诺与授权</p>
                    <p className="item-desc">
                        注册用户在天下大足智慧金融服务平台进行注册和使用本政务服务平台时承诺并确认：<br/>
                        （1）提供及时、详尽及准确的个人或法人资料；<br/>
                        （2）使用部分工商信息进行验证；<br/>
                        （3）及时更新注册资料；<br/>
                        （4）同意接收兴农担保公司推送的信息；<br/>
                        （5）在提请服务事项办理过程中，按服务内容要求提供真实、准确的要件材料，并及时对上传本平台的要件材料做好更新维护；<br/>
                        （6）不存放与政务服务申办无关的信息内容。<br/>
                    </p>
                    <p className="item-title">第五条  用户信息保障</p>
                    <p className="item-desc">
                        兴农担保公司依法保护个人隐私，不得违法违规向与服务无关的第三方提供用户注册资料。
                    </p>
                    <p className="item-title">第六条  用户的密码和安全性</p>
                    <p className="item-desc">
                        考虑到注册用户是特定的行为相对人，以其账号操作的行为由注册自然人或法人承担相应法律责任。注册用户应妥善保管密码，不得将账号、密码提供给他人使用。建议定期修改密码，以确保账号安全。
                    </p>
                    <p className="item-title">第七条  禁止条款</p>
                    <p className="item-desc">
                        注册用户有违反法律法规行为的，兴农担保公司有权终止为用户提供服务，并保留追责的权利。
                    </p>
                    <p className="item-title">第八条  注意事项</p>
                    <p className="item-desc">
                        兴农担保公司将加强网络安全管理和风险防控，因技术原因和网络中断等因素，可能会造成注册用户的实时操作无效或暂停，请注意备份相关资料。
                    </p>
                    <p className="item-title">第九条  其他规定</p>
                    <p className="item-desc">
                        兴农担保公司后期依照本服务条款及相关法律法规发布的补充规则，均为本协议不可分割的组成部分，原则上不再另行签约。
                    </p>
                </div>
            </div>
        )
    }
}