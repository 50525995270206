import React from 'react';
import {Row, Col, Modal, Form, Button, Input, Breadcrumb, message} from "antd";
import http from "../../../../../lib/service/http";
import {getImgPublicUrl, localTime} from '../../../../../lib/help/helper'
import closeImg from "../../../../../resource/images/profile/cancle.svg";
import {rules} from '../../../../../lib/help/rules'
import {Link} from "react-router-dom";
import imgQiye from "../../../../../resource/images/public/img_qiyefuwu.svg";
const {not_null} = rules;
const { TextArea } = Input;
export default class CompanyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '发布驳回',
      detailList: {},
      visible: false
    }
  }
  getListData(params) {
    http.get(`/financial/enterprise-service/detail/${params.id}`).then(res => {
      this.setState({
        detailList: res.detail,
        status: res.detail.Status
      });
    });
  };
  componentWillMount () {
    let params = {
      id:this.props.match.params.id,
    };
    this.getListData(params);
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  pass=() => {
    let  params = {
      product_service_id: Number(this.props.match.params.id),
      audit_status: 'REVIEW-PASS',
      reject_reason: '',
    };
    const that =this;
    http.post('/financial/enterprise-service/audit', params).then(res => {
      message.success({
        className: '',
        content: '审核成功',
        duration:2,
        onCancel() {
          that.props.history.push('/user-info/company-service');
        },
      });
      that.props.history.push('/user-info/company-services');
    }, () => {

    })
  };
  reject=()=> {
    this.setState({
      visible: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  onFinish =(values) =>{
    const that =this;
    let  params = {
      product_service_id: Number(this.props.match.params.id),
      audit_status: 'REVIEW-REJECTED',
      reject_reason: values.reject_reason,
    };
    http.post('/financial/enterprise-service/audit', params).then(res => {
      message.success({
        className: '',
        content: '审核成功',
        duration:2,
        onCancel() {
          that.props.history.push('/user-info/company-service');
        },
      });
      that.props.history.push('/user-info/company-services');
    }, () => {

    });
    this.setState({
      visible: false,
    });
  };
  onFinishFailed(errorInfo) {
  };
  render() {
    const {detailList,visible}=this.state;
    const isEmpty = Object.keys(detailList).length === 0;

    return (
      <div className="firm-register-container breadcrumb-content debt-finance">
        <div className={'breadcrumb detail-breadcrumb'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link className={'link'} to={'/profile'}>个人中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link className={'link'} to={'/user-info/company-services'}>企业服务</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className={'link'}>服务详情</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="firm-register-content debt-content debt-detail detail-breadcrumb-margin">
          <div className={'title'}>服务详情</div>
          <Modal title={'驳回产品'}
                 visible={visible}
                 closeIcon={<img src={closeImg} alt=""/>}
                 onCancel={this.handleCancel}
                 onOk={this.handleCancel}
                 okText={'确定'}
                 footer={null}
                 width={640}
                 wrapClassName='modal-reject'
                 destroyOnClose={true}
          >
            <Form
              name="basic"
              ref={this.formRef}
              onFinish={(values) => this.onFinish(values)}
              onFinishFailed={() => this.onFinishFailed()}>
              <Form.Item
                label="驳回原因"
                name="reject_reason"
                rules={not_null}
                validateFirst={true}
              >
                <TextArea size="middle" placeholder={'请输入驳回原因'}/>
              </Form.Item>
              <Form.Item className="footer-btn">
                <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.handleCancel}>
                  取消
                </Button>
                <Button className={'submit-btn'} type="primary" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <div className={'debt-detail-content'}>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>产品名称</Col>
              <Col span={21} className={'name-text'}>{detailList.Name}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>类型</Col>
              <Col span={21} className={'name-text'}>{detailList.ProductTypeStr}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>图片</Col>
              <Col span={21} className={'name-text name-img'}>{detailList.ImageFid?<img src={getImgPublicUrl(detailList.ImageFid)} alt="" />:<img src={imgQiye} alt="" />}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>价格区间</Col>
              <Col span={21} className={'name-text'}>{detailList.PriceFloor}-{detailList.PriceCeil}万元</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>联系方式</Col>
              <Col span={21} className={'name-text'}>{detailList.Contact}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>项目介绍</Col>
              <Col span={21} className={'name-text name-text-other'}>{detailList.Description}</Col>
            </Row>
          </div>
        </div>
        <div className={'debt-row'}>
          {this.state.status === 'PENDING-REVIEW'? (
            <Row>
              <Col span={3} className={'name-title'}/>
              <Col span={21} className={'name-text'}>
                <div className={'btn-operate'}>
                  <span className="btn btn-pass" onClick={() => this.pass()}>通过</span>
                  <span className="btn btn-reject" onClick={() => this.reject()}>驳回</span>
                </div>
              </Col>
            </Row>
          ):(this.state.status === 'REVIEW-REJECTED'?(
            <div>
              <Row className={'debt-row debt-other-line'}>
                <Col span={3} className={'name-title'}>审核状态</Col>
                <Col span={21} className={'name-text name-text-other'}>审核驳回</Col>
              </Row>
              <Row className={'debt-row'}>
                <Col span={3} className={'name-title'}>审核时间</Col>
                <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>
              </Row>
              {/*<Row className={'debt-row'}>*/}
              {/*  <Col span={3} className={'name-title'}>发布驳回时间</Col>*/}
              {/*  <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>*/}
              {/*</Row>*/}
              <Row className={'debt-row'}>
                <Col span={3} className={'name-title'}>驳回原因</Col>
                <Col span={21} className={'name-text name-text-other'}>{detailList.RejectReason}</Col>
              </Row>
            </div>
          ):(this.state.status === 'PUBLISHED'?(
            <div>
              <Row className={'debt-row debt-other-line'}>
                <Col span={3} className={'name-title'}>审核状态</Col>
                <Col span={21} className={'name-text name-text-other'}>已发布</Col>
              </Row>
              <Row className={'debt-row'}>
                <Col span={3} className={'name-title'}>审核时间</Col>
                <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>
              </Row>
              <Row className={'debt-row'}>
                <Col span={3} className={'name-title'}>发布时间</Col>
                <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>
              </Row>
            </div>
          ):(this.state.status === 'PUBLISH-REJECTED'?(
            <div>
              <Row className={'debt-row debt-other-line'}>
                <Col span={3} className={'name-title'}>审核状态</Col>
                <Col span={21} className={'name-text name-text-other'}>发布驳回</Col>
              </Row>
              <Row className={'debt-row'}>
                <Col span={3} className={'name-title'}>审核时间</Col>
                <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>
              </Row>
              <Row className={'debt-row '}>
                <Col span={3} className={'name-title'}>发布驳回时间</Col>
                <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>
              </Row>
              <Row className={'debt-row '}>
                <Col span={3} className={'name-title'}>发布驳回原因</Col>
                {/*未展示*/}
                <Col span={21} className={'name-text name-text-other'}>{detailList.AdminRejectReason&&detailList.AdminRejectReason.length>0? detailList.AdminRejectReason:'--'}</Col>
              </Row>
            </div>
          ):(this.state.status === 'SHELVED'?(
            <div>
              <Row className={'debt-row debt-other-line'}>
                <Col span={3} className={'name-title'}>审核状态</Col>
                <Col span={21} className={'name-text name-text-other'}>已下架</Col>
              </Row>
              <Row className={'debt-row'}>
                <Col span={3} className={'name-title'}>下架时间</Col>
                <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>
              </Row>
              {/*<Row className={'debt-row'}>*/}
              {/*  <Col span={3} className={'name-title'}>发布时间</Col>*/}
              {/*  <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>*/}
              {/*</Row>*/}
            </div>):(<div>
            <Row className={'debt-row debt-other-line'}>
              <Col span={3} className={'name-title'}>审核状态</Col>
              <Col span={21} className={'name-text name-text-other'}>{isEmpty?'--':'审核通过'}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>审核时间</Col>
              <Col span={21} className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>
            </Row>
          </div>)))))}
        </div>
      </div>
    )
  }
}
