import React from 'react';
import './index.scss';
import  { Form, Input, Row, Col,  Select,  Pagination,} from 'antd';
import {regexps_num,regexps_date,regexps_rate} from '../../lib/help/validates'
import CorporateInfo from "../CorporateInfo";
import BusinessInfo from "../BusinessInfo";
import InfoSupplementTip from "../InfoSupplementTip";
import iconUpload from "../../resource/images/public/img_qiyefuwu.svg";
import vector from "../../resource/images/public/vector.svg"
import http from "../../lib/service/http";
import {withRouter} from "react-router-dom";
import {getImgPublicUrl} from "../../lib/help/helper";
import NoData from "../NoData";
import { setConfirmLink} from "../../lib/help/public";

const { Option } = Select;

 class CorporateServices extends React.Component {
  formRef = React.createRef();
  formRefName = React.createRef();
  formRefDiff = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      total: 16,
      currentPage: 1,
      totalPage: 100,
      valuePrice:null,
      productName: '',
      finName: '',
      firstCategoryList : [],
      firstCategoryId : '',
      secondCategoryList : [],
      secondCategoryId : '',
      userInfo: {},
      productInfo: [
      ],
      validates: {
        price: [
          {
            pattern: regexps_num,
            message: '请输入大于等于0的数字',
          }
        ],
        date: [
          {
            pattern: regexps_date,
            message: '请输入正确月份',
          }
        ],
        rate: [
          {
            pattern: regexps_rate,
            message: '请输入大于0的数字',
          }
        ]
      },
    }
  }
  onFirstCategoryChange =(e)=>{
    this.setState({
      firstCategoryId: e,
      secondCategoryList : [],
      secondCategoryId : '',
    });
    const params = {
      id: e
    };
   this.getSecondCategory(params)
  };
  //二级品类改变事件
  onSecondCategoryChange=(e)=>{
    this.setState({
      secondCategoryId: e
    });
    //取一级品类的值，没有的话为0
    // let newValue=e.target.value || 0;
    // this.setState({
    //   secondCategoryId : newValue,
    // },() => {
    // })
  };
  getRate = () => {
  };
  getDeadLine = ()=> {
  };
  getPrice = () => {
  };
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    const type_id = (this.state.secondCategoryId!==undefined)&&(this.state.secondCategoryId !== '')? this.state.secondCategoryId : this.state.firstCategoryId;
    const params = {
      page:page,
      name: this.state.productName,
      fin_name: this.state.finName,
      type_id: type_id
    };
    this.getListData(params)
  };
  getListData(params) {
    let tmp = {
      fin_name:params.fin_name,
      name:params.name,
      type_id:params.type_id,
      page:params.page,
    };
    http.get('/home/enterprise-service-list',tmp).then(res => {
      this.setState({
        productInfo: res.items,
        totalPage: res.total_page,
        total: res.total
      });
    })
  };
   getProductType(params){
    http.post('/product-type', params).then(res => {
      this.setState({
        firstCategoryList:res,
      });
    }, () => {
    })
 };
   getSecondCategory(params){
     http.post('/product-type', params).then(res => {
       this.setState({
         secondCategoryList:res,
       });
     }, () => {
     })
   };
  componentWillMount() {
    let params = {
      fin_name: '',
      name: '',
      page: 1,
      type_id: ''
    };
    this.getListData(params);
    this.getProductType({});
  };
   componentDidUpdate(prevProps,prevState){
     if(this.props.activeKey === '2' && (prevProps.activeKey !== this.props.activeKey)) {
       let params = {
         fin_name: '',
         name: '',
         page: 1,
         type_id: ''
       };
       this.getListData(params);
       this.getProductType({});
      this.reset();
       this.setState({
         currentPage: 1
       })
     }
   }
  onChangePrice = value => {
    this.setState({
      productName: value.target.value,
    });
  };
   onChangeName = value => {
     this.setState({
       finName: value.target.value,
     });
   };
  goDetail = (item)=> {
    this.props.history.push({pathname:'/corporate-detail/'+item.ID});
  };
   search = () => {
    const type_id = (this.state.secondCategoryId!==undefined)&&(this.state.secondCategoryId !== '')? this.state.secondCategoryId : this.state.firstCategoryId;
     const params = {
       page:'',
       name: this.state.productName,
       fin_name: this.state.finName,
       type_id: type_id
     };
     this.getListData(params);
     this.setState({
       currentPage: 1,
     });
   };
   reset = () => {
     this.formRef.current.resetFields();
     this.formRefName.current.resetFields();
     this.formRefDiff.current.resetFields();
     this.setState({
       secondCategoryList: [],
        currentPage: 1
     })
     this.setState({
       productName: '',
       finName: '',
       secondCategoryId: '',
       firstCategoryId: ''
     });
     let params = {
       name: '',
       page: 1,
       type_id: '',
       fin_name: '',
     };
     this.getListData(params)
   };
  render() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    const {total,productInfo} =  this.state;
    return (
      <div className="range-content">
        <div className="debt">
          <div className={'debtRow'}>
            <div className="item-market">
              <Form
                className="range"
                name="basic"
                ref={this.formRef}
              >
                <div className={'item-title'}>项目名称:</div>
                <Form.Item className=" item-select" name='priceMax' style={{width: '224px'}}>
                  <Input
                    className={'term-name'} placeholder={'请输入项目名称'}
                    onChange={this.onChangePrice.bind(this)}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={'debtRow'}>
            <div className="item-market">
              <Form
                className="range"
                name="basic"
                ref={this.formRefName}
              >
                <div className={'item-title'}>发布机构:</div>
                <Form.Item className="item-select"  name="dateMax" style={{width: '224px'}}>
                  <Input
                    className={'term-name'} placeholder={'请输入发布机构'}
                    onChange={this.onChangeName.bind(this)}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={'debtRow'}>
            <div className="item-market">
              <Form
                className="range"
                name="basic"
                ref={this.formRefDiff}
              >
                <div className={'item-title'}>类别:</div>
                <Form.Item className="item-select" name="rateMin">
                  <Select name="" onChange={(e) => this.onFirstCategoryChange(e)} className="form-control cate-select" placeholder={'请选择类别'}>
                    {/*<Option value="">请选择一级分类</Option>*/}
                    {
                      this.state.firstCategoryList.map(
                        (category, index) => <Option value={category.ID} key={category.ID}>{category.Name}</Option>)
                    }
                  </Select>
                  { this.state.secondCategoryList.length ?
                    <Select name="" onChange={(e) => this.onSecondCategoryChange(e)} className="form-control cate-select" placeholder={'请选择详细类别'} allowClear>
                      {/*<Option value="">请选择二级分类</Option>*/}
                      {
                        this.state.secondCategoryList.map(
                          (category, index)=> <Option value={category.ID} key={category.ID}>{category.Name}</Option>
                        )
                      }
                    </Select> : null
                  }
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={'search-btn'}>
              <span className="detail-btn" onClick={() => this.search()}>筛选</span>
              <span className="detail-btn" onClick={() => this.reset()}>重置</span>
          </div>
        </div>
        <div className="total-data">
          共计
          <span className="total-num">{total}</span>
          项服务
        </div>
        {productInfo && productInfo.length > 0 ? (
          <div className="">
            {productInfo.map(item => (
              <Row key={item.name} title={item.title}  className=" table-data product-info">
                <Col span={4} className="img-detail">
                  {item.ImageFid ? ( <img src={getImgPublicUrl(item.ImageFid)} alt="" />): ( <img src={iconUpload} alt=""/>)}
                </Col>
                <Col span={15} className="detail">
                  <div className="title">
                    {item.Name}
                  </div>
                  <div className="company-name"> <img src={vector} className={'vector-img'} alt=""/>{item.FinName}</div>
                  <div className={'price-info'}>
                    <Row>
                      <Col span={12}>
                        <div className={'price-range'}>
                          <span className={'title-diff'}>价格区间</span>
                          <span className={'title-num title-rate'}>{item.PriceFloor}-{item.PriceCeil}万元</span>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className={'service-type'}>
                          <span className={'title-diff'}>服务类别</span>
                          <span className={'title-num'}>{item.ProductTypeStr}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={4} className="btn">
                  <p className="look-detail" onClick={this.goDetail.bind(this, item)}>查看详情</p>
                  {(!userInfo.user|| userInfo.user&&userInfo.user.UserType === '企业账户')&& <p onClick={(event)=>setConfirmLink(event, this, item.ID, 'company')} className={'apply-btn detail-btn'}>立即申请</p>}
                </Col>
              </Row>
            ))}
            <Pagination
              className={'pageStyle'}
              onChange={this.onChangePage}
              defaultCurrent={1}
              total={total}
              pageSize={10}
              showSizeChanger={false}
              current={this.state.currentPage}
            />
            </div>
        ) :  (<div className="news-list-content">
          <NoData />
        </div>)}
      </div>
    )
  }
}
export default withRouter(CorporateServices)
