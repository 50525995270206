import React from 'react';
import {Link} from 'react-router-dom';
import './index.scss'
import imgSuccess from '../../resource/images/public/img-success-tip.svg'


export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '企业',
            link: '/firm-replenish',
            type: '',
        }
    }


    componentDidMount() {
        if (this.props.match.params.type === 'agency') {
            this.setState({
                title: '机构',
                link: '/agency-replenish'
            })
        }else if(this.props.match.params.type === 'confirm') {
            this.setState({
                title: '您的申请已提交',
                link: '/transaction',
                type: 'confirm'
            })
        }
    }

    componentWillUnmount() {
    }

    render() {
        const {title, link, type} = this.state;

        return (
            <div className={type === 'confirm' ? 'fulfil-tip-container type-confirm' : 'fulfil-tip-container'}>
                <div className="fulfil-tip-content content">
                    <img src={imgSuccess} alt="" className="img-tip"/>
                    {
                        type === 'confirm' ?
                            <p className="title">{title}</p> :
                            <p className="title">{title}账号注册成功</p>
                    }
                    {
                        type === 'confirm' ?
                            <p className="desc confirm-desc">
                                1.金融机构会尽快受理，大约需要3个工作日；<br/>
                                2.交易对接成功后，金融机构生成交易确认清单，请您尽快确认交易信息。
                            </p> :
                            <p className="desc">
                                尊敬的{title}用户，根据平台要求，请您完善{title}的相关资料后<br/>
                                即可体验平台全部服务
                            </p>
                    }
                    <div className="link-box">
                        {
                            type === 'confirm' ?
                                <Link className="set-info" to={link}>查看订单</Link> :
                                <Link className="set-info" to={link}>立即补充</Link>
                        }
                        <Link className="back-btn" to={'/'}>返回首页</Link>
                    </div>
                </div>
            </div>
        )
    }
}