import './App.less';
import './resource/styles/public.scss'
import './resource/styles/loading.scss'
import 'wangeditor/dist/wangEditor.min'
import {Layout} from 'antd';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import React from 'react';
import {withRouter} from "react-router-dom";


import AuthorHeader from './common/AuthorHeader'
import IndexHeader from './common/IndexHeader'
import PublicFooter from './common/Footer'
import Aside from './common/Aside'
import Login from './pages/Login';
import NoPage from './pages/404';
import Index from './pages/index'
import RegisterType from './pages/RegisterType'
import FirmRegister from './pages/FirmRegister'
import AgencyRegister from './pages/AgencyRegister'
import FulfilTip from './pages/FulfilTip'
import AgencyReplenish from './pages/AgencyReplenish'
import FirmReplenish from './pages/FirmReplenish'
import InfoSupplementTip from './pages/InfoSupplementTip'
import ProductSupermarket from './pages/ProductSupermarket'
import EnterpriseFinancial from './pages/EnterpriseFinancial'
import BondFinancingDetail from './pages/ProductSupermarket/DetailPages/BondFinancingDetail'
import CorporateServicesDetail from'./pages/ProductSupermarket/DetailPages/CorporateServicesDetail'
import EnterpriseDetail from './pages/EnterpriseFinancial/DetailPage/EnterPriseDetail'
import FinancialDetail from './pages/EnterpriseFinancial/DetailPage/FinancialDetai'
import NewsList from './pages/NewsList'
import NewsInfo from './pages/NewsInfo'
import ContactFooter from './common/OtherFooter'
import DebtFinance from './pages/Profile/Product/MainAccount/DebtFinance'
import AuthManage from './pages/Profile/Account/AuthPages/AuthorityManagement'
import DebtDetail from './pages/Profile/Product/MainAccount/Detail/DebtDetail'
import CompanyServices from './pages/Profile/Product/MainAccount/CompanyServices'
import CompanyDetail from './pages/Profile/Product/MainAccount/Detail/CompanyDetail'
import SubDebtFinance from './pages/Profile/Product/SubAccount/DebtList'
import AddDebt from './pages/Profile/Product/SubAccount/AddPages/AddDebt'
import SubCompanyService from './pages/Profile/Product/SubAccount/CompanyList'
import AddCompany from './pages/Profile/Product/SubAccount/AddPages/AddCompany'
import AddRequirement from './pages/Profile/Requirements/Add'
import RetrievePassword from './pages/RetrievePassword'
import ResetPassword from './pages/ResetPassword'

import SubDebtDetail from './pages/Profile/Product/SubAccount/Detail/DebtDetail'
import SubCompanyDetail from './pages/Profile/Product/SubAccount/Detail/CompanyDetail'

import Profile from './pages/Profile/Index'
import AccountInfo from './pages/Profile/AccountInfo'
import AccountSecurity from './pages/Profile/AccountSecurity'
import ConfirmList from './pages/Profile/ConfirmList'
import Transaction from './pages/Profile/Transaction'
import TransactionCompanyDetail from './pages/Profile/Transaction/Detail/index'
import MyMessage from './pages/MyMessage/index'
import ShelvedPage from './pages/Profile/Transaction/Detail/ShelvedPage'
import RegisterAgreement from './pages/RegisterAgreement'
import FinancingRequirements from './pages/FinancingRequirements'
import EnterpriseFinancialDetail from './pages/FinancingRequirements/Details'
import Requirements from './pages/Profile/Requirements'
const {Header, Content, Footer} = Layout;
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            currentRoute: this.props.location,
        }
    }

    componentWillMount() {
        this.props.history.listen(route => {
            this.setState({
                currentRoute: route
            })
        })
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }
    getHeader(route) {
        return route.hash === '#/'||
          route.hash === '#/enterprise-financial' ||
          route.hash === '#/financing-requirements' ||
          route.hash === '#/news-list' ||
          route.hash === '#/profile' ||
          route.hash.indexOf('enterprise-detail') > -1 ||
          route.hash.indexOf('news-info') > -1 ||
          route.hash.indexOf('enterprise-financial-detail') > -1 ||
          route.hash.indexOf('financial-detail') > -1 ||
          route.hash.indexOf('bond-detail') > -1 ||
            route.hash.indexOf('financing-requirements-detail')>-1 ||
          route.hash.indexOf('corporate-detail') > -1 ||
          route.hash.indexOf('user-info') >-1 ||
          route.hash.indexOf('#/product-supermarket') > -1 ||
            route.hash.indexOf('#/my-account') > -1 ||
            route.hash.indexOf('#/confirm-list') > -1 ||
          route.hash.indexOf('#/my-account') > -1 ||
          route.hash.indexOf('#/transaction') > -1 ||
          route.hash.indexOf('#/my-message') > -1 ||
            route.hash.indexOf('#/requirements') >-1 ||
            route.hash.indexOf('#/add-requirements') >-1 ||
          route.hash.indexOf('#/register-agreement') > -1 ||
          route.hash.indexOf('#/fulfil-tip/confirm') > -1
    }

    getAside(route) {
        return route.hash === '#/profile' ||
         route.hash.indexOf('#/user-info') > -1 ||
          route.hash.indexOf('#/my-account') > -1 ||
          route.hash.indexOf('#/transaction') > -1 ||
          route.hash.indexOf('#/my-message') > -1 ||
            route.hash.indexOf('#/requirements') >-1
    }

    getContactFooter(route) {
        return route.hash === '#/login'
            || route.hash === '#/register-type'
            || route.hash === '#/register-firm'
            || route.hash === '#/register-agency'
            || route.hash === '#/retrieve-password'
            || route.hash === '#/reset-password'
            || route.hash === '#/agency-replenish'
            || route.hash === '#/firm-replenish'
            || route.hash === '#/supple-tip'
            || route.hash.indexOf('fulfil-tip') > -1
    }
    render() {
        const {currentRoute} = this.state;
        return (
            <Router>
                <Layout className={this.getAside(currentRoute) ? 'body-container profile-container' : 'body-container'}>
                    <Header
                        className={this.getHeader(currentRoute) ? 'index-header container' : 'author-header container'}>
                        {this.getHeader(currentRoute) ? <IndexHeader/> : <AuthorHeader/>}
                    </Header>
                    <Layout className={this.getAside(currentRoute) && 'content'}>
                        {
                            this.getAside(currentRoute) && <Aside/>
                        }
                        <Content>
                            <Switch>
                                <Route exact path="/" component={Index}/>
                                <Route exact path="/news-list" component={NewsList}/>
                                <Route exact path="/news-info/:id" component={NewsInfo}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/register-type" component={RegisterType}/>
                                <Route exact path="/register-firm" component={FirmRegister}/>
                                <Route exact path="/register-agency" component={AgencyRegister}/>
                                <Route exact path="/fulfil-tip/:type" component={FulfilTip}/>
                                <Route exact path="/agency-replenish" component={AgencyReplenish}/>
                                <Route exact path="/firm-replenish" component={FirmReplenish}/>
                                <Route exact path="/supple-tip" component={InfoSupplementTip}/>
                                <Route exact path="/product-supermarket" component={ProductSupermarket}/>
                                <Route exact path="/financing-requirements" component={FinancingRequirements}/>
                                <Route exact path="/enterprise-financial" component={EnterpriseFinancial}/>
                                <Route exact path="/enterprise-detail/:id" component={EnterpriseDetail}/>
                                <Route exact path="/financial-detail/:id" component={FinancialDetail}/>
                                <Route exact path="/bond-detail/:id" component={BondFinancingDetail}/>
                                <Route exact path="/enterprise-financial-detail/:id" component={EnterpriseFinancialDetail}/>
                                <Route exact path="/corporate-detail/:id" component={CorporateServicesDetail}/>
                                <Route exact path="/user-info/debt-finance" component={DebtFinance} state={''}/>
                                <Route exact path="/my-account/auth-manage" component={AuthManage}/>
                                <Route exact path="/user-info/debt-finance/debt-detail/:id" component={DebtDetail}/>
                                <Route exact path="/user-info/company-services" component={CompanyServices}/>
                                <Route exact path="/user-info/company-services/company-detail/:id" component={CompanyDetail}/>
                                <Route exact path="/user-info/sub-debt-finance" component={SubDebtFinance} />
                                <Route exact path="/user-info/sub-debt-finance/sub-add-debt"  component={AddDebt}/>
                                <Route exact path="/user-info/sub-company-service" component={SubCompanyService}/>
                                <Route exact path="/user-info/sub-company-service/sub-add-company" component={AddCompany}/>
                                <Route exact  path="/corporate-detail/:id" component={CorporateServicesDetail}/>
                                <Route exact path="/user-info/sub-debt-finance/sub-debt-detail/:id" component={SubDebtDetail}/>
                                <Route exact path="/user-info/sub-company-services/sub-company-detail/:id" component={SubCompanyDetail}/>
                                <Route exact  path="/transaction" component={Transaction}/>
                                <Route exact  path="/transaction-detail/:id" component={TransactionCompanyDetail}/>
                                <Route exact path="/my-message" component={MyMessage}/>
                                <Route exact  path="/profile" component={Profile}/>
                                <Route exact  path="/my-account/account-info" component={AccountInfo}/>
                                <Route exact  path="/requirements" component={Requirements}/>
                                <Route exact  path="/add-requirements" component={AddRequirement}/>
                                <Route exact  path="/my-account/account-security" component={AccountSecurity}/>
                                <Route exact  path="/retrieve-password" component={RetrievePassword}/>
                                <Route exact  path="/reset-password" component={ResetPassword}/>
                                <Route exact  path="/confirm-list" component={ConfirmList}/>
                                <Route exact  path="/shelved-page" component={ShelvedPage}/>
                                <Route exact  path="/register-agreement" component={RegisterAgreement}/>
                                <Route exact path={'*'} component={NoPage}/>
                            </Switch>
                        </Content>

                    </Layout>
                    {
                        this.getContactFooter(currentRoute) ?
                            <Footer>
                                <PublicFooter/>
                            </Footer> :
                            <Footer>
                                <ContactFooter/>
                            </Footer>

                    }
                </Layout>
            </Router>
        );
    }
}


export default withRouter(App);
