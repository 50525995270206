import React from 'react';
import {Button, Steps} from 'antd';
import './index.scss'
import http from './../../lib/service/http'
import CorporateInfo from '../../pages/CorporateInfo';
import BusinessInfo from '../../pages/BusinessInfo';
import InfoSupplementTip from '../../pages/InfoSupplementTip';

const {Step} = Steps;

export default class FirmReplenish extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {
                    title: '上传法人信息',
                    content: <CorporateInfo onRef={(ref) => {
                        this.corporateInfo = ref
                    }} setData={this.getCorporateInfoData}/>,
                },
                {
                    title: '完善工商信息',
                    content: <BusinessInfo onRef={(ref) => {
                        this.businessInfo = ref
                    }} setData={this.getBusinessInfoData}/>,
                },
                {
                    title: '完成',
                    content: <InfoSupplementTip/>,
                },
            ],
            current: 0,
            corporateInfoData: {},
            businessInfoData: {}
        }
    }

    nextStep = () => {
        if (this.state.current === 0) {
            this.corporateInfo && this.corporateInfo.formRef.current.submit();
        }
        if (this.state.current === 1) {
            this.businessInfo && this.businessInfo.formRef.current.submit();
        }
    };

    prevStep = () => {
        this.setState({
            current: this.state.current - 1
        })
    };
    getCorporateInfoData = (data) => {
        this.setState({
            corporateInfoData: data,
            current: this.state.current + 1
        })
    }
    getBusinessInfoData = (data) => {
        this.setState({
            businessInfoData: data,
        }, () => {
            this.submitData();
        })
    }

    submitData() {
        let params = {
            ...this.state.corporateInfoData,
            ...this.state.businessInfoData
        }
        http.post('/enterprise/information', params).then(res => {
            this.setState({
                current: this.state.current + 1
            })
            this.getUserInfo()
        }, () => {
        })
    }

    getUserInfo() {
        http.get('/user/info').then(res => {
            sessionStorage.setItem('userInfo', JSON.stringify(res));
        })
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    render() {
        const {current, steps} = this.state;
        return (
            <div className="firm-register-container firm-replenish-container">
                <div className="firm-register-content content">
                    <div className="step-title">
                        <Steps current={current}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title}/>
                            ))}
                        </Steps>
                            <div className={current === 0 ? 'steps-content' : 'steps-content is-show-content'}>
                                {steps[0].content}
                            </div>
                            <div className={current !== 0 ? 'steps-content' : 'steps-content  is-show-content'}>
                                {current !== 0 && steps[current].content}
                            </div>
                        <div className="steps-action">
                            {current === 0 && (
                                <Button type="primary" onClick={() => this.nextStep()}>
                                    下一步
                                </Button>
                            )}
                            {current === steps.length - 2 && (
                                <Button type="primary" className={'submit-replenish'} onClick={() => this.nextStep()}>
                                    提交
                                </Button>
                            )}
                            {current === 1 && (
                                <Button className={'pre-btn'} onClick={() => this.prevStep()}>
                                    上一步
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}