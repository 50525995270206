import React from 'react';
import './index.scss'
import http from "../../../lib/service/http";
import {Col, Form, Input} from "antd";
import {rules} from "../../../lib/help/rules";
const { TextArea } = Input;
const {memo,product_num_max} = rules;
export default class ConfirmList extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            financingTitle: [
                '产品名称',
                '所属机构',
                '利率范围',
                '额度范围',
                '担保方式',
                '期限',
            ],
            enterpriseTitle: [
                '产品名称',
                '所属机构',
                '类型',
                '价格范围',
            ],
            enterpriseRes: {},
            bondRes: {},
            id: '',
            type: '',
        }
    }
    getListData() {
        let url = '/home/enterprise-service-detail/'
        if (this.state.type === 'bond') {
            url = 'home/debt-product-detail/'
        }
        http.get(url + this.state.id).then(res => {
            if (this.state.type === 'bond') {
                this.setState({
                    bondRes: res.detail
                })
            } else {
                this.setState({
                    enterpriseRes: res.detail
                })
            }
        })
    }


    componentDidMount() {
        let tmp_query = this.props.location.search;
        if (tmp_query.indexOf('id') > -1 && tmp_query.indexOf('type') > -1) {
            tmp_query = tmp_query.substr(1,).split('&')
            this.setState({
                id: tmp_query[0].split('id=')[1],
                type: tmp_query[1].split('type=')[1],
            }, () => {
                this.getListData()
            })
        } else {
            this.props.history.replace('/')
        }
    }
    submitList = (values)=> {
        let params = {
            product_id: Number(this.state.id),
            product_type: this.state.type === 'bond' ? 'TRADING' : 'SERVICE',
            ...values
        };
            http.post('/enterprise/apply-product', params).then(res => {
                sessionStorage.removeItem('orderInfo');
                this.props.history.push({pathname: '/fulfil-tip/confirm'});
            })
    }

    componentWillUnmount() {
    }
    preventBubble =(e)=>{
        e.preventDefault();
    };
    render() {
        const {financingTitle, enterpriseTitle, bondRes, type, enterpriseRes,confirmPriceMax} = this.state;
        return (
            <div className="confirm-list-container">
                <div className="content">
                    <p className="page-title">
                        {
                            type === 'bond' ? '申请贷款融资产品' : '申请企业服务产品'
                        }
                    </p>
                    {
                        type === 'bond' ?
                            <div className="list-box financing-confirm">
                                <p className="list-title">
                                    核对订单信息
                                </p>
                                <div className="list-content">
                                    <div className="title-box">
                                        {
                                            financingTitle.map((item, index) => {
                                                return <p className={"text title" + index} key={index}>{item}</p>
                                            })
                                        }
                                    </div>
                                    <div className="list-desc">
                                        <p className="text title0">{bondRes.Name}</p>
                                        <p className="text title1">{bondRes.FinName}</p>
                                        {
                                            bondRes.RateCeil ?
                                                <p className="text title2">{bondRes.RateFloor}%-{bondRes.RateCeil}%</p>:
                                                <p className="text title2">{bondRes.RateFloor}%以上</p>
                                        }
                                        {
                                            bondRes.AmountCeil ?
                                                <p className="text title3">{bondRes.AmountFloor} - {bondRes.AmountCeil}万元</p> :
                                                <p className="text title3">{bondRes.AmountFloor}万元以上</p>
                                        }
                                        <p className="text title4">{bondRes.Secured}</p>
                                        {
                                            bondRes.CycleCeil ?
                                                <p className="text title5">{bondRes.CycleFloor} - {bondRes.CycleCeil}个月</p> :
                                                <p className="text title5">{bondRes.CycleFloor}个月起</p>
                                        }
                                    </div>
                                </div>
                                <div className={'apply-amount'}>
                                    <Form
                                      className="range"
                                      name="basic"
                                      onFinish={(values) => this.submitList(values)}
                                      ref={this.formRef}
                                    >
                                        <div className={'apply-amount-num'}>
                                        <Form.Item
                                          label="申请金额（选填）"
                                          rules={product_num_max} name='intent_amount'
                                          validateFirst={true}>
                                            <Input
                                              placeholder={'请输入意向金额'}
                                            />
                                        </Form.Item>
                                        <div className={'unit-text'}>万元</div>
                                        </div>
                                        <Form.Item
                                          label="备注（选填）"
                                          name="memo"
                                          className="not-extra input-remark"
                                          rules={memo}
                                        >
                                            <TextArea size="middle" placeholder={'请输入备注信息,最多不超过200个字'}/>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <p className="submit-btn" onClick={()=>this.formRef.current.submit()}>
                                    提交订单
                                </p>
                            </div> :
                            <div className="list-box company-confirm">
                                <p className="list-title">
                                    核对订单信息
                                </p>
                                <div className="list-content">
                                    <div className="title-box">
                                        {
                                            enterpriseTitle.map((item, index) => {
                                                return <p className={"text title" + index} key={index}>{item}</p>
                                            })
                                        }
                                    </div>
                                    <div className="list-desc">
                                        <p className="text title0">{enterpriseRes.Name}</p>
                                        <p className="text title1">{enterpriseRes.FinName}</p>
                                        <p className="text title2">{enterpriseRes.ProductTypeStr}</p>
                                        {
                                            enterpriseRes.PriceCeil ?
                                                <p className="text title3">{enterpriseRes.PriceFloor} - {enterpriseRes.PriceCeil}万元</p> :
                                                <p className="text title3">{enterpriseRes.PriceFloor}万元以上</p>
                                        }
                                    </div>
                                </div>
                                <div className={'apply-amount'}>
                                    <Form
                                      className="range"
                                      name="basic"
                                      ref={this.formRef}
                                      onFinish={(values) => this.submitList(values)}
                                    >
                                        <div className={'apply-amount-num'}>
                                            <Form.Item
                                              label="预算金额（选填）"
                                              rules={product_num_max} name='intent_amount'
                                              validateFirst={true}>
                                                <Input
                                                  placeholder={'请输入意向金额'}
                                                />
                                            </Form.Item>
                                            <div className={'unit-text'}>万元</div>
                                        </div>
                                        <Form.Item
                                          label="备注（选填）"
                                          name="memo"
                                          className="not-extra input-remark"
                                          rules={memo}
                                        >
                                            <TextArea size="middle" placeholder={'请输入备注信息,最多不超过200个字'}/>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <p className="submit-btn" onClick={()=>this.formRef.current.submit()}>
                                    提交订单
                                </p>
                            </div>
                    }
                </div>
            </div>
        )
    }

}
