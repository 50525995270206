import React from 'react';
import {withRouter, Link} from "react-router-dom";

import './index.scss'
import iconPublicSecurity from "../../resource/images/index/icon-public-security.svg";


class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }



    render() {
        return (
            <div className="footer-content content">
                <p className="footer-text">
                    © {(new Date()).getFullYear()} 重庆市大足区兴农融资担保有限责任公司综合服务平台 版权所有
                    <a className={'text-link'} target="_blank" href="https://beian.miit.gov.cn/"> 渝ICP备 2021002695号-2</a>
                    <img alt="备案"  src={iconPublicSecurity} className="icon-security"/>
                    <a className={'text-link'} target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50022502000511">
                        渝公网安备 50022502000511号
                    </a>
                </p>
                <div className="link-box">
                    {/*<Link to={'/'} className='link-item'>联系我们</Link>-*/}
                    <Link to={'/register-agreement'} className='link-item'>用户协议</Link>
                </div>
            </div>
        )
    }
}

export default withRouter(Footer)