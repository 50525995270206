import React from 'react';
import {Row, Col, Pagination,} from 'antd';
import "../index.scss"
import {withRouter} from "react-router-dom";
import NoData from "../../../NoData";
import http from "../../../../lib/service/http";
import {localTime} from "../../../../lib/help/helper";
class SubAgencyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      total:0,
      data: [],
      visible: false,
      distributeData:[],
      productOrder: {},
      statusList: ['PENDING-MATCH','MATCH-SUCCESS','MATCH-FAILED']
    }
  }
  getList = (key,params) => {
    let tmp = {
      query_status:key,
      page:params.page
    };
    http.get('/financial/product-order/list',tmp).then(res => {
      if (res) {
        if(this.props.type === 'index') {
          this.setState({
            data: res.data.items ? res.data.items.slice(0, 3) : [],
            total: res.data.total
          })
        }else {
          this.setState({
            data: res.data.items,
            total: res.data.total
          })
        }

      }
    })
  };
  componentDidMount () {
    const  key = this.state.statusList[this.props.activeKey];
    let params = {
      page:1
    };
    this.getList(key,params)
  }
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params = {
      page:page,
    };
    this.getList(this.state.statusList[this.props.activeKey],params)
  };
  componentDidUpdate(prevProps,prevState){
    const  key = this.state.statusList[this.props.activeKey];
    let params = {
      page:this.state.currentPage
    };
    if(prevProps.activeKey !== this.props.activeKey) {
      this.getList(key,params);
      this.setState({
        currentPage: 1
      })
    }
  }
  goDetail= (item)=> {
    this.props.history.push({
      pathname:'/transaction-detail/'+item.ID,
      state: '产品详情'
    });
  };
  render() {
    const {total,data} = this.state;
    return (
      <div className="transaction-list  agency-list">
        {total > 0 ? (  <div className={''}>
            <Row className={'transaction-header-content'}>
              <Col span={3} className={'text'}>交易编号</Col>
              <Col span={6} className={'text'}>申请产品</Col>
              <Col span={6} className={'text'}>申请企业</Col>
              <Col span={5} className={'text'}>申请日期</Col>
              <Col span={4} className={'text'} style={{paddingLeft: '25px'}}>操作</Col>
            </Row>
            {data.map(item=> {
              return   <div className={'list-content'} key={item.ID}>
                <Row className={'list-item'}>
                  <Col span={3} className={'text'}>{item.ID}</Col>
                  <Col span={6} className={'text'}>
                    <div>{item.ProductName}</div>
                    <div className={'text-type'}>类型：<span>{item.ProductType==='TRADING'?'贷款融资':'企业服务'} </span></div>
                  </Col>
                  <Col span={6} className={'text'}>
                    <div>{item.EnterpriseName}</div>
                    <div className={'text-type'}>联系方式：<span>{item.Telephone}</span></div>
                  </Col>
                  <Col span={5} className={'apply-time'}>
                    <div>
                      <p>{localTime(item.CreatedAt)}</p></div>
                  </Col>
                  <Col span={4} className={'btn'}>
                    <div>
                      <p onClick={this.goDetail.bind(this, item)}>{item.OrderStatus==='PENDING-MATCH'?'撮合交易':'查看详情'}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            })}
            <div className={'page-total-data'}>
              <div >
                共计 <span className={'total-num'}>{total}</span> 条数据
              </div>
              <Pagination
                className={'pageStyle'}
                onChange={this.onChangePage.bind(this)}
                defaultCurrent={1}
                total={total}
                pageSize={10}
                showSizeChanger={false}
                current={this.state.currentPage}
              />
            </div>
          </div>
        ):<div className="news-list-content">
          <NoData />
        </div>}
      </div>
    )
  }
}
export default withRouter(SubAgencyList)
