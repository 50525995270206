import React from 'react';
import {Row,Col} from "antd";
import {getImgPublicUrl, getImgUrl, localTime} from "../../../../../lib/help/helper";
import companyLogo from "../../../../../resource/images/public/img-company-logo.svg"
export default class EnterpriseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{}
    }
  }
  close = () => {
    this.props.close();
  };
  componentDidMount() {
    this.setState({
      data: this.props.data
    })
  }
  render() {
    const {data} = this.state;
    return (
      <div className="enterprise-info">
        <Row>
          <Col span={6} className={'enterprise-info-title'}>企业名称</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.Name}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>统一社会信用代码</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.UnifiedSocialCreditCode}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>企业类型</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.Type}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>法人代表</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.LegalPersonName}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>法人代表家庭住址</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.LegalPersonAddress}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>法人代表身份证号</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.LegalPersonIDNumber}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>身份证有效期</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.LegalPersonIDValidity==='PERMANENT'?'长期':data.LegalPersonIDValidity}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>法定代表人身份证</Col>
          <Col span={18} className={'enterprise-info-text'}>
            <Row gutter={24}>
              <Col span={12}>
                <div className="img-box">
                  <img src={getImgUrl(data.LegalPersonIDFrontFid)} alt=""/>
                </div>
              </Col>
              <Col span={12}>
                <div className="img-box">
                  <img src={getImgUrl(data.LegalPersonIDBackFid)} alt=""/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>营业执照</Col>
          <Col span={18} className={'enterprise-info-text'}>{
            data.BusinessLicenseFid ?
              <div className="img-box">
                <div className="img-bg">
                  <img src={getImgPublicUrl(data.BusinessLicenseFid)} alt=""/>
                </div>
              </div> :
              <p className="desc">暂未添加</p>
          }</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>营业执照核准日期</Col>
          <Col span={18} className={'enterprise-info-text'}>{localTime(data.BusinessLicenseApprovalDate).split(' ')[0]}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>企业logo</Col>
          <Col span={18} className={'enterprise-info-text'}>{
            data.LogoFid ?
              <div className="img-box">
                <div className="img-bg">
                  <img src={getImgUrl(data.LogoFid)} alt=""/>
                </div>
              </div> :
                <div className="img-box">
                    <div className="img-bg">
                        <img src={companyLogo} alt=""/>
                    </div>
                </div>
          }</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>企业地址</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.Address}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>注册资本</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.RegisteredCapital}元</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>成立日期</Col>
          <Col span={18} className={'enterprise-info-text'}>{localTime(data.FoundedDate).split(' ')[0]}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>营业期限</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.BusinessLicenseValidity==='PERMANENT'?'长期':data.BusinessLicenseValidity}</Col>
        </Row>
        <Row>
          <Col span={6} className={'enterprise-info-title'}>经营范围</Col>
          <Col span={18} className={'enterprise-info-text'}>{data.BusinessScope}</Col>
        </Row>
      </div>
    )
  }
}
