import React from 'react';
import {Button, Form, Input, Row, Col, message} from 'antd';
import './index.scss'
import http from './../../lib/service/http'
import {rules} from '../../lib/help/rules'
import {regexps_img_code} from '../../lib/help/validates'

const {account, password, phone, phone_code, img_code} = rules;

export default class RetrievePassword extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            imageCode: '',
            uuid: '',
            isSend: false,
            cutDownTime: 60,
            imgCodeReg: false,
            passwordRules: [
                ...password,
                {
                    validator: this.handleCheckPwd,
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
        }
    }

    onFinish(values) {
        values.uuid = this.state.uuid;
        delete values.agreement;
        http.post('/user/reset-password', values).then(res => {
            message.success('重置成功,请登录');
            this.props.history.replace('/login')
        }, () => {
            this.formRef.current.setFieldsValue({captcha: ''})
            this.getImgCode()
        })
    };


    getPhoneCode = () => {
        let params = {
            telephone: this.formRef.current.getFieldValue('telephone'),
            uuid: this.state.uuid,
            captcha: this.formRef.current.getFieldValue('captcha')
        }
        if (!params.telephone) {
            message.error('请输入正确手机号');
            return;
        }
        this.setState({
            isSend: true,
        })
        // 发送验证码
        let tmpVal
        http.post('/sms/send', params).then(res => {
            tmpVal = setInterval(() => {
                if (this.state.cutDownTime === 0) {
                    clearInterval(tmpVal);
                    this.setState({
                        isSend: false,
                        cutDownTime: 60,
                    })
                }
                this.setState({
                    cutDownTime: this.state.cutDownTime - 1
                })
            }, 1000)
        }).catch(() => {
            clearInterval(tmpVal);
            this.setState({
                isSend: false,
                cutDownTime: 60,
            })
            this.getImgCode();
        })
    }

    getImgCode = () => {
        http.get('/image-captcha/generate').then(res => {
            this.setState({
                imageCode: res.data.image,
                uuid: res.data.uuid
            })
        })
    }

    handleCheckPwd = (_, value, callback) => {
        let cfmPwd = this.formRef.current.getFieldValue('confirm_password');
        if (cfmPwd && value && cfmPwd !== value) {
            callback(new Error('两次密码不一致'))

        } else {
            if (!this.formRef.current.isFieldValidating(['confirm_password'])) {
                this.formRef.current.validateFields(['confirm_password']);
            }
            callback()
        }
    }

    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        if (loginPass && value && loginPass !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            if (!this.formRef.current.isFieldValidating(['password'])) {
                this.formRef.current.validateFields(['password']);
            }
            callback()
        }
    }

    regImgCode = (e) => {
        this.setState({
            imgCodeReg: regexps_img_code.test(e.target.value)
        })
    }

    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
        this.getImgCode()
    }

    componentWillUnmount() {
    }

    render() {
        const {confirmPasswordRules, passwordRules} = this.state
        return (
            <div className="firm-register-container retrieve-password-container">
                <div className="firm-register-content content">
                    <div className="form-content register-from-content">
                        <Row gutter={[0, 0]} className='number-info-box'>
                            <Col className="gutter-row left-from" span={24}>
                                <Form
                                    name="basic"
                                    ref={this.formRef}
                                    onFinish={(values) => this.onFinish(values)}
                                    onFinishFailed={() => this.onFinishFailed()}>
                                    <Form.Item
                                        label="登录用户名"
                                        name="username"
                                        rules={account}
                                        extra={'6-20位字符，含数字、字母中的一种及以上'}
                                        validateFirst={true}
                                    >
                                        <Input size="middle" placeholder={'请输入用户名'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="手机号"
                                        name="telephone"
                                        rules={phone}
                                        className="not-extra input-phone"
                                    >
                                        <Input size="middle" placeholder={'请输入法人手机号'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="图片识别码"
                                        name="captcha"
                                        rules={img_code}
                                        className="not-extra number-code register-code"
                                    >
                                        <Input
                                            suffix={<img src={'data:image/jpeg;base64,' + this.state.imageCode}
                                                         onClick={this.getImgCode} alt=""
                                                         className='img-code'/>}
                                            size="middle" placeholder={'请输入图片识别码'} onChange={this.regImgCode}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="短信验证码"
                                        name="sms_code"
                                        rules={phone_code}
                                        className="not-extra news-code-input number-code"
                                    >
                                        <Input
                                            suffix={
                                                <div className="send-box">
                                                    {
                                                        this.state.isSend &&
                                                        <p className="send-code-btn number-btn">
                                                            {this.state.cutDownTime}
                                                        </p>
                                                    }
                                                    {
                                                        !this.state.isSend && !this.state.imgCodeReg &&
                                                        <p className="send-code-btn number-btn">
                                                            发送验证码
                                                        </p>
                                                    }
                                                    {
                                                        !this.state.isSend && this.state.imgCodeReg &&
                                                        <p className="send-code-btn"
                                                           onClick={() => this.getPhoneCode()}>
                                                            发送验证码
                                                        </p>
                                                    }
                                                </div>
                                            }
                                            size="middle" placeholder={'请输入短信验证码'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="新密码"
                                        name="password"
                                        rules={passwordRules}
                                        extra={'8-16位字符，含数字、大小写字母、特殊字符中的三种及以上'}
                                        className={'input-password has-line'}
                                        validateFirst={true}
                                    >
                                        <Input.Password size="middle" placeholder={'请输入密码'}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="确认密码"
                                        name="confirm_password"
                                        rules={confirmPasswordRules}
                                        className="confirm-input not-extra input-password"
                                        validateFirst={true}
                                    >
                                        <Input.Password size="middle" placeholder={'请再次输入密码'}/>
                                    </Form.Item>

                                    <Form.Item className="submit-btn retrieve-btn">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}