import React from 'react';
import {message, Modal} from 'antd';
import {withRouter, Link} from "react-router-dom";
import {ExclamationCircleOutlined} from '@ant-design/icons';

import './index.scss'
import http from "../../lib/service/http";
import Cookie from "js-cookie";

import headerLogo from '../../resource/images/public/logo-black.svg'
import userAvatar from '../../resource/images/public/user-avatar.svg'


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            currentRoute: this.props.location,
            isModalVisible: false,

        }
    }

    componentDidMount() {
        this.props.history.listen(route => {
            this.setState({
                currentRoute: route
            })
        })
    }

    setHeaderTitle(route) {
        let tmpTitle = ''
        switch (route) {
            case '/register-type' :
                tmpTitle = '';
                break;
            case '/login' :
                tmpTitle = '登录';
                break;
            case '/register-firm' :
                tmpTitle = '注册';
                break;
            case '/register-agency' :
                tmpTitle = '注册';
                break;
            case '/fulfil-tip/agency' :
                tmpTitle = '注册';
                break;
            case '/fulfil-tip/firm' :
                tmpTitle = '注册';
                break;
            case '/agency-replenish' :
                tmpTitle = '资料补充';
                break;
            case '/firm-replenish' :
                tmpTitle = '资料补充';
                break;
            case '/retrieve-password' :
                tmpTitle = '找回密码';
                break;
            default:
                tmpTitle = '';
        }
        return tmpTitle;
    }

    logout = () => {
        http.post('/user/logout').then(res => {
            sessionStorage.clear();
            Cookie.remove('token');
            message.success('登出成功');
            this.props.history.replace('/');
            this.props.history.replace('/');
        })
    }
    handleOk = () => {
        this.logout();
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }
    userLink(userInfo) {
        if(!userInfo.info && userInfo.user && userInfo.user.UserType === '机构主账户') {
            return '/agency-replenish'
        }
        if(!userInfo.info && userInfo.user && userInfo.user.UserType === '企业账户') {
            return '/firm-replenish'
        }
        return '/'
    }

    render() {
        let userInfo = sessionStorage.getItem('userInfo') || '{}';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
        }
        const {isModalVisible} = this.state;
        return (
            <div className={"author-header-container content-header"}>
                <div className="header-content content">
                    <div className="img-box">
                        <Link to={'/'}>
                            <img src={headerLogo} alt="" className="header-logo"/>
                        </Link>
                        {
                            this.setHeaderTitle(this.state.currentRoute.pathname) &&
                            <span className="current-page">{
                                this.setHeaderTitle(this.state.currentRoute.pathname)
                            }</span>
                        }
                    </div>
                    {
                        userInfo.user ?
                            <div className="avatar-box">
                                <Link to={this.userLink(userInfo)} className="user-name-box">
                                    <img src={userAvatar} className={'avatar-img'} alt={''}/>
                                    <p className="user-name">{userInfo.user.UserName}</p>
                                </Link>
                                <p className="logout-btn" onClick={this.showModal}>退出登录</p>
                            </div>
                            :
                            <div className="login-aside">
                                {
                                    this.state.currentRoute.pathname === '/login' ?
                                        <Link className={'link-text'} to={'/register-type'}>注册</Link> :
                                        <Link className={'link-text'} to={'/login'}>登录</Link>
                                }
                                <Link className={'link-text'} to={'/'}>平台首页</Link>
                            </div>
                    }
                </div>
                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'确定'}
                    centered
                    onOk={this.handleOk}
                    className="set-padding"
                    onCancel={this.handleCancel}>
                    <p className='account-modal-title logout-title'>
                        <ExclamationCircleOutlined className="icon-waring"/>
                        确定退出登录吗?</p>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Header)
