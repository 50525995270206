import React from 'react';
import {Breadcrumb, Button, Form, Input, Modal, Checkbox, InputNumber} from "antd";
import {Link, withRouter} from "react-router-dom";
import xian from "../../../../resource/images/profile/xian.svg";
import pull from "../../../../resource/images/profile/pull.svg"
import splitLine from "../../../../resource/images/profile/split-line.svg"
import { Row, Col} from 'antd';
import closeImg from "../../../../resource/images/profile/cancle.svg";
import EnterpriseDetail  from "./Modal/EnterpriseDetail";
import http from "../../../../lib/service/http";
import TransactionList from "./Modal/TransactionList"
import {getUserInfo} from "../../../../lib/help/public";
import {rules} from "../../../../lib/help/rules";
import {localTime} from "../../../../lib/help/helper";
import Distribution from "../Agency/Modal/Distribution";
import xianRed from "../../../../resource/images/profile/xian-red.svg";
const {product_num,product_rate,pactSecured, product_date} = rules;
class CompanyDetail extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      status: '待撮合',
      visible: false,
      detailData:{},
      visiblePass: false,
      userInfo: {
        user: {}
      },
      isEdit: true,
      children:[],
      options: [],
      isSecured: false,
      secured:[],
      SecuredData: [],
      checkedValues: [],
      orderObject: {},
      productObject: {},
      operationLogs: [],
      enterpriseObject: {},
      distributeData:[],
      visibleDistribute: false,
      OrderSecured: [],
      confirmRate: [
        ...product_rate,
        {
          validator: this.handleRate,
          trigger: 'onBlur'
        },
      ],
    }
  }
  handleRate =(_, value, callback) =>{
    if (Number(value) > 0&&!(Number(value) <100)) {
      callback(new Error('请输入小于100的数字'))
    } else {
      callback()
    }
  };
  getListData(params) {
    http.get(`/financial/product-order/detail/${params.id}`).then(res => {
      this.setState({
        orderObject: res.data.order_object,
        productObject: res.data.product_object,
        operationLogs: res.data.operation_logs,
        enterpriseObject: res.data.enterprise_object,
        status: res.data.order_object.OrderStatus
      });
    });
  };
  getUserInfo() {
    let tmpInfo = sessionStorage.getItem('userInfo');
    if(tmpInfo) {
      tmpInfo = JSON.parse(tmpInfo);
      this.setState({
        userInfo: tmpInfo
      })
    }
  }
  componentDidMount() {
    let params = {
      id:this.props.params.id
    };
    getUserInfo(()=>{
      this.getListData(params);
    });
    this.getUserInfo();
    this.getSecureMap();
  }
  getDistributeList = (key,params) => {
    let tmp = {
      query_status: key
    };
    http.get('/financial/product-order/distribute-sub-account-list',tmp).then(res => {
      if (res.data.item !== null) {
        this.setState({
          distributeData: res.data.items,
          visibleDistribute: true,
        })
      } else if (res.err_code === 110013) {
        this.setState({
          visibleDistribute: false,
        });
      }
    })
  };
  Distribute=()=> {
    // this.getDistributeList(item.OrderStatus);
    this.getDistributeList(this.state.productObject.OrderStatus);
  };
  goDetails =() => {

  };
  openDetailsModal = ()=> {
    this.setState({
      visible: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      visibleDistribute: false
    });
  };
  closeModal = () => {
    this.setState({
      visible: false,
      visibleDistribute: false
    });
  };
  handleCancelList = () => {
    this.setState({
      visiblePass: false,
    });
  };
  closeTransactionList = () => {
    this.setState({
      visiblePass: false
    });
  };
  pass=() => {
    this.setState({
      visiblePass: true
    });
  };
  reject=()=> {
    const that = this;
    Modal.confirm({
      className: 'info-disable',
      width: '416px',
      title: (
        <div className={'disable-title'}>确定驳回该交易吗?</div>
      ),
      content:(
        <div className={'disable-text'}>交易驳回后将不可更改，请谨慎操作</div>
      ),
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
      onOk() {
        http.post('/financial/product-order/match-failed', {product_order_id:that.state.orderObject.ID}).then(res => {
          if (Object.keys(res).length===0) {
            that.props.history.push({
              pathname:'/transaction',
              state: ''
            })
          }
        }, () => {
        })
      },
    });
  };
  editResult=()=> {
    if (this.state.isEdit) {
      this.setState({
        isEdit: false
      });
    } else {
      this.setState({
        isEdit: true
      })
    }
    this.formRef.current.setFieldsValue({order_secured: this.getValueByKey(this.state.orderObject.OrderSecured)});
    this.formRef.current.setFieldsValue({amount: this.state.orderObject.Amount});
    this.formRef.current.setFieldsValue({interest_rate: String(this.state.orderObject.InterestRate)});
    this.formRef.current.setFieldsValue({month_limit: this.state.orderObject.MonthLimit});
  };
  onFinish =(values) => {
    if (values.order_secured) {
      if(Array.isArray(values.order_secured)) {
        values.order_secured =  values.order_secured.join(',')
      } else {

      }
      values.order_secured = this.getValueByKey(values.order_secured,1).split(',')
    }
    let params = {
      product_order_id: this.state.orderObject.ID,
      ...values
    };
   // const that =this;
    http.post('/financial/product-order/update-match', params).then(res => {
      if (Object.keys(res).length===0) {
        if (this.state.isEdit) {
          this.setState({
            isEdit: false
          });
        } else {
          this.setState({
            isEdit: true
          })
        }
        let params = {
          id:this.props.params.id,
        };
        this.getListData(params);
      }
    }, () => {
    })
  };
  onFinishFailed=()=> {

  };
  getSecureMap = ()=> {
    http.get('/financial/debt-product/secure_map').then(res => {
      let obj = {};
      let array = [];
      Object.keys(res.data).forEach((key) => {
        obj.label = res.data[key];
        obj.value = key;
        return array.push({label:res.data[key],value:key})
      });
      this.setState({
        options: array
      });
    })
  };
  onChangeSecured=(checkedValues)=> {
    this.setState( {
      checkedValues: checkedValues
    });
    let array = [];
    this.state.options.forEach(item=> {
      checkedValues.forEach(i=> {
        if (item.value=== i) {
        return  array.push(item.label)
        }
      })
    });
    this.setState({
      SecuredData: array
    });
    this.formRef.current.setFieldsValue({order_secured: array});
  };
  onChangeSecuredInput =()=> {
    if (this.state.isSecured) {
      this.setState({
        isSecured: false
      })
    } else {
      this.setState({
        isSecured: true
      });
      this.setState( {
        checkedValues: this.state.orderObject.OrderSecured.split(',')
      });
    }
  };
  goClose=(item)=> {
    const that =this;
    Modal.confirm({
      className: 'info-disable',
      width: '416px',
      title: (
        <div className={'disable-title'}>确定关闭该交易吗?</div>
      ),
      content:(
        <div className={'disable-text'}>关闭后不可更改</div>
      ),
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
      onOk() {
        let params = {
          product_order_id: item.ID,
          query_status:item.OrderStatus
        };
        http.post('/financial/product-order/close',params).then(res => {
          if (Object.keys(res).length===0) {
            that.props.history.push({
              pathname:'/transaction',
              state: ''
            })
          }
        }, () => {
        })
      },
    });
  };
  cancelSecured =()=> {
    this.setState({
      isSecured: false
    })
    this.formRef.current.setFieldsValue({order_secured: this.getValueByKey(this.state.orderObject.OrderSecured)});
  };
  oKSecured =() => {
    this.formRef.current.setFieldsValue({order_secured: this.state.SecuredData});
    this.setState({
      isSecured: false
    })
  };
  getValueByKey=(key,type)=> {
    let array = [];
    const arrayPickerData = [];
    if (type&&type===1) {
       array = [
        {'信用':'CREDIT'},
        {'保证':'ENSURE'},
        {'抵押':'MORTGAGE'},
        {'质押':'PLEDGE'}
      ];
    } else {
      array = [
        {'CREDIT':'信用'},
        {'ENSURE':'保证'},
        {'MORTGAGE':'抵押'},
        {'PLEDGE':'质押'}
      ];
    }
    if (key!==undefined) {
      let keyArr = key.split(",");
        keyArr.forEach((k)=>{
          array.forEach((Item) => {
            if (Item[k]!==undefined) {
              arrayPickerData.push(Item[k]);
            }
          });
        });
      return arrayPickerData.join(',');
    }
  };
  render() {
    const {status,visible,isSecured,visiblePass,isEdit,options,orderObject,productObject,operationLogs,enterpriseObject,confirmRate} = this.state;
    return (
      <div className="firm-register-container transaction agency-detail-transaction">
        <div className={'breadcrumb detail-breadcrumb'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link className={'link'} to={'/profile'}>个人中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link className={'link'} to={'/transaction'}>我的交易</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className={'link'}>交易详情</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="firm-register-content transaction-index-container">
          <div className={'title-detail'}>交易详情</div>
          <div className={'transaction-detail'}>
            <Row>
              <Col span={8}>
                <div className={'applicant applicant-enterprise'}><div>申请企业</div> <div className={'apply-title apply-title-width'}>{orderObject.EnterpriseName}</div></div>
                <div className={'applicant'}>申请时间 <span className={'apply-title'}> {localTime(orderObject.CreatedAt)}</span></div>
                {
                  orderObject.Memo?<div className={'applicant-enterprise-memo'}><div>备注</div> <div className={'apply-memo'}>{orderObject.Memo}</div></div>:''
                }
              </Col>
              <Col span={8}>
                <div className={'applicant applicant-enterprise'} style={{display: "flex"}}><div>产品名称</div> <div className={'apply-title apply-title-width'}>{orderObject.ProductName} </div></div>
                {
                  orderObject.IntentAmount?<div className={'applicant applicant-enterprise'}><div>{orderObject.ProductType === 'SERVICE'?'预算金额':'申请金额'}</div> <div className={'apply-title apply-title-width'}>{orderObject.IntentAmount} 万元</div></div>:''
                }
              </Col>
              <Col span={8} className={'product-status-info'}>
                {status === 'PENDING-DISTRIBUTE'? (
                  <div>
                    <div className={'close-btn'}  onClick={this.goClose.bind(this, orderObject)}>关闭交易</div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>待分配</div>
                    <div className={'transaction-validity'}>
                      {orderObject.RemainDays&&Number(orderObject.RemainDays)>7?
                        <div>
                          <span className={'apply-title'}><img src={xian} alt=""/>有效期：{orderObject.RemainDays}天</span>
                        </div>: <div>
                          <span className={'apply-title'}><img src={xianRed} alt=""/>有效期：{orderObject.RemainDays}天</span>
                        </div>}
                    </div>
                  </div>
                ):(status === 'PENDING-MATCH'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>待撮合</div>
                  </div>
                ):(status === 'MATCH-SUCCESS'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>撮合成功</div>
                  </div>
                ):(status === 'MATCH-FAILED'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>撮合失败</div>
                  </div>
                ):(status === 'CLOSED'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>已关闭</div>
                  </div>
                ):(status === 'EXPIRED'?(
                  <div>
                    <div className={'close-status apply-title'}>交易状态</div>
                    <div className={'status-text'}>已失效</div>
                  </div>
                ):(<div/>))))))}
              </Col>
            </Row>
          </div>
          <div className={'split-line'}>
            <img src={splitLine} alt=""/>
          </div>
          <div className={'detail-bottom'}>
            <div className={'title-detail'}>操作日志</div>
            {this.state.userInfo.user.UserType === '机构主账户'&&
              <div>
                {
                  (orderObject.OrderStatus==='PENDING-DISTRIBUTE' || orderObject.OrderStatus==='PENDING-MATCH')&&  <div onClick={this.Distribute.bind()} className={'distribute'}>{orderObject.FinancialSubUserID?'重新分配':'分配子账号'}</div>
                }
              </div>
           }
          </div>
          <Modal title={'分配子账号'}
                 visible={this.state.visibleDistribute}
                 closeIcon={<img src={closeImg} alt=""/>}
                 onCancel={this.handleCancel}
                 onOk={this.handleCancel}
                 okText={'确定'}
                 footer={null}
                 width={450}
                 wrapClassName='modal-distribute'
                 destroyOnClose={true}
          >
            <Distribution close={this.closeModal}  productOrder={this.state.orderObject} distributeData={this.state.distributeData}/>
          </Modal>
          <div>
            <Row className={'transaction-header-content'}>
              <Col span={3} className={'text'}>操作前状态</Col>
              <Col span={3} className={'text'}>操作</Col>
              <Col span={3} className={'text'}>操作后状态</Col>
              <Col span={4} className={'text'}>操作人(用户名)</Col>
              <Col span={5} className={'text'}>操作对象(用户名)</Col>
              <Col span={6} className={'text'}>操作时间</Col>
            </Row>
            <div className={'list-content'}>
              {operationLogs.map(item=> {
                return <Row className={'list-item'} key={item.ID}>
                    <Col span={3} className={'text'}>{item.OperationPrev || "--"}</Col>
                    <Col span={3} className={'text'}>{item.Operation || "--"}</Col>
                    <Col span={3} className={'text'}>{item.OperationNext || "--"}</Col>
                    <Col span={4} className={'text'}>{item.Operator || "--"}</Col>
                    <Col span={5} className={'text'}>{item.Remark || "--"}</Col>
                    <Col span={6} className={'text'}>{localTime(item.UpdatedAt) || "--"}</Col>
                  </Row>
              })}
            </div>
          </div>
          <div className={'split-line'}>
            <img src={splitLine} alt=""/>
          </div>
          <div className={'detail-bottom'}>
            <div className={'title-detail'}>交易结果</div>
          </div>
          <div>
            {this.state.userInfo.user.UserType === '机构子账户'?(
               <div className={'list-content'}>

                 {
                   orderObject.ProductType === 'SERVICE'?(
                     <div>
                       <Row className={'transaction-header-content'} style={{width: "40%"}}>
                         <Col span={4} className={'text'}/>
                         <Col span={20} className={'text'}>价格</Col>
                       </Row>
                       <div className={'list-content'}>
                     <Form
                       name="basic"
                       ref={this.formRef}
                       onFinish={(values) => this.onFinish(values)}
                       onFinishFailed={() => this.onFinishFailed()}
                       initialValues={{
                       }}
                     >
                       {orderObject.Amount&& <Row className={'list-item'} style={{width: "40%"}}>
                         <Col span={4} className={'text'}/>
                         <Col span={14} className={'text'}>
                           {isEdit?(orderObject.Amount+"万元"):(
                             <div className={'edit-box'}>
                               <Form.Item
                                 name="amount"
                                 rules={product_num}
                                 className={'input-price input-flex input-password'}
                                 validateFirst={true}
                               >
                                 <Input size="middle" placeholder={'请输入金额'}  />
                               </Form.Item>
                               <div className={'unit-text'}>万元</div>
                             </div>)}
                         </Col>
                         <Col span={6} className={'text edit-btn'} >
                           <Form.Item className="">
                             {isEdit&& <Button className={'text edit-btn'} type="text" onClick={this.editResult}>
                               编辑
                             </Button> }
                             {!isEdit&& <Button className={'text edit-btn'} type="text" htmlType="submit">
                               保存
                             </Button>}
                           </Form.Item>
                         </Col>
                       </Row>}
                     </Form>
                       </div>
                     </div>
                   ):(
                     <div>
                       <Row className={'transaction-header-content'}>
                         <Col span={5} className={'text'}>利率</Col>
                         <Col span={5} className={'text'}>金额</Col>
                         <Col span={5} className={'text'}>期限</Col>
                         <Col span={9} className={'text'}>担保方式</Col>
                       </Row>
                       <div className={'list-content'}>
                     <Form
                       name="basic"
                       ref={this.formRef}
                       onFinish={(values) => this.onFinish(values)}
                       onFinishFailed={() => this.onFinishFailed()}
                       initialValues={{
                       }}
                     >
                       {orderObject.InterestRate&&
                       <Row className={'list-item'}>
                         <Col span={5} className={'text'}>
                           {isEdit?(orderObject.InterestRate +"%"):(
                             <div className={'edit-box'}>
                               <Form.Item
                                 name="interest_rate"
                                 rules={confirmRate}
                                 className={'input-price input-flex'}
                                 validateFirst={true}
                               >
                                 {/*<InputNumber size="middle"placeholder={'请输入利率'} />*/}
                                 <Input size="middle" placeholder={'请输入利率'} />
                               </Form.Item>
                               <div className={'unit-text'}>%</div>
                             </div>
                           )}
                         </Col>
                         <Col span={5} className={'text'}>
                           {isEdit?(orderObject.Amount +"万元"):(
                             <div className={'edit-box'}>
                               <Form.Item
                                 name="amount"
                                 rules={product_num}
                                 className={'input-price input-flex'}
                                 validateFirst={true}
                               >
                                 <Input size="middle" placeholder={'请输入金额'}  />
                               </Form.Item>
                               <div className={'unit-text'}>万元</div>
                             </div>)}
                         </Col>
                         <Col span={5} className={'text'}>
                           {isEdit?(orderObject.MonthLimit +"个月"):(
                             <div className={'edit-box'}>
                               <Form.Item
                                 name="month_limit"
                                 rules={product_date}
                                 className={'input-price input-flex'}
                                 validateFirst={true}
                               >
                                 <InputNumber size="middle" placeholder={'请输入期限'}/>
                               </Form.Item>
                               <div className={'unit-text'}>个月</div>
                             </div>
                           )}
                         </Col>
                         <Col span={7} className={'text'}>
                           {isEdit? (this.getValueByKey(orderObject.OrderSecured)):(
                             <div className={'secured-box'}>
                               <Form.Item
                                 name="order_secured"
                                 rules={pactSecured}
                                 className={'input-price input-flex'}
                                 validateFirst={true}
                               >
                                 <Input size="middle"
                                        placeholder={'请选择'}
                                        suffix={<img src={pull} alt="" onClick={this.onChangeSecuredInput.bind(this)} />}
                                        className={'secured-input'}
                                        readOnly
                                        onClick={this.onChangeSecuredInput.bind(this)}
                                 />
                               </Form.Item>
                               <Form.Item
                                 className={'input-price input-flex secured-input-box'}
                                 validateFirst={true}
                               >
                                 {isSecured&&(
                                   <div className={'secured-select-box'}>
                                     <Checkbox.Group options={options} defaultValue={this.state.checkedValues}  onChange={this.onChangeSecured.bind(this)}/>
                                     <div className={'secured-btn'}>
                                       <span className="btn btn-cancel" onClick={this.cancelSecured.bind(this)}>取消</span>
                                       <span className="btn btn-ok" onClick={this.oKSecured.bind()}>确定</span>
                                     </div>
                                   </div>
                                 )}
                               </Form.Item>
                             </div>
                           )}
                         </Col>
                         <Col span={2} className={'text edit-btn'} >
                           <Form.Item className="">
                             {isEdit&& <Button className={'text edit-btn'} type="text" onClick={this.editResult}>
                               编辑
                             </Button> }
                             {!isEdit&& <Button className={'text edit-btn'} type="text" htmlType="submit">
                               保存
                             </Button>}
                           </Form.Item>
                         </Col>
                       </Row>}
                     </Form>
                       </div>
                     </div>
                   )
                 }
               </div>
            ):(
              <div>
                {orderObject.ProductType === 'SERVICE'?(
                  <div className={'result-title-price'}>
                    <div className={'price price-title'}>价格</div>
                    {orderObject.Amount&& <div className={'price price-num'}>{orderObject.Amount}万元</div>}
                  </div>
                ):(   <div className={'result-title'}>
                  <Row >
                    <Col span={5} className={'price price-title'}>利率</Col>
                    <Col span={6} className={'price price-title'}>金额</Col>
                    <Col span={6} className={'price price-title'}>期限</Col>
                    <Col span={7} className={'price price-title'}>担保方式</Col>
                  </Row>
                    {orderObject.Amount&&   <Row className={'list-item'}>
                      <Col span={5} className={'price price-num'}>
                        {orderObject.InterestRate}%
                      </Col>
                      <Col span={6} className={'price price-num'}>
                        {orderObject.Amount}万元
                      </Col>
                      <Col span={6} className={'price price-num'}>
                        {orderObject.MonthLimit}个月
                      </Col>
                      <Col span={7} className={'price price-num'}>
                        {this.getValueByKey(orderObject.OrderSecured)}
                      </Col>
                    </Row>}
                </div>)}
              </div>
            )}

          </div>
          {/*子账号*/}
          <div>
            <div className={'split-line'}>
              <img src={splitLine} alt=""/>
            </div>
            <div className={'detail-bottom'}>
              <div className={'title-detail'}>申请企业</div>
              <div className={'go-detail'} onClick={this.openDetailsModal.bind()}>
                查看详情
              </div>
              <Modal title={'企业信息'}
                     visible={visible}
                     closeIcon={<img src={closeImg} alt=""/>}
                     onCancel={this.handleCancel}
                     onOk={this.handleCancel}
                     okText={'确定'}
                     style={{top: "30px"}}
                     width={640}
                     wrapClassName='modal-enterprise-modal'
                     destroyOnClose={true}
              >
                <EnterpriseDetail close={this.closeModal} data={enterpriseObject}/>
              </Modal>
            </div>
            <div style={{display: "flex"}}>
              <div className={'applicant applicant-enterprise'}><div className='item-label-text'>企业名称</div> <div className={'apply-title apply-title-width'}>{orderObject.EnterpriseName}</div></div>
              <div className={'applicant applicant-enterprise legal-name'}><div>法人姓名</div> <div className={'apply-title'}>{enterpriseObject.LegalPersonName}</div></div>
            </div>
            <div className={'applicant applicant-enterprise applicant-result'}>联系方式
              <span className={'apply-title'}>{orderObject.Telephone}</span>
            </div>
          </div>
          <div className={'split-line'}>
            <img src={splitLine} alt=""/>
          </div>
          <div className={'detail-bottom'}>
            <div className={'title-detail'}>交易产品</div>
            <div className={'go-detail'}>
              {productObject.Status==='SHELVED'?<Link to={'/shelved-page'} className={'text-link'}>查看详情</Link>:(
                <div>
                  { orderObject.ProductType === 'SERVICE'?  <Link to={'/corporate-detail/'+ productObject.ID} className={'text-link'}>查看详情</Link>:
                    <Link to={'/bond-detail/'+ productObject.ID} className={'text-link'}>查看详情</Link>}
                </div>
              )}
            </div>
          </div>
          {
            orderObject.ProductType === 'SERVICE'? <div>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      产品名称
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.Name}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      服务类型
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{productObject.ProductTypeStr}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      发布机构
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.FinancialName}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      价格范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{orderObject.AmountFloor + "-"+  orderObject.AmountCeil}万元</Col>
                  </Row>
                </Col>
              </Row>
            </div>: <div>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      产品名称
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.Name}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      利率范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>
                      {productObject.RateCeil? productObject.RateFloor+"%" +"-"+productObject.RateCeil+"%":productObject.RateFloor+"%以上"}
                    </Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      期限范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{productObject.CycleCeil? productObject.CycleFloor +"-"+productObject.CycleCeil+"个月":productObject.CycleFloor+"个月以上"}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      发布机构
                    </Col>
                    <Col  span={16}  className={'product-info product-name'}>{productObject.FinancialName}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      金额范围
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{productObject.AmountCeil? productObject.AmountFloor +"-"+productObject.AmountCeil+"万元":productObject.AmountFloor+"万元以上"}</Col>
                  </Row>
                  <Row>
                    <Col  span={8}  className={'product-info product-title'}>
                      担保方式
                    </Col>
                    <Col  span={16}  className={'product-info product-name  border-bottom'}>{this.getValueByKey(productObject.Secured)}</Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          {
            (this.state.userInfo.user.UserType === '机构子账户'&&status === 'PENDING-MATCH') &&
            <div className={'btn-operate'}>
              <span className="btn btn-pass" onClick={() => this.pass()}>通过</span>
              <span className="btn btn-reject" onClick={() => this.reject()}>驳回</span>
            </div>
          }
          <Modal title={'交易清单'}
                 visible={visiblePass}
                 closeIcon={<img src={closeImg} alt=""/>}
                 onCancel={this.handleCancelList}
                 onOk={this.handleCancelList}
                 footer={null}
                 width={640}
                 wrapClassName='modal-transaction-list'
                 destroyOnClose={true}
          >
            <TransactionList close={this.closeTransactionList}  productObject={productObject} orderObject={orderObject}//productOrderId={this.props.match.params.id}
             />
          </Modal>
        </div>
      </div>
    )
  }
}
export default withRouter(CompanyDetail)
