import React from 'react';
export default class NoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="no-page-container">
                i'm 404
            </div>
        )
    }
}