import React from 'react';
import {Input, Pagination} from 'antd';
import './index.scss'
import {Link} from 'react-router-dom'
import http from "../../lib/service/http";
import {localTime} from "../../lib/help/helper"
import NoData from "../NoData"

export default class NewsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: [
                {title: 'title', desc: 'desc'},
                {title: 'title', desc: 'desc'},
                {title: 'title', desc: 'desc'},
            ],
            total: 100,
            page: 1,
            keywords: '',
            total_null: false,
        }
    }

    onChangePage = (page) => {
        this.setState({
            page: page
        }, () => {
            this.getListData()
        })
    }

    getListData(page) {
        let params = {
            page: page || this.state.page,
            keywords: this.state.keywords
        }
        http.get('/home/news-list', params).then(res => {
            this.setState({
                dataList: res.items ? res.items : [],
                total: res.total,
                page: res.total_page,
                total_null: (!this.state.keywords && res.current_page === 1 && !res.items)
            });
        })
    }

    getSearchVal = (e) => {
        this.setState({
            keywords: e.target.value
        })
    }
    escapeHtml(str) {
        var temp = document.createElement("div");
        temp.innerHTML = str;
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }

    componentDidMount() {
        this.getListData();
    }

    componentWillUnmount() {
    }

    render() {
        const {total} = this.state;
        return (
            <div className="news-list-container">
                <div className="news-list-banner-container container">
                    <div className="content banner-content">
                    </div>
                </div>
                        <div className="news-list-content">
                            {
                                !this.state.total_null  &&
                                <div className="search-box">
                                    <Input size="large" className="search-input"
                                           onChange={(event) => this.getSearchVal(event)}
                                           placeholder={'请输入'}/>
                                    <p className="search-btn" onClick={() => this.getListData(1)}>搜索</p>
                                </div>
                            }
                            {
                                this.state.dataList.length > 0 ?
                                    <div>
                                        <div className="news-list-box content">
                                            {
                                                this.state.dataList.map((item, index) => {
                                                    return (
                                                        <Link to={'/news-info/' + item.ID} className="list-item"
                                                              key={'key' + index}>
                                                            <p className="news-title">{item.Title}</p>
                                                            <div className="news-desc">
                                                                {item.Content && this.escapeHtml(item.Content.replace(/<[^<>]+>/g ,""))}
                                                            </div>
                                                            <p className="news-time">
                                                                <span className="tag">来源: {item.Source}</span>
                                                                {localTime(item.CreatedAt).split(' ')[0]}
                                                            </p>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>
                                        <Pagination
                                            className={'page-style content'}
                                            onChange={this.onChangePage}
                                            defaultCurrent={1}
                                            total={total}
                                            hideOnSinglePage={true}
                                            showSizeChanger={false}
                                        />
                                    </div> :
                                    <div className="news-list-content">
                                        <NoData />
                                    </div>
                            }
                        </div>


            </div>
        )
    }
}