import React from 'react';
import {Button, Form, Input, message} from 'antd';
import './index.scss'
import http from './../../lib/service/http'
import Cookie from 'js-cookie';
import {rules} from "../../lib/help/rules";

const {password} = rules;


const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}

export default class ResetPassword extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            imageCode: '',
            uuid: '',
            user_type: 'ENTERPRISE',
            passwordRules: [
                ...password,
                {
                    validator: this.handleCheckPwd,
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
        }
    }

    onFinish(values) {
        http.post('/user/change-password', values).then(res => {
            message.success('修改成功，请重新登录');
            Cookie.remove('token');
            this.props.history.replace('/login')
        }, () => {
        })
    };

    handleCheckPwd = (_, value, callback) => {
        let cfmPwd = this.formRef.current.getFieldValue('confirm_password');
        let oldPwd = this.formRef.current.getFieldValue('old_password');
        if (oldPwd && value && oldPwd === value) {
            callback(new Error('不能与当前密码一致'))
        } else if (cfmPwd && value && cfmPwd !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            if (!this.formRef.current.isFieldValidating(['confirm_password'])) {
                this.formRef.current.validateFields(['confirm_password']);
            }
            callback()
        }
    }

    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        if (loginPass && value && loginPass !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            if (!this.formRef.current.isFieldValidating(['password'])) {
                this.formRef.current.validateFields(['password']);
            }
            callback()
        }
    }

    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const {confirmPasswordRules, passwordRules,} = this.state;

        return (
            <div className="login-container reset-password-container">
                <div className="login-content">
                    <div className="login-right">
                        <p className="login-title">
                            重置初始密码
                        </p>
                        <p className="left-desc">
                            保证您的账户安全请您重置密码
                        </p>
                        <Form
                            {...layout}
                            name="basic"
                            ref={this.formRef}
                            onFinish={(values) => this.onFinish(values)}
                            onFinishFailed={() => this.onFinishFailed()}
                        >
                            <Form.Item
                                label="当前密码"
                                name="old_password"
                                rules={password}
                                className={'input-password not-extra'}
                                validateFirst={true}
                            >
                                <Input.Password size="middle" placeholder={'请输入密码'}/>
                            </Form.Item>
                            <Form.Item
                                label="新密码"
                                name="password"
                                rules={passwordRules}
                                extra={'8-16位字符，含数字、大小写字母、特殊字符中的三种及以上'}
                                className={'input-password input-password-extra'}
                                validateFirst={true}
                            >
                                <Input.Password size="middle" placeholder={'请输入密码'}/>
                            </Form.Item>
                            <Form.Item
                                label="确认密码"
                                name="confirm_password"
                                rules={confirmPasswordRules}
                                className="confirm-input not-extra input-password"
                                validateFirst={true}
                            >
                                <Input.Password size="middle" placeholder={'请再次输入密码'}/>
                            </Form.Item>
                            <Form.Item {...tailLayout} className="submit-btn-box">
                                <Button className={'submit-btn'} type="primary" htmlType="submit">
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}