import React from 'react';
import {Breadcrumb} from 'antd';
import './index.scss'
import http from "../../lib/service/http";
import {localTime, getImgPublicUrl} from "../../lib/help/helper"
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import download from 'downloadjs';

export default class NewsInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsInfo: {},
            attachFile: []
        }
    }


    getInfoData() {
        http.get('/home/news-detail/' + this.props.match.params.id).then(res => {
            this.setState({
                newsInfo: res.detail,
                attachFile: res.attachFile??[].map(file => {
                    // fullpath 表示可以直接下载和预览的全路径
                    file.fullpath = '';
                    return file;
                })
            });
        })
    }

    componentDidMount() {
        this.getInfoData();
    }

    componentWillUnmount() {
    }

    preview = (file) => {
        let fileType = file.URL.slice(file.URL.lastIndexOf('.') + 1);
        const typeArr = ['doc', 'docx', 'xlsx', 'xls', 'csv'];
        if (typeArr.indexOf(fileType) !== -1) {
            this.download(file);
        } else {
            window.open(getImgPublicUrl(file.ID));
        }
    };
    download = (file) => {
        let x=new XMLHttpRequest();
        x.open( "GET", getImgPublicUrl(file.ID) , true);
        x.responseType="blob";
        x.onload= function(e){download(e.target.response, file.Name);};
        x.send();
    };

    render() {
        const {newsInfo} = this.state
        return (
            <div className="news-info-container">
                <div className="news-info-content content">
                    <Breadcrumb separator=">" className="title-box">
                        <Breadcrumb.Item href={'/#/news-list'}>政策资讯</Breadcrumb.Item>
                        <Breadcrumb.Item>正文</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="info-box w-e-text-container">
                        <p className="news-title">
                            {newsInfo.Title}
                        </p>
                        <p className="time-box">
                            <span className="tag">来源：{newsInfo.Source}</span>
                            <span className="time">{localTime(newsInfo.CreatedAt).split(' ')[0]}</span>
                        </p>
                        <div className="desc w-e-text" dangerouslySetInnerHTML={{__html: newsInfo.Content}}>
                        </div>
                        {
                            this.state.attachFile.length > 0 &&
                            <div>
                                <span className={"file-field"}>附件：</span>
                                {this.state.attachFile.map((file, index) => {
                                    return <p key={file.ID} className={"file-row"}>
                                        <a className={"file-name"} onClick={() => this.preview(file)} title={file.Name}>{file.Name}</a>
                                        <VerticalAlignBottomOutlined className={"file-icon"} onClick={() => this.download(file)}/>
                                    </p>
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
