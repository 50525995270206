import React from 'react';
import {Row, Col} from 'antd';
import http from "../../../../lib/service/http";
import {localTime} from "../../../../lib/help/helper"
import {Link} from "react-router-dom";
import Message from "../../../MyMessage";
import Agency from "../../Transaction/Agency/AgencyList";
import SubAgency from "../../Transaction/SubAgency/SubAgencyList";

import iconAgency1 from '../../../../resource/images/profile/icon-agency-1.svg'
import iconAgency2 from '../../../../resource/images/profile/icon-agency-2.svg'
import iconAgency3 from '../../../../resource/images/profile/icon-agency-3.svg'
import iconAgency4 from '../../../../resource/images/profile/icon-agency-4.svg'

export default class AgencyIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsInfo: {},
            dataList: [],
            page: 1,
            userInfo: {
                user: {}
            },
            overviewData: {},
            menuNumber: {},
            authorityObj: {},
            messageTotal: 0,
            subListTotal: 0,
            agencyTotal: 0
        }
    }

    getListData(page) {
        let params = {
            page: page || this.state.page,
        }
        http.get('/financial/news-list', params).then(res => {
            this.setState({
                dataList: res.items ? [res.items[0]] : [],
            })
        })
    }

    getOverviewData() {
        http.get('/financial/overview').then(res => {
            this.setState({
                overviewData: res,
            })
        })
    }

    getMenuNumber() {
        http.get('/financial/menu').then(res => {
            this.setState({
                menuNumber: res,
            })
        })
    }

    getUserInfo() {
        let tmpInfo = sessionStorage.getItem('userInfo');
        let tmpUserRoute = sessionStorage.getItem('routeInfo') || '{}';
        let userRoute = JSON.parse(tmpUserRoute)
        if (tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            if (tmpInfo.user.UserType === '机构主账户') {
                this.getMenuNumber();
                this.getAgencyList();
            }else if(tmpInfo.user.UserType === '机构子账户' && userRoute['交易管理']){
                this.getSubList()
            }
            this.setState({
                userInfo: tmpInfo
            })
        }
        this.setState({
            authorityObj: userRoute
        })
    }

    getMessageList(page = 1) {
        http.get('user/message-list', {page: page}).then(res => {
            this.setState({
                messageTotal: res.data.total,
            });
        });
    }
    getSubList = () => {
        let tmp ={
            query_status: 'PENDING-MATCH',
            page:1,
        };
        http.get('/financial/product-order/list?',tmp).then(res => {
            if (res) {
                this.setState({
                    subListTotal: res.data.total
                })
            }
        })
    };
    getAgencyList = () => {
        let tmp ={
            query_status: 'PENDING-DISTRIBUTE',
            page:1,
        };
        http.get('/financial/product-order/list',tmp).then(res => {
            if (res) {
                this.setState({
                    agencyTotal: res.data.total
                })
            }
        })
    };
    escapeHtml(str) {
        var temp = document.createElement("div");
        temp.innerHTML = str;
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }

    componentDidMount() {
        this.getUserInfo();
        this.getListData();
        this.getOverviewData();
        this.getMessageList();
    }

    componentWillUnmount() {
    }

    render() {
        const {overviewData, menuNumber, userInfo, authorityObj, agencyTotal, messageTotal, subListTotal} = this.state;
        return (
            <div className="agency-info-container">
                <div className="account-info agency-info">
                    <Row gutter={24}>
                        <Col
                            span={((userInfo.user.UserType === '机构主账户') || (authorityObj['发布企业服务'] || authorityObj['发布贷款融资'])) ? 16 : 24}
                            className="agency-account-info">
                            <div className="account-info-content">
                                <div className="title-box">
                                    <p className="title">账户概览</p>
                                </div>
                                <div className="account-list">
                                    <div className="account-item">
                                        <p className="item-title">已发布贷款融资数(个)</p>
                                        <p className="item-number">{overviewData.DebtProductPublishedTotal}</p>
                                        <div className="item-current">
                                            <span className="item-text">贷款融资总数</span>
                                            <p className="text-number">{overviewData.DebtProductTotal}<span>个</span></p>
                                        </div>
                                    </div>
                                    <div className="account-item">
                                        <p className="item-title">已发布企业服务数(个)</p>
                                        <p className="item-number">{overviewData.EnterpriseServicePublishedTotal}</p>
                                        <div className="item-current">
                                            <span className="item-text">企业服务总数</span>
                                            <p className="text-number">{overviewData.EnterpriseServiceTotal}
                                                <span>个</span></p>
                                        </div>
                                    </div>
                                    <div className="account-item">
                                        <p className="item-title">累计撮合金额(万元)</p>
                                        <p className="item-number">{overviewData.MatchAmountTotal}</p>
                                        <div className="item-current">
                                            <span className="item-text">昨日新增</span>
                                            <p className="text-number">{overviewData.YesterdayNewTotal}万元</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {
                            ((userInfo.user.UserType === '机构主账户') || (authorityObj['发布企业服务'] || authorityObj['发布贷款融资'])) &&
                            <Col span={8} className="agency-menu-info">
                                <div className="menu-info-content">
                                    <div className="title-box">
                                        <p className="title">快捷菜单</p>
                                    </div>
                                    {
                                        this.state.userInfo.user.UserType === '机构主账户' ?
                                            <div className="fast-menu-box">
                                                <div className="menu-item">
                                                    <div className="item-box">
                                                        <img src={iconAgency3} alt="" className="item-icon"/>
                                                        <p className="desc">待审核贷款产品</p>
                                                        <p className="number">{menuNumber.ProductFinancialPendingReviewTotal}</p>

                                                    </div>
                                                    <Link to={'/user-info/debt-finance'}>查看</Link>
                                                </div>
                                                <div className="menu-item">
                                                    <div className="item-box">
                                                        <img src={iconAgency1} alt="" className="item-icon"/>
                                                        <p className="desc">待审核企业服务</p>
                                                        <p className="number">{menuNumber.ProductServicePendingReviewTotal}</p>
                                                    </div>
                                                    <Link to={'/user-info/company-services'}>查看</Link>
                                                </div>
                                            </div> :
                                            <div className="fast-menu-box">
                                                {
                                                    authorityObj['发布贷款融资'] &&
                                                    <div className="menu-item">
                                                        <div className="item-box">
                                                            <img src={iconAgency2} alt="" className="item-icon"/>
                                                            <p className="desc">发布贷款产品</p>
                                                        </div>
                                                        <Link to={'/user-info/sub-debt-finance/sub-add-debt'}>去发布</Link>
                                                    </div>
                                                }
                                                {
                                                    authorityObj['发布企业服务'] &&
                                                    <div className="menu-item">
                                                        <div className="item-box">
                                                            <img src={iconAgency4} alt="" className="item-icon"/>
                                                            <p className="desc">发布企业服务</p>
                                                        </div>
                                                        <Link to={'/user-info/sub-company-service/sub-add-company'}>去发布</Link>
                                                    </div>
                                                }

                                            </div>
                                    }
                                </div>
                            </Col>
                        }
                    </Row>
                </div>
                {
                    (userInfo.user.UserType !== '机构子账户' || authorityObj['交易管理']) &&
                    <div className={(agencyTotal > 0 || subListTotal > 0) ? "recent-transaction recent-box has-list" : "recent-transaction recent-box"}>
                        <div className="title-box">
                            <p className="title">
                                {
                                    userInfo.user.UserType === '机构子账户' ? '待撮合交易' : '待分配交易'
                                }
                            </p>
                            {
                                (agencyTotal > 0 || subListTotal > 0) ?
                                    <Link className="show-all" to={'/transaction'}>查看全部</Link> :
                                    <p className="no-data-text">暂无交易数据</p>
                            }
                        </div>
                        {
                            (agencyTotal > 0 || subListTotal > 0) &&
                            <div className="transaction-list transaction">
                                {
                                    this.state.userInfo.user.UserType === '机构主账户' ?
                                        <Agency activeKey={'0'} type={'index'} /> :
                                        <SubAgency activeKey={'0'} type={'index'} />
                                }
                            </div>
                        }
                    </div>
                }
                <div
                    className={messageTotal > 0 ? "recent-transaction recent-box has-list" : "recent-transaction recent-box"}>
                    <div className="title-box">
                        <p className="title">最近消息</p>
                        {
                            messageTotal > 0 ?
                                <Link className="show-all" to={'/my-message'}>查看全部</Link> :
                                <p className="no-data-text">暂无消息数据</p>
                        }
                    </div>
                    {
                        messageTotal > 0  &&
                        <div className="transaction-list transaction">
                            <Message type={'index'}/>
                        </div>
                    }
                </div>
                <div
                    className={this.state.dataList.length > 0 ? "recent-transaction recent-box has-list" : "recent-transaction recent-box"}>
                    <div className="title-box">
                        <p className="title">政策资讯</p>
                        {
                            this.state.dataList.length > 0 ?
                                <Link className="show-all" to={'/news-list'}>查看全部</Link> :
                                <p className="no-data-text">暂无资讯数据</p>
                        }
                    </div>
                    {
                        this.state.dataList.length > 0 &&
                        <div className="news-list-box">
                            {
                                this.state.dataList.map((item, index) => {
                                    return (
                                        <Link to={'/news-info/' + item.ID} className="list-item" key={'key' + index}>
                                            <p className="news-title">{item.Title}</p>
                                            <div className="news-desc">
                                                {item.Content && this.escapeHtml(item.Content.replace(/<[^<>]+>/g ,""))}
                                            </div>
                                            <p className="news-time">
                                                <span className="tag">{item.Source}</span>
                                                {localTime(item.CreatedAt).split(' ')[0]}
                                            </p>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

}
