import React from 'react';
import '../index.scss'
import {Button, DatePicker, Form, Input, Modal, Radio} from "antd";
import http from "../../../../lib/service/http";
import {rules} from "../../../../lib/help/rules";
import CorporateInfo from '../../../CorporateInfo';
import {getUserInfo} from "../../../../lib/help/public";
import {getImgUrl, localTime} from "../../../../lib/help/helper";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import moment from 'moment';

import imgLicense from '../../../../resource/images/public/img-license.svg'
import Upload from "../../../../components/Upload";
import locale from "antd/es/date-picker/locale/zh_CN";
import jigouLogo from "../../../../resource/images/public/jigou_logo.svg"
const {time_zone, pact, not_null, upload_file, is_number, upload_file_not_required} = rules

const {RangePicker} = DatePicker;


export default class AuthorIndex extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            newsInfo: {},
            userInfo: {
                user: {},
                info: {},
            },
            openIndex: -1,
            initialValues: {},
            valueIsChange: false,
            isModalVisible: false,
            validityType: '长期'
        }
    }

    getUserInfo = (userInfo) => {
        let tmpInfo;
        tmpInfo = userInfo || sessionStorage.getItem('userInfo');
        if (tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            let tmpAccountInfo = {
                "legal_person_name": tmpInfo.info.LegalPersonName,
                "legal_person_address": tmpInfo.info.LegalPersonAddress,
                "legal_person_id_number": tmpInfo.info.LegalPersonIDNumber,
                "legal_person_id_front": tmpInfo.info.LegalPersonIDFrontFid,
                "legal_person_id_back": tmpInfo.info.LegalPersonIDBackFid,
                "legal_person_id_validity": tmpInfo.info.LegalPersonIDValidityStr,
                "business_license": tmpInfo.info.BusinessLicenseFid,
                "business_license_approval_date": moment(tmpInfo.info.BusinessLicenseApprovalDate),
                "logo": tmpInfo.info.LogoFid,
                "address": tmpInfo.info.Address,
                "registered_capital": tmpInfo.info.RegisteredCapital,
                "founded_date": moment(tmpInfo.info.FoundedDate),
                "business_license_validity": tmpInfo.info.BusinessLicenseValidityStr,
                "business_scope": tmpInfo.info.BusinessScope,
            }
            if (tmpAccountInfo.business_license_validity !== '长期') {
                tmpAccountInfo.range_picker = [moment(tmpAccountInfo.business_license_validity.split(' - ')[0]), moment(tmpAccountInfo.business_license_validity.split(' - ')[1])]
                tmpAccountInfo.business_license_validity = '非长期';
                this.setState({
                    validityType: '非长期'
                })
            }
            this.formRef.current.setFieldsValue(tmpAccountInfo)
            this.setState({
                userInfo: tmpInfo,
                initialValues: tmpAccountInfo,
            })
        }
    }
    checkValidity = (type) => {
        this.setState({
            validityType: type.target.value
        })
    }
    onFinish = (values) => {
        if (values.logo && values.logo.url) {
            values.logo = values.logo.url;
        } else {
            delete values.logo
        }
        if (values.business_license && values.business_license.url) {
            values.business_license = values.business_license.url;
        } else {
            delete values.business_license
        }
        if (values.business_license_validity === '非长期') {
            values.business_license_validity = values.range_picker[0].format('YYYY-MM-DD') + ' - ' + values.range_picker[1].format('YYYY-MM-DD')
        }else {
            values.business_license_validity = 'PERMANENT';
        }
        if(values.founded_date) {
            values.founded_date = values.founded_date.format('YYYY-MM-DD');
        }
        if(values.business_license_approval_date) {
            values.business_license_approval_date = values.business_license_approval_date.format('YYYY-MM-DD');
        }


        http.post('/enterprise/update-information', values).then(() => {
            getUserInfo(this.getUserInfo)
            this.setState({
                openIndex: -1,
                valueIsChange: false
            })
            this.handleCancel();
        }, () => {
        })
    };

    showEdit(index, editKey) {
        const {initialValues} = this.state;

        if (editKey) {
            this.formRef.current.setFieldsValue({[editKey]: initialValues[editKey]});
            if(editKey === 'range_picker') {
                this.formRef.current.setFieldsValue({business_license_validity: initialValues.business_license_validity});
                this.setState({
                    validityType: initialValues.business_license_validity
                })
            }
        }
        if (index !== -1 && this.state.valueIsChange) {
            this.showModal()
        } else {
            this.setState({
                openIndex: index,
                valueIsChange: false
            })
        }
    }

    onFileChange = (fileKey, file) => {
        this.formRef.current.setFieldsValue({[fileKey]: file});
        this.formRef.current.validateFields([fileKey]);
    }
    onValuesChange = () => {
        this.setState({
            valueIsChange: true,
        })
    }
    handleOk = () => {
        if (this.state.openIndex !== 0) {
            this.formRef.current.submit();
        } else {
            this.getEditInfo();
        }
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }
    getEditInfo = () => {
        this.corporateInfo.formRef.current.submit();
    }

    componentDidMount() {
        this.getUserInfo();
    }

    componentWillUnmount() {
    }

    render() {
        const {openIndex, initialValues, isModalVisible, userInfo} = this.state;
        const {info} = userInfo;
        return (
            <div className="account-info-container">
                <div className="profile-title-box">
                    <p className="title">基本信息</p>
                </div>
                <div className="info-content">
                    <div className="item-type-box">
                        <div className="item-box">
                            <p className="label-text">企业名称</p>
                            <p className="desc">{info.Name}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">统一社会信用代码</p>
                            <p className="desc">{info.UnifiedSocialCreditCode}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">企业类型</p>
                            <p className="desc">{info.Type}</p>
                        </div>
                    </div>
                    {
                        openIndex === 0 ?
                            <div className="item-type-box edit-box">
                                <CorporateInfo
                                    onRef={(ref) => {
                                        this.corporateInfo = ref
                                    }}
                                    type={'edit'}
                                    getInfoChange={this.onValuesChange}
                                    initialValues={initialValues}
                                    setData={this.onFinish}
                                />
                                <Form.Item className="btn-box">
                                    <Button className={'submit-btn'} type="primary" onClick={this.getEditInfo}>
                                        保存
                                    </Button>
                                    <Button className={'close-btn'} onClick={() => this.showEdit(-1, 'userInfo')}>
                                        取消
                                    </Button>
                                </Form.Item>
                            </div> :
                            <div className="item-type-box">
                                <div className="item-box">
                                    <p className="label-text">法定代表人姓名</p>
                                    <p className="desc">{info.LegalPersonName}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(0)}>修改</p>
                                </div>
                                <div className="item-box">
                                    <p className="label-text">法定代表人家庭住址</p>
                                    <p className="desc">{info.LegalPersonAddress}</p>
                                </div>
                                <div className="item-box">
                                    <p className="label-text">法定代表人身份证号</p>
                                    <p className="desc">{info.LegalPersonIDNumber}</p>
                                </div>
                                <div className="item-box">
                                    <p className="label-text">身份证有效期</p>
                                    <p className="desc">{info.LegalPersonIDValidityStr}</p>
                                </div>
                                <div className="item-box">
                                    <p className="label-text">法定代表人身份证</p>
                                    <div className="img-box">
                                        <div className="img-bg">
                                            <img src={getImgUrl(info.LegalPersonIDFrontFid)} alt=""/>
                                        </div>
                                        <div className="img-bg">
                                            <img src={getImgUrl(info.LegalPersonIDBackFid)} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    <Form
                        name="basic"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onValuesChange={this.onValuesChange}
                        className="form-content"
                    >

                        <div className="item-type-box">
                            {
                                openIndex === 1 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            label="营业执照"
                                            name="business_license"
                                            rules={upload_file}
                                            className={'height-item upload-item'}
                                            extra={
                                                <div className="input-desc">
                                                    <p className="desc-text">只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符</p>
                                                </div>}
                                            validateFirst={true}

                                        >
                                            <Upload
                                                defImg={imgLicense}
                                                onChange={this.onFileChange}
                                                fileKey={'business_license'}
                                                imgPath={initialValues.business_license}
                                            />
                                        </Form.Item>
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'}
                                                    onClick={() => this.showEdit(-1, 'business_license')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">营业执照</p>
                                        <div className="img-box">
                                            <div className="img-bg">
                                                <img src={getImgUrl(info.BusinessLicenseFid)} alt=""/>
                                            </div>
                                        </div>
                                        <p className="edit-btn" onClick={() => this.showEdit(1)}>修改</p>
                                    </div>
                            }

                            {
                                openIndex === 2 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            name="business_license_approval_date"
                                            label="营业执照核准日期"
                                            rules={time_zone}
                                            className={'not-extra create-time-item'}
                                        >
                                            <DatePicker
                                                allowClear={false}
                                                format="YYYY-MM-DD"
                                                locale={locale}
                                            />
                                        </Form.Item>
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'}
                                                    onClick={() => this.showEdit(-1, 'business_license_approval_date')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">营业执照核准日期</p>
                                        <p className="desc">{localTime(info.BusinessLicenseApprovalDate).split(' ')[0]}</p>
                                        <p className="edit-btn" onClick={() => this.showEdit(2)}>修改</p>
                                    </div>
                            }
                            {
                                openIndex === 3 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            label="企业Logo"
                                            name="logo"
                                            rules={upload_file_not_required}
                                            className={'height-item upload-item'}
                                            extra={
                                                <div className="input-desc">
                                                    <p className="desc-text">只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符</p>
                                                </div>}
                                            validateFirst={true}
                                        >
                                            <Upload
                                                onChange={this.onFileChange}
                                                fileKey={'logo'}
                                                imgPath={initialValues.logo}
                                            />
                                        </Form.Item>
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'} onClick={() => this.showEdit(-1, 'logo')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">企业Logo</p>
                                        {
                                            info.LogoFid ?
                                                <div className="img-box">
                                                    <div className="img-bg">
                                                        <img src={getImgUrl(info.LogoFid)} alt=""/>
                                                    </div>
                                                </div> :
                                                <p className="desc"><img src={jigouLogo} alt=""/></p>
                                        }
                                        <p className="edit-btn" onClick={() => this.showEdit(3)}>修改</p>
                                    </div>

                            }
                            {
                                openIndex === 4 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            label="企业地址"
                                            name="address"
                                            rules={not_null}
                                            className={'not-extra input-address'}
                                        >
                                            <Input size="middle" placeholder={'请输入机构地址'}/>
                                        </Form.Item>
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'}
                                                    onClick={() => this.showEdit(-1, 'address')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">企业地址</p>
                                        <p className="desc">{info.Address}</p>
                                        <p className="edit-btn" onClick={() => this.showEdit(4)}>修改</p>
                                    </div>

                            }
                            {
                                openIndex === 5 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            label="注册资本"
                                            name="registered_capital"
                                            rules={is_number}
                                            className={'input-phone'}
                                            extra={'请输入阿拉伯数字'}
                                        >
                                            <Input size="middle"
                                                   suffix={<p className="suffix-text">元整</p>}
                                                   placeholder={'请输入金额'}/>
                                        </Form.Item>
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'}
                                                    onClick={() => this.showEdit(-1, 'registered_capital')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">注册资本</p>
                                        <p className="desc">{info.RegisteredCapital}元整</p>
                                        <p className="edit-btn" onClick={() => this.showEdit(5)}>修改</p>
                                    </div>
                            }
                            {
                                openIndex === 6 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            name="founded_date"
                                            label="成立日期"
                                            rules={time_zone}
                                            className={'not-extra create-time-item'}
                                        >
                                            <DatePicker
                                                allowClear={false}
                                                format="YYYY-MM-DD"
                                                locale={locale}
                                            />
                                        </Form.Item>
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'}
                                                    onClick={() => this.showEdit(-1, 'founded_date')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">成立日期</p>
                                        <p className="desc">{localTime(info.FoundedDate).split(' ')[0]}</p>
                                        <p className="edit-btn" onClick={() => this.showEdit(6)}>修改</p>
                                    </div>
                            }
                            {
                                openIndex === 7 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            label="营业期限"
                                            name="business_license_validity"
                                            rules={pact}
                                            className={this.state.validityType === '非长期' ? 'not-extra cert-type-input' : 'not-extra'}
                                            validateFirst={true}

                                        >
                                            <Radio.Group onChange={this.checkValidity}>
                                                <Radio value={'非长期'}>非长期</Radio>
                                                <Radio value={'长期'}>长期</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        {
                                            this.state.validityType === '非长期' &&
                                            <Form.Item
                                                name="range_picker"
                                                label=""
                                                rules={time_zone}
                                                className={'not-extra date-time'}
                                            >
                                                <RangePicker locale={locale} format="YYYY-MM-DD"/>
                                            </Form.Item>
                                        }
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'}
                                                    onClick={() => this.showEdit(-1, 'range_picker')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">营业期限</p>
                                        <p className="desc">{info.BusinessLicenseValidityStr}</p>
                                        <p className="edit-btn" onClick={() => this.showEdit(7)}>修改</p>
                                    </div>
                            }

                            {
                                openIndex === 8 ?
                                    <div className="item-box edit-item">
                                        <Form.Item
                                            label="经营范围"
                                            name={'business_scope'}
                                            rules={not_null}
                                            className={'not-extra desc-input'}
                                        >
                                            <Input.TextArea placeholder={'请输入经营范围'}/>
                                        </Form.Item>
                                        <Form.Item className="btn-box">
                                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                            <Button className={'close-btn'}
                                                    onClick={() => this.showEdit(-1, 'business_scope')}>
                                                取消
                                            </Button>
                                        </Form.Item>
                                    </div> :
                                    <div className="item-box">
                                        <p className="label-text">经营范围</p>
                                        <p className="desc">{info.BusinessScope}</p>
                                        <p className="edit-btn" onClick={() => this.showEdit(8)}>修改</p>
                                    </div>
                            }
                        </div>
                    </Form>
                </div>
                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'保存'}
                    className="set-padding"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}>
                    <p className='account-modal-title'><ExclamationCircleOutlined
                        className="icon-waring"/> 有内容没有保存，确定退出编辑吗？</p>
                    <p className='account-modal-desc'>退出编辑后，更新的内容不会自动保存</p>
                </Modal>
            </div>
        )
    }
}
