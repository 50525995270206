import React from 'react';
import {Link, withRouter} from "react-router-dom";
import http from "../../../lib/service/http";
import './index.scss';
import { Row, Col, Breadcrumb} from 'antd';
import {getImgPublicUrl, localTime} from "../../../lib/help/helper";
import iconUpload from "../../../resource/images/public/jigou_logo.svg";
import vector from "../../../resource/images/public/vector.svg";
import time from "../../../resource/images/public/time.svg"
import rectangle from "../../../resource/images/public/rectangle.svg"
import recommend from "../../../resource/images/public/recommend.svg"
import NoData from "../../NoData";
import {setConfirmLink} from "../../../lib/help/public";
class BondDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailList: {},
      contact: '',
      hotDebtList: [],
      userInfo: {},
    }
  }
  getListData(params) {
    http.get(`home/debt-product-detail/${params}`).then(res => {
      this.setState({
        detailList: res.detail
      });
    })
  };
  getContact() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    let user = JSON.parse(userInfo).user;
    if (user) {
      const params = {
        id: Number(this.props.match.params.id),
        product_type: "TRADING",
      };
      http.post('/home/product-contact', params).then(res => {
        if(res) {
          this.setState({
            contact: res.contact
          })
        }
      }, () => {
      });
    } else {
      this.setState({
        contact: '******'
      })
    }
  };
  getHotDebt() {
    http.get('/home/hot-debt-product-list/' + this.props.match.params.id).then(res => {
      this.setState({
        hotDebtList: res.items
      });
    })
  };
  goDetail =(ID)=> {
    const id = ID;
    this.getListData(id);
    this.getContact();
    this.getHotDebt()
  };
  componentWillMount () {
    const id = this.props.match.params.id;
    this.getListData(id);
    this.getContact();
    this.getHotDebt()
  }
  render() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    const {detailList,contact,hotDebtList} =  this.state;
    return (
      <div className="firm-register-container product-detail-container">
        <div className="firm-register-content content">
          <div className={'breadcrumb'}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link className={'link'} to={'/product-supermarket'}>产品超市</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detailList.Name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col span={19}>
              <Row className={'detail-info part-one'}>
                <Col span={4} className={'img-box'}>
                  {detailList.LogoFid ? (  <img src={getImgPublicUrl(detailList.LogoFid)} alt="" />): ( <img src={iconUpload} alt=""/>)}
                </Col>
                <Col span={20} className={'info-box'}>
                  <div className={'title'}>{detailList.Name ||'--'}</div>
                  <Row className={'bond-info'}>
                   <Col span={12}>
                     <div className={"fin-name"}>
                       <img src={vector} className={'vector-img'} alt=""/>
                       <span> {detailList.FinName ||'--'}</span>
                     </div>
                   </Col>
                    <Col span={12}><img src={time} className={'vector-img'} alt=""/>{localTime(detailList.CreatedAt)||'--'}</Col>
                  </Row>
                  <div className={'service'}>
                    <div><span className={'area'}>服务地区: </span> {detailList.Area ||'--'}</div>
                    {/*<div><span className={'area'}>有效期: </span> {detailList.Validity === 'FIXED' ? (localDate(detailList.ValidityStart) + '-- '+ localDate(detailList.ValidityEnd)):detailList.Validity}</div>*/}
                  </div>
                  <Row className={'range-info'}>
                    <Col span={6}>
                      <ul>
                        <li className={'title-num title-rate'}>{detailList.RateCeil? detailList.RateFloor+"%" +"-"+detailList.RateCeil+"%":detailList.RateFloor+"%以上" ||'--'}</li>
                        <li className={'title-diff'}>利率范围</li>
                      </ul>
                    </Col>
                    <Col span={6}>
                      <ul>
                        <li className={'title-num'}>{detailList.CycleCeil? detailList.CycleFloor +"-"+detailList.CycleCeil+"个月":detailList.CycleFloor+"个月以上" ||'--'}</li>
                        <li className={'title-diff'}>期限范围</li>
                      </ul>
                    </Col>
                    <Col span={6}>
                      <ul>
                        <li className={'title-num'}>{detailList.AmountCeil? detailList.AmountFloor +"-"+detailList.AmountCeil+"万元":detailList.AmountFloor+"万元以上" ||'--'}</li>
                        <li className={'title-diff'}>金额范围</li>
                      </ul>
                    </Col>
                    <Col span={6}>
                      <ul>
                        <li className={'title-num'}>{detailList.Secured}</li>
                        <li className={'title-diff'}>担保方式</li>
                      </ul>
                    </Col>
                  </Row>
                  {(!userInfo.user|| userInfo.user&&userInfo.user.UserType === '企业账户')&& <p onClick={(event)=>setConfirmLink(event, this, detailList.ID, 'bond')} className={'apply-btn detail-btn'}>立即申请</p>}
                </Col>
                <div className={'product-info'}>
                  <div className={'product-features'}>
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>产品特点</span></div>
                    <div className={'features-one'}>
                      {/*<div className={'time-limit'}>1.期限长</div>*/}
                      <div className={'time-text'}>
                      <div dangerouslySetInnerHTML={{__html: detailList.Description}||'--'} /></div>
                    </div>
                    {/*<div className={'features-one'}>*/}
                    {/*  <div className={'time-limit'}>2.还款灵活</div>*/}
                    {/*  <div className={'time-text'}>xxxxxx</div>*/}
                    {/*</div>*/}
                    {/*<div className={'features-one'}>*/}
                    {/*  <div className={'time-limit'}>3.核批快</div>*/}
                    {/*  <div className={'time-text'}>xxxxxx</div>*/}
                    {/*</div>*/}
                    {/*<div className={'features-one'}>*/}
                    {/*  <div className={'time-limit'}>4.需贷简</div>*/}
                    {/*  <div className={'time-text'}>xxxxxx</div>*/}
                    {/*</div>*/}
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>申请条件</span></div>
                    <div className={'features-one'}>
                      <div className={'time-text'}>{detailList.Requirement ||'--'}</div>
                    </div>
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>所需材料</span></div>
                    <div className={'features-one'}>
                      {/*<div className={'time-limit'}>1.期限长</div>*/}
                      {/*<div className={'time-limit'}>1.期限长</div>*/}
                      {/*<div className={'time-limit'}>1.期限长</div>*/}
                      <div className={'time-text'}>{detailList.Data ||'--'}</div>
                    </div>
                    {detailList.Remark&&detailList.Remark.length>0?(<div>
                      <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>其他说明</span></div>
                        <div className={'features-one'}>
                      <div className={'time-text'}>{detailList.Remark||'--'}</div>
                        </div>
                    </div>):(<div/>)}
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>联系方式</span></div>
                    <div className={'features-one'}>
                    <div className={'time-text'}>{contact||'--'}</div>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
            <Col span={5} className={'detail-info'}>
              <div className={'recommend'}>
                <img src={recommend} alt=""/> <span className={'other-product'}>其他产品推荐</span>
              </div>

              {hotDebtList&&hotDebtList.length>0 ? (hotDebtList.map((item,index) => (
                <div className={'other-product-info'} key={index} onClick={() =>this.goDetail(item.ID)}>
                  <div className={'other-product-img'}>
                    {item.LogoFid?<img src={getImgPublicUrl(item.LogoFid)} alt=""/>: <img src={iconUpload} alt=""/>}
                  </div>
                  <div className={'other-product-title'}>{item.Name||'--'}</div>
                  <div className={'hot-rate'}><span className={'rate-title'}>利率范围：</span><span className={'rate-range'}><li className={'title-num title-rate'}>{item.RateCeil? item.RateFloor+"%" +"-"+item.RateCeil+"%":item.RateFloor+"%以上" ||'--'}</li></span></div>
                  {(!userInfo.user|| userInfo.user&&userInfo.user.UserType === '企业账户')&&<p onClick={(event)=>setConfirmLink(event, this, item.ID, 'bond')} className={'apply-btn detail-btn'}>立即申请</p>}
                </div>
              ))):((<div className="news-list-content">
                <NoData />
              </div>))}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default withRouter(BondDetail)
