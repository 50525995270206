import React from 'react';
import "./index.scss"
import {Tabs} from "antd";
import SubAgencyList from "./SubAgencyList";
const {TabPane} = Tabs;
export default class SubAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey:  '0'
    }
  }
  changeTab=(key)=> {
    this.setState({
      activeKey: key
    })
  };
  render() {
    return (
      <div className="company-transaction">
        <div className={'title'}>我的交易</div>
        <div className={'company-manage'}>
          <Tabs defaultActiveKey="0" className={'tab-box'} onChange={this.changeTab.bind(this)}>
            <TabPane tab={
              <p className="tab-item tab-item-2">待撮合</p>
            } key="0">
              {this.state.activeKey === '0'?  <SubAgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">撮合成功</p>
            } key="1">
              {this.state.activeKey === '1'?  <SubAgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">撮合失败</p>
            } key="2">
              {this.state.activeKey === '2'?  <SubAgencyList activeKey={this.state.activeKey}/>: <div/>}
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
