import React from 'react';
import {Button, Form, Input, Select,Modal} from "antd";
import http from "../../../../../lib/service/http";
import infoSuccess from "../../../../../resource/images/profile/info-success.jpg"
import closeImg from "../../../../../resource/images/profile/cancle.svg"
import {rules} from "../../../../../lib/help/rules";
const {account, password, phone, emailUser,is_select,remark} = rules;
const {Option} = Select;
const { TextArea } = Input;
export default class AddUser extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      passwordRules: [
        ...password,
        {
          validator: this.handleCheckPwd,
          trigger: 'onBlur'
        }
      ],
      confirmPasswordRules: [
        ...password,
        {
          validator: this.handleCfmPwd,
          trigger: 'onBlur'
        }
      ],
      roleType: []
    }
  }
  handleCheckPwd = (_, value, callback) => {
    let cfmPwd = this.formRef.current.getFieldValue('confirm_password');
    if (cfmPwd && value && cfmPwd !== value) {
      callback(new Error('两次密码不一致'))

    } else {
      callback()
    }
  };

  //确认密码校验一致
  handleCfmPwd = (_, value, callback) => {
    let loginPass = this.formRef.current.getFieldValue('password');
    if (loginPass && value && loginPass !== value) {
      callback(new Error('两次密码不一致'))
    } else {
      callback()
    }
  };
  onFinish =(values) =>{
    http.post('/financial/sub-account/create', values).then(res => {
      if (Object.keys(res).length===0) {
        this.props.close();
        Modal.info({
          closable: true,
          closeIcon: (<img src={closeImg} alt=""/>),
          className: 'info-success',
          width: '320px',
          content: (
            <div>
              <img src={infoSuccess} alt=""/>
              <p className={'add-success'}>新增成功</p>
              <p className={'success-text'}>登录平台后需先修改初始密码.</p>
            </div>
          ),
          okText: '我知道了',
          onCancel() {},
          onOk() {},
        });
      }
    }, () => {
    })
  };
  getRoleList=()=>  {
      http.get('/financial/role-list').then(res => {
        this.setState({
          roleType:res.data.items || [],
        })
      })
};
  componentDidMount() {
    this.getRoleList()
  }

  onFinishFailed(errorInfo) {
  };
  close = () => {
   this.props.close();
  };
  preventBubble =(e)=>{
    e.preventDefault();
  };
  render() {
    const {confirmPasswordRules, passwordRules} = this.state;
    return (
      <div className="modal-add-content">
        <div className="form-content add-from-content">
            <div className="left-from">
              <Form
                name="basic"
                ref={this.formRef}
                onFinish={(values) => this.onFinish(values)}
                onFinishFailed={() => this.onFinishFailed()}>
                <Form.Item
                  label="用户名称"
                  name="username"
                  rules={account}
                  extra={'6-20位字符，含数字、字母中的一种及以上'}
                  validateFirst={true}
                >
                  <Input size="middle" placeholder={'请输入用户名称'}/>
                </Form.Item>

                <Form.Item
                  label="初始密码"
                  name="password"
                  rules={passwordRules}
                  extra={'8-16位字符，含数字、大小写字母、特殊字符中的三种及以上'}
                  className={'input-password'}
                  validateFirst={true}
                >
                  <Input.Password size="middle" placeholder={'请输入密码'}/>
                </Form.Item>

                <Form.Item
                  label="确认密码"
                  name='confirmPassword'
                  rules={confirmPasswordRules}
                  className="confirm-input not-extra input-password"
                  validateFirst={true}
                >
                  <Input.Password size="middle" placeholder={'请再次输入密码'}/>
                </Form.Item>

                <Form.Item
                  label="手机号"
                  name="telephone"
                  rules={phone}
                  className="not-extra input-phone"
                >
                  <Input size="middle" placeholder={'请输入手机号'}/>
                </Form.Item>
                <Form.Item
                  label="邮箱"
                  name="email"
                  rules={emailUser}
                  className="not-extra input-phone"
                >
                  <Input size="middle" placeholder={'请输入邮箱地址'}/>
                </Form.Item>
                <Form.Item
                  label="角色"
                  name="role_name"
                  rules={is_select}
                  className="not-extra"
                >
                  <Select placeholder="请选择角色">
                    {
                      this.state.roleType.map((item) => {
                        return <Option value={item.RoleName} key={item.RoleName}>{item.RoleName}</Option>
                      })
                    }

                  </Select>
                </Form.Item>
                <Form.Item
                  label="备注"
                  name="memo"
                  rules={remark}
                  className="not-extra input-remark"
                >
                  <TextArea size="middle" placeholder={'请输入备注信息'} onPressEnter={(e)=>this.preventBubble(e)}/>
                </Form.Item>
                <Form.Item className="footer-btn">
                  <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.close}>
                    取消
                  </Button>
                  <Button className={'submit-btn'} type="primary" htmlType="submit">
                    确定
                  </Button>
                </Form.Item>
              </Form>
            </div>
        </div>
      </div>
    )
  }
}
