import React from 'react';
import {Col, Pagination, Row} from "antd";
import http from "../../lib/service/http";
import {localTime} from "../../lib/help/helper";
import NoData from "../NoData";

export default class NoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colPrice :[
                {
                    title: '不限',
                    content: '',
                    amount: '',
                    isSelect: true
                },
                {
                    title: '小于30万',
                    content: '',
                    amount: '<30',
                    isSelect: false
                },
                {
                    title: '小于100万',
                    content: '',
                    amount: '<100',
                    isSelect: false
                },
                {
                    title: '小于300万',
                    content: '',
                    amount: '<300',
                    isSelect: false
                },
                {
                    title: '大于等于300万',
                    content: '',
                    amount: '>=300',
                    isSelect: false
                },
            ],
            colDeadline: [
                {
                    title: '不限',
                    content: '',
                    cycle: '',
                    isSelect: true
                },
                {
                    title: '小于3个月',
                    content: '',
                    cycle: '<3',
                    isSelect: false
                },
                {
                    title: '小于6个月',
                    content: '',
                    cycle: '<6',
                    isSelect: false
                },
                {
                    title: '小于9个月',
                    content: '',
                    cycle: '<9',
                    isSelect: false
                },
                {
                    title: '大于等于9个月',
                    content: '',
                    cycle: '>=9',
                    isSelect: false
                },
            ],
            total: 16,
            currentPage: 1,
            totalPage: 100,
            valuePrice:null,
            productInfo: [
            ],
            amount: '',
            cycle: '',
            isSelect: false,
            options: [
                {value:'LIQUIDITY',name: '流动资金'},
                {value:'FIXED',name: '固定资产'},
                {value:'FINANCING',name: '项目融资'},
                {value:'OTHER',name: '其他'}
            ],
        }
    }
    SelectOptions=(key)=> {
        return(this.state.options.filter(function(item){
            return item.value===key;
        })[0].name)
    };
    getListData(params) {
        let userInfo = sessionStorage.getItem('userInfo') || '{}';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
        }
        let tmp  = {
            amount:params.amount,
            cycle:params.cycle,
            page:params.page
        };
        // 接口
        if(!userInfo.user) {
            http.get('home/enterprise-financing-list-no-auth',tmp).then(res => {
                this.setState({
                    productInfo: res.items,
                    totalPage: res.total_page,
                    total: res.total
                });
            })
        } else {
            http.get('/home/enterprise-financing-list',tmp).then(res => {
                this.setState({
                    productInfo: res.items,
                    totalPage: res.total_page,
                    total: res.total
                });
            })
        }
    };
    componentWillMount() {
        let params = {
            amount: '',
            cycle: '',
            page: 1,
        };
        this.getListData(params);
    };
    getDeadLine = (item,index)=> {
        this.state.colDeadline.forEach((i,e)=> {
            if(e===index)  {
                this.state.colDeadline[index].isSelect  = true;
            } else  {
                i.isSelect = false;
            }
        });
        this.setState({
            colDeadline: [...this.state.colDeadline],
            cycle: item.cycle || '',
        });
        let params = {
            page:'',
            amount:  this.state.amount || '',
            cycle: item.cycle || '',
        };
        this.getListData(params)
        this.setState({
            currentPage: 1,
        });
    };
    getPrice = (item,index) => {
        this.state.colPrice.forEach((i,e)=> {
            if(e===index)  {
                this.state.colPrice[index].isSelect  = true;
            } else  {
                i.isSelect = false;
            }
        });
        this.setState({
            colPrice: [...this.state.colPrice],
            amount:  item.amount || '',
        });
        let params = {
            page:'',
            amount:  item.amount || '',
            cycle: this.state.cycle || ''
        };
        this.getListData(params)
        this.setState({
            currentPage: 1,
        });
    };
    goDetail =()=> {

    };
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
        let params = {
            page:page,
            amount: this.state.amount,
            cycle: this.state.cycle
        };
        this.getListData(params)
    };
    render() {
        const {total,productInfo,colPrice,colRate,colDeadline} =  this.state;
        return (
            <div className="finance-content">
                <div className="debt">
                    <Row className={'debtRow'}>
                        <Col span={3} className="item-market">
                            <span>融资期限:</span>
                        </Col>
                        <Col span={21} className="item-market">
                            <ul>
                                {colDeadline.map((item,index) => (
                                    <li key={index} title={item.title} >
                                        <span key={index} className={item.isSelect ? 'active-select range-btn' : 'range-btn'} onClick={() => this.getDeadLine(item,index)}>{item.title}</span>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                    <Row className={'debtRow'}>
                        <Col span={3} className="item-market">
                            <span>融资金额:</span>
                        </Col>
                        <Col span={21} className="item-market">
                            <ul>
                                {colPrice.map((item,index) => (
                                    <li key={index} title={item.title} onClick={() => this.getPrice(item,index)}>
                                        <span key={index} className={item.isSelect ? 'active-select range-btn' : 'range-btn'}>{item.title}</span>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className="total-data">
                    共计
                    <span className="total-num">{total}</span>
                    条需求
                </div>
                {productInfo && productInfo.length > 0 ? ( <div className="">
                    {productInfo.map(item => (
                        <div key={item.Name} title={item.title}  className=" table-data product-info">
                                <div className={'require-info'}>
                                   <div className="public-time">
                                       发布时间： {localTime(item.CreatedAt)}
                                   </div>
                                    <div className="public-company">
                                        {item.EnterpriseName}
                                    </div>
                                    <Row className="public-other">
                                            <Col span={7}>
                                                <span className="title">期望融资金额 </span>
                                                <span className="amount">{item.Amount}万元</span>
                                                <div className="other-more">
                                                    <span className="title">联系方式 </span>
                                                    <span className="text">{item.Contact}</span>
                                                </div>
                                            </Col>
                                            <Col span={7}>
                                                <span className="title">期望年化利率 </span>
                                                <span className="text">{item.RateFloor}%-{item.RateCeil}%</span>
                                                <div className="other-more">
                                                    <span className="title">融资用途 </span>
                                                    <span className="text">{this.SelectOptions(item.FinancingPurpose)}</span>
                                                </div>
                                            </Col>
                                            <Col span={5}>
                                                <span className="title">融资期限 </span>
                                                <span className="text">{item.Cycle}个月</span>
                                            </Col>
                                            <Col span={5}>
                                                <span className="title">担保方式 </span>
                                                <span className="text">{item.SecuredMode}</span>
                                            </Col>
                                        </Row>
                                </div>
                        </div>
                    ))}
                    <Pagination
                        className={'pageStyle'}
                        onChange={this.onChangePage}
                        defaultCurrent={1}
                        total={total}
                        pageSize={10}
                        showSizeChanger={false}
                        current={this.state.currentPage}
                    />
                </div>) : (<div className="news-list-content">
                    <NoData />
                </div>)}
            </div>
        )
    }
}
