import React from 'react';
import {Link} from 'react-router-dom';
import './index.scss'
import imgAgency from '../../resource/images/register-type/img-type-agency.svg'
import imgFirm from '../../resource/images/register-type/img-type-firm.svg'


export default class RegisterType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="register-type-container">
                <div className="register-type-content content">
                    <p className="title">请选择注册账户类型</p>
                    <p className="desc">请根据实际需求选企业账号或机构账号，如果两者都是需分别注册</p>
                    <div className="type-box">
                        <Link to={'/register-firm'} className="type-item">
                            <img src={imgFirm} alt="" className="img-type"/>
                            <p className="item-title">企业账号</p>
                            {/*<p className="item-desc">适用于介绍介绍介绍介绍</p>*/}
                        </Link>
                        <Link to={'/register-agency'} className="type-item">
                            <img src={imgAgency} alt="" className="img-type"/>
                            <p className="item-title">机构账号</p>
                            {/*<p className="item-desc">适用于介绍介绍介绍介绍</p>*/}
                        </Link>
                    </div>
                    <p className="tip-text">
                        不知道选哪个？欢迎拨打咨询热线：<span>023-85233427</span>
                    </p>
                </div>
            </div>
        )
    }
}