import React from 'react';
import './index.scss'
import shelved from '../../../../resource/images/profile/shelevd.jpg'
export default class ShelvedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  goBack=()=> {
    this.props.history.go(-1);
  };
  render() {
    return (
      <div className="firm-register-container shelved-page">
        <div className={'shelved-page-content'}>
          <div className={'shelved-img'}>
            <img src={shelved} alt=""/>
            <p className={'shelved-title'}>产品已下架</p>
            <p className={'shelved-text'}>对不起，该产品已经下架，请您浏览其他产品。</p>
            <p className={'return-link'} onClick={this.goBack}>返回</p>
          </div>
        </div>
      </div>
    )
  }
}
