import React from 'react';
import {Table, Space, Pagination, message, Popconfirm,} from "antd";
import {withRouter} from "react-router-dom";
import http from "../../../../lib/service/http";
import {localTime} from "../../../../lib/help/helper";
import NoData from "../../../NoData";
class CompanyList extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleRole: false,
      data: [],
      currentPage: 1,
      roleType: [
        {
          name: 1,
          key: 1,
          value:1
        }
      ],
      columns: [
        {
          title: '编号',
          render:(text,record,index)=>`${index+1}`,
        },
        {
          title: '产品名称',
          dataIndex: 'Name',
          key: 'Name',
        },
        {
          title: '类型',
          dataIndex: 'ProductTypeStr',
          key: 'ProductTypeStr',
        },
        {
          title: '创建时间',
          key: 'CreatedAt',
          render: (row) => {
            return (localTime(row.CreatedAt))
          }
        },
        {
          title: '发布人',
          dataIndex: 'UserName',
          key: 'UserName',
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <Space size="middle" className={'operate-btn'}>
              <a onClick={this.goDetails.bind(this, record)}>查看详情</a>
              {(this.props.activeKey === '3' || this.props.activeKey === '5') &&
              <Popconfirm
                  title="删除后的产品不可找回，确定是否删除？"
                  onConfirm={this.delRow.bind(this, record)}
                  okText="确定"
                  cancelText="取消"
              >
                <a href='#'>删除</a>
              </Popconfirm>
              }
            </Space>
          ),
        },
      ]
    }
  }
  goDetails =(record) => {
    this.props.history.push({
      pathname:'/user-info/company-services/company-detail/'+record.ID,
      state: '产品详情'
    });
  };
  delRow = (record) => {
    http.post(`/financial/enterprise-service/delete/${record.ID}`).then(() => {
      const  key = this.props.activeKey;
      let params = {
        page:1
      };
      this.setState({currentPage: 1});
      this.getList(key,params);
      message.success('删除成功');
    })
  };
  getList = (key,params) => {
    let status= {};
    switch (key) {
      case '1':
        status = {
          status: 'PENDING-REVIEW',
          name:  '',
        };
        break;
      case '2':
        status = {
          status: 'REVIEW-PASS',
          name:  '',
        };
        break;
      case '3':
        status = {
          status: 'REVIEW-REJECTED',
          name:  '',
        };
        break;
      case '4':
        status = {
          status: 'PUBLISHED',
          name:  '',
        };
        break;
      case '5':
        status = {
          status: 'PUBLISH-REJECTED',
          name:  '',
        };
        break;
      case '6':
        status = {
          status: 'SHELVED',
          name:  '',
        };
    }
    let tmp= {
      query_status:status.status,
      query_name:status.name,
      page:params.page
    };
    http.get('/financial/enterprise-service/list',tmp).then(res => {
      if (res) {
        this.setState({
          data: res.items,
          total: res.total
        })
      }
    })
  };
  componentDidMount () {
    const  key = this.props.activeKey;
    let params = {
      page:1
    };
    this.getList(key,params)
  }
  componentDidUpdate(prevProps,prevState){
    const  key = this.props.activeKey;
    let params = {
      page:this.state.currentPage
    };
    if(prevProps.activeKey !== this.props.activeKey) {
      this.getList(key,params);
      this.setState({
        currentPage: 1
      })
    }
  }
  onChangePage = page => {
    this.setState({
      currentPage: page,
    });
    let params = {
      page:page,
    };
    this.getList(this.props.activeKey,params)
  };
  render() {
    const {data,total } = this.state;
    return (
      <div className="firm-register-container user-manage">
        <div className="firm-register-content user-content">
          {total > 0 ? (  <div className={'table-data'}>
            <Table columns={this.state.columns} dataSource={data} pagination={false}  rowKey='ID'/>
            <div className={'page-total-data'}>
              <div >
                共计 <span className={'total-num'}>{total}</span> 条数据
              </div>
              <Pagination
                className={'pageStyle'}
                onChange={this.onChangePage.bind(this)}
                defaultCurrent={1}
                total={total}
                pageSize={10}
                showSizeChanger={false}
                current={this.state.currentPage}
              />
            </div>
          </div>):<div className="news-list-content">
            <NoData />
          </div>}
        </div>
      </div>
    )
  }
}
export default withRouter(CompanyList)
