import React from 'react';
import '../index.scss'
import {Button, Form, Input, Modal} from "antd";
import http from "../../../../lib/service/http";
import {rules} from "../../../../lib/help/rules";
import {getUserInfo} from "../../../../lib/help/public";
import {getImgUrl} from "../../../../lib/help/helper";

import Upload from "../../../../components/Upload";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import jigouLogo from "../../../../resource/images/public/jigou_logo.svg"
const { not_null, upload_file, phone } = rules;
export default class AuthorIndex extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {
                user: {},
                info: {},
            },
            openIndex: -1,
            initialValues: {},
            valueIsChange: false,
            isModalVisible: false,
        }
    }

    getUserInfo = (userInfo) => {
        let tmpInfo;
        tmpInfo = userInfo || sessionStorage.getItem('userInfo');
        if (tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            let tmpAccountInfo = {
                "name": tmpInfo.info.Name,
                "website": tmpInfo.info.Website,
                "contact_name": tmpInfo.info.ContactName,
                "contact_position": tmpInfo.info.ContactPosition,
                "contact_telephone": tmpInfo.info.ContactTelephone,
                "logo": tmpInfo.info.LogoFid,
                "business_scope": tmpInfo.info.BusinessScope,
                "address": tmpInfo.info.Address,
            }
            this.formRef.current.setFieldsValue(tmpAccountInfo)
            this.setState({
                userInfo: tmpInfo,
                initialValues: tmpAccountInfo
            })
        }
    }

    onFinish(values) {
        if(values.logo && values.logo.url) {
            values.logo = values.logo.url;
        }else {
            delete values.logo
        }
        http.post('/financial/update-information', values).then(() => {
            getUserInfo(this.getUserInfo)
            this.setState({
                openIndex: -1,
                valueIsChange: false
            })
            this.handleCancel();
        }, () => {
        })
    };

    showEdit(index, editKey) {
        const {initialValues} = this.state;
        if (editKey) {
            this.formRef.current.setFieldsValue({[editKey]: initialValues[editKey]});
        }
        if (index !== -1 && this.state.valueIsChange) {
            this.showModal()
        } else {
            this.setState({
                openIndex: index,
                valueIsChange: false
            })
        }
    }

    onFileChange = (fileKey, file) => {
        this.formRef.current.setFieldsValue({[fileKey]: file});
        this.formRef.current.validateFields([fileKey]);
    }
    onValuesChange = () => {
        this.setState({
            valueIsChange: true,
        })
    }
    handleOk = () => {
        this.formRef.current.submit();
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }

    componentDidMount() {
        this.getUserInfo();
    }

    componentWillUnmount() {
    }

    render() {
        const {openIndex, initialValues, isModalVisible} = this.state;
        return (
            <div className="account-info-container">
                <div className="profile-title-box">
                    <p className="title">基本信息</p>
                </div>
                <div className="info-content">
                    <Form
                        name="basic"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onValuesChange={this.onValuesChange}
                        className="form-content"
                    >
                        {
                            openIndex === 0 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="机构名称"
                                        name="name"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构地址'}/>
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'} onClick={() => this.showEdit(-1, 'name')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">机构名称</p>
                                    <p className="desc">{initialValues.name}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(0)}>修改</p>
                                </div>
                        }
                        {
                            openIndex === 1 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="机构网址"
                                        name="website"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构地址'}/>
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'} onClick={() => this.showEdit(-1, 'website')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">机构网址</p>
                                    <p className="desc">{initialValues.website}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(1)}>修改</p>
                                </div>
                        }
                        {
                            openIndex === 2 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="机构地址"
                                        name="address"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构地址'}/>
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'} onClick={() => this.showEdit(-1, 'address')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">机构地址</p>
                                    <p className="desc">{initialValues.address}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(2)}>修改</p>
                                </div>
                        }
                        {
                            openIndex === 3 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="机构Logo"
                                        name="logo"
                                        rules={upload_file}
                                        className={'height-item upload-item'}
                                        extra={
                                            <div className="input-desc">
                                                <p className="desc-text">只支持.jpg/png格式，文件大小不超过2MB,文件名中不要包含空格、&、=等特殊字符</p>
                                            </div>}
                                        validateFirst={true}
                                    >
                                        <Upload
                                            onChange={this.onFileChange}
                                            fileKey={'logo'}
                                            imgPath={initialValues.logo}
                                        />
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'} onClick={() => this.showEdit(-1, 'logo')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">机构Logo</p>
                                    {
                                        initialValues.logo ?
                                          <div className="img-box">
                                              <div className="img-bg">
                                                  <img src={getImgUrl(initialValues.logo)} alt=""/>
                                              </div>
                                          </div> :
                                          <p className="desc"><img src={jigouLogo} alt=""/></p>
                                    }
                                    <p className="edit-btn" onClick={() => this.showEdit(3)}>修改</p>
                                </div>
                        }
                        {
                            openIndex === 4 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="联系人"
                                        name="contact_name"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构地址'}/>
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'}
                                                onClick={() => this.showEdit(-1, 'contact_name')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">联系人</p>
                                    <p className="desc">{initialValues.contact_name}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(4)}>修改</p>
                                </div>
                        }
                        {
                            openIndex === 5 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="联系人职位"
                                        name="contact_position"
                                        rules={not_null}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构地址'}/>
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'}
                                                onClick={() => this.showEdit(-1, 'contact_position')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">联系人职位</p>
                                    <p className="desc">{initialValues.contact_position}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(5)}>修改</p>
                                </div>
                        }

                        {
                            openIndex === 6 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="联系人电话"
                                        name="contact_telephone"
                                        rules={phone}
                                        className={'not-extra input-address'}
                                    >
                                        <Input size="middle" placeholder={'请输入机构地址'}/>
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'}
                                                onClick={() => this.showEdit(-1, 'contact_telephone')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">联系人电话</p>
                                    <p className="desc">{initialValues.contact_telephone}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(6)}>修改</p>
                                </div>
                        }
                        {
                            openIndex === 7 ?
                                <div className="item-box edit-item">
                                    <Form.Item
                                        label="经营范围"
                                        name={'business_scope'}
                                        rules={not_null}
                                        className={'not-extra desc-input'}
                                    >
                                        <Input.TextArea placeholder={'请输入经营范围'}/>
                                    </Form.Item>
                                    <Form.Item className="btn-box">
                                        <Button className={'submit-btn'} type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <Button className={'close-btn'}
                                                onClick={() => this.showEdit(-1, 'business_scope')}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </div> :
                                <div className="item-box">
                                    <p className="label-text">机构主营业务、资质</p>
                                    <p className="desc">{initialValues.business_scope}</p>
                                    <p className="edit-btn" onClick={() => this.showEdit(7)}>修改</p>
                                </div>
                        }
                    </Form>
                </div>
                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'保存'}
                    onOk={this.handleOk}
                    className="set-padding"
                    onCancel={this.handleCancel}>
                    <p className='account-modal-title'><ExclamationCircleOutlined
                        className="icon-waring"/> 有内容没有保存，确定退出编辑吗？</p>
                    <p className='account-modal-desc'>退出编辑后，更新的内容不会自动保存</p>
                </Modal>
            </div>
        )
    }
}
